<template>
  <div class="container-fluid">
    <div v-if="userCategory === 'patient'">
      <Patient 
        :room_meta="room_meta" 
        @save="save">
      </Patient>
    </div>
    <div v-else>
      <SLT 
        :room_meta="room_meta" 
        @start="start"
        @save="save"
        @next="next" 
        @back="back"
        @end="end"
        @exit="exit"
        @command="command">
      </SLT>
    </div>
  </div>
</template>
  
<script>
  
import {logActivity} from '@/media_input/utils'
import Patient from './patient/patient.vue'
import SLT from './slt/slt.vue'
import { ref, toRefs, onMounted, onUpdated } from 'vue';
import firebase from '@firebase/app';
import { reactive } from 'vue'

export default {
  components: {
    Patient,
    SLT,
  },
  props: ['room_meta'],
  setup(props, {emit}) {
    const userCategory = localStorage.getItem('userCategory')
    const room_meta = toRefs(props.room_meta)
    
    
    ///these are only available on the SLT view    
    const start = () => {
      emit("start")
    }

    const save = (v) => {
      emit("save", v)
    }

    const next = () => {
      emit("next")
    }

    const back = () => {
      emit("back")
    }

    const end = () => {
      emit("end")
    }

    const exit = () => {
      emit("exit")
    }

    const command = (v) => {
      emit("command", v)
    }

    const transmit_stream = (v) => {
      emit("transmit_stream", v)
    }

    onMounted(() => {
      logActivity( 'accessed', 'questionnaire_interactive_session')
    })

    return {
      userCategory,
      room_meta, 

      save,
      start,
      next,
      back,
      end,
      exit,
      command,
      transmit_stream
    };
  }
}
</script>