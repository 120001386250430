<template>
  <div class="container-fluid mt-4">
    <h1 class="display-5 mb-4">Choose a subscription plan</h1>
    <p class="lead">You have reached your daily exercise limit for your subscription plan. You can choose to continue on the free plan or you can choose one of the subscription plans. You can cancel your subscription at any time. No notice or reasoning is needed.</p>
    <div class="row mt-4">
      <div class="col">
        <div :class="['card h-100 shadow', {'blurredElement': !selected_t2 && selected_t1 || selected_t3 || selected_t4}]">
          <div class="card-body fw-bolder" style="background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);height:450px">
            <div class="h-100 position-relative">
              <div class="card-title">
                <h3>Super</h3>
                <hr />
              </div>
              <div v-if="selected_t2">
                <p>Would you like to get a 20% discount £35.99 by getting a 3 months subscription?</p>
                <button class="btn btn-outline-primary w-100 mt-4 btn-lg" type="button" @click="checkout('T2', '1')">1 Month Plan @ £14.99</button>
                <button class="btn btn-success w-100 mt-4 btn-lg" type="button" @click="checkout('T2', '3')">3 Months Plan @ £35.99</button>

                <div class="position-absolute bottom-0 start-50 translate-middle-x w-100"> 
                  <button class="btn btn-secondary w-100 mt-4" type="button" @click="selected_t2 = false">Choose another plan</button>
                </div>
              </div>
              <div v-else>
                <p> what you get: </p>
                <div class="ms-4">
                  <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> <strong>UNLIMITED</strong> practice exercises </p>
                  <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> <strong>UNLIMITED</strong> homework from professional speech and language therapists (if applicable)</p>
                  <p><i class="bi bi-x-circle" style="color:red;font-size: 20px;"></i> Regular treatment plan review by speech and language therapists</p>
                </div>
                <div class="position-absolute bottom-0 start-50 translate-middle-x w-100"> 
                  <p class="lead text-center"><strong>£14.99 / Month</strong></p>
                  <button class="btn btn-outline-success w-100" type="button" @click="focus('T2')">Choose plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div :class="['card h-100 shadow', {'blurredElement': !selected_t3 && selected_t1 || selected_t2 || selected_t4}]">
          <div class="card-body fw-bolder" style="background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);height:450px">
            <div class="h-100 position-relative">
              <div class="card-title">
                <h3>Premium</h3>
                <hr />
              </div>
              <p> what you get: </p>
              <div class="ms-4">
                <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> <strong>UNLIMITED</strong> practice exercises </p>
                <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> <strong>UNLIMITED</strong> homework from professional speech and language therapists</p>
                <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> Personalised exercises created for your needs</p>
              </div>
              <div class="position-absolute bottom-0 start-50 translate-middle-x w-100"> 
                <p class="lead text-center"><strong>£40 / Month </strong></p>
                <button class="btn btn-success w-100" type="button" @click="checkout('T3', '1')">Choose plan</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="getquote" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Get a quote</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="selected_t3=false"></button>
            </div>
            <div class="modal-body">
              <div v-if="selected_t3">
                <p>Thank you for choosing our premium plan. We have received your request for a quote and we will contact you shortly.</p>
                <p>Alternatively, you can reach to us directly by phone:</p>
                <div class="text-center">
                  <p class="lead"><strong>Dr Andre Hallack</strong></p>
                  <p class="lead">tel: 07717252728</p>
                </div>
              </div>
              <div v-else>
                <p class="lead">Please enter your phone number to get your quote.</p>
                <div class="form-floating">
                  <input v-model="phone_number" type="text" class="form-control" id="phone_number" placeholder="Phone number" maxlength="15">
                  <label for="phone_number">Phone number</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button v-if="!selected_t3" type="button" :disabled="!phone_number" class="btn btn-primary w-100" @click="focus('T3')">Get Quote</button>
              <button v-else type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal" @click="selected_t3=false">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-4 justify-content-center">
        <div class="col-md-4">
          <div :class="['card h-100 shadow', {'blurredElement': selected_t1 || selected_t2 || selected_t3 || selected_t4}]">
            <div class="card-body">
              <div class="card-title">

                <div class="row">
                  <div class="col-md-6">
                    <h3>Free</h3>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-primary w-100" type="button" disabled>Current Plan</button>
                  </div>
                </div>
                <hr />
                
              </div>
              <p> what you get: </p>
              <div class="ms-4">
                <p><i class="bi bi-check2-circle" style="color:green;font-size: 20px;"></i> Up to 5 practice exercises per day</p>
                <p><i class="bi bi-x-circle" style="color:red;font-size: 20px;"></i> Unlimited homework from professional speech and language therapists</p>
                <p><i class="bi bi-x-circle" style="color:red;font-size: 20px;"></i> Regular treatment plan review by speech and language therapists</p>
              </div>
              <p class="lead text-center"><strong>Free</strong></p>
              <button class="btn btn-outline-success"  style="width: 95%" type="button" @click="checkout('F1', '1')">{{tier === "F1" || tier === "Free_1"?"Continue on free plan":"Choose plan"}} </button>
            </div>
          </div>
        </div>
      <div class="col-md-4">
        <div :class="['card h-100 shadow', {'blurredElement': selected_t1 || selected_t2 || selected_t3}]">
          <div class="card-body fw-bolder" style="background-image: linear-gradient(45deg, #E6E6FA 0%, #E6E6FA 99%, #E6E6FA 100%);height:450px">
            <div class="card-title">
              <div class="row">
                <div class="col-md-6">
                  <h3>Need help choosing a plan? </h3>

                </div>                    
              </div>
              <hr />
            </div>

            <h5>Click here and we will help you find a suitable plan! </h5>
            <br>
            <br>
            <button class="btn btn-success w-100" type="button" data-bs-toggle="modal" data-bs-target="#contactUs">Contact us</button>

            <div class="modal fade" id="contactUs" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Contact us</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="selected_t4=false"></button>
                  </div>
                  <div class="modal-body">
                    <div v-if="selected_t4">
                      <p>Thank you for contacting us. We will contact you shortly.</p>
                      <p>Alternatively, you can reach to us directly by phone:</p>
                        <div class="text-center">
                          <p class="lead"><strong>Dr Andre Hallack</strong></p>
                          <p class="lead">tel: 07717252728</p>
                        </div>
                    </div>
                    <div v-else>
                      <p class="lead">Please enter your phone number so we can contact you.</p>
                      <div class="form-floating">
                        <input v-model="phone_number" type="text" class="form-control" id="phone_number" placeholder="Phone number" maxlength="15">
                        <label for="phone_number">Phone number</label>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button v-if="!selected_t4" type="button" :disabled="!phone_number" class="btn btn-primary w-100" @click="focus('T4')">Confirm</button>
                    <button v-else type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal" @click="selected_t4=false">Close</button>
                  </div>
                </div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { updateDataDirectory, getUserDataDir } from "@/firebase/index";
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  props: {},
  components: {
    Navbar,
    Footer
  },
  data() {
    
    return {
      phone_number: '',
      selected_t1: false,
      selected_t2: false,
      selected_t3: false,
      selected_t4:false,
      hasSLT: false,
      tier: sessionStorage.getItem('tier'),
      userCategory: localStorage.getItem("userCategory"),
      clientReferenceId: localStorage.getItem("uid"),
      email: "",
    };
  },
  created() {

  },
  mounted() {
    let _this = this

    getUserDataDir("Data/", function (err, result) {
      let data = result.val();
      _this.user_formal_name = data['Firstname'] + data['Surname']
      
      _this.email = data['Email']
      
      if (data['SLT'])
      {
        _this.hasSLT = true;
      } else {
        _this.hasSLT = false;
      }

    });

    if (this.clientReferenceId == '' || this.tier == '') {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    focus(tier) {
      if (tier === "T1") {
        this.selected_t1 = true
        this.selected_t2 = false
        this.selected_t3 = false
        this.selected_t4 = false
      } else if (tier === "T2") {
        this.selected_t2 = true
        this.selected_t1 = false
        this.selected_t3 = false
        this.selected_t4 = false
      } else if (tier === "T3") {
        this.selected_t3 = true
        this.selected_t1 = false
        this.selected_t2 = false
        this.selected_t4 = false
      } else if (tier === "T4") {
        this.selected_t4 = true
        this.selected_t1 = false
        this.selected_t2 = false
        this.selected_t3 = false
        this.getHelp()
      }
    },
    getQuote() {
      const _this = this

      let url = window.location.href

      let uid = localStorage.getItem("uid")
      let hex = '';
      for (let i = 0; i < 6; ++i) {
        hex += (Math.floor(Math.random() * 16)).toString(16);
      }

      this.reference_number = hex

      let issue = {}
      issue[this.reference_number] = {url: url, uid: uid, message: "Phone: " + _this.phone_number + "\n\nEmail: " + _this.email + "\n\nName: " + _this.user_formal_name + "\n\nNeed a quote for premium plan"}

      let pointer = 'Issues/' + new Date().toDateString()
      updateDataDirectory(pointer, issue)
    },
    getHelp() {
      const _this = this
      let url = window.location.href

      let uid = localStorage.getItem("uid")
      let hex = '';
      for (let i = 0; i < 6; ++i) {
          hex += (Math.floor(Math.random() * 16)).toString(16);
      }

      this.reference_number = hex

      let issue = {}
      issue[this.reference_number] = {url: url, uid: uid, message: "Phone: " + _this.phone_number + "\n\nEmail: " + _this.email + "\n\nName: " + _this.user_formal_name + "\n\nNeed a help to pick a plan"}

      let pointer = 'Issues/' + new Date().toDateString()
      updateDataDirectory(pointer, issue)
    },

    async checkout(tier, period) {
      if (tier === "Free_1") {
        this.$router.push({name: "home"})
      } else if (tier === "T1" || tier === "T2" || tier == "T3") {
        let url = import.meta.env.VITE_FAST_API + '/create_checkout'
        //let url = 'http://127.0.0.1:8000' + '/create_checkout'

        const res = await this.axios.post(url, { 
          tier: tier, 
          period: period,
          client_reference_id: this.clientReferenceId,
          customer_email: this.email
        });
        window.location.href = res.data
      } else if (tier === "T4") {
        if (period == "1") {

        } else {
          
        }
      }
    },
  },
};
</script>
<style>
.blurredElement {
  opacity: 0.3;
  /* Any browser which supports CSS3 */
  filter: blur(1px);

  /* Firefox version 34 and earlier */
  filter: url("blur.svg#gaussian_blur");

  /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
  -webkit-filter: blur(5px);
  transition: 'all .3s linear'
}
</style>