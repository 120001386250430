<template>
  <div>
    <!-- <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div> -->

    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='ClockGame';openModal('lifeskills_modal');">
      
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Cognitive_life_skill" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: purple">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Life Skills</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="lifeskills_modal" tabindex="-1" aria-labelledby="lifeskills_modal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title">Life Skills</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('lifeskills_modal');"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ClockGame'?'btn-primary':'btn-light']" type="button" @click="selected_module='ClockGame'">
                  Clock game
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'GiveDirections'?'btn-primary':'btn-light']" type="button" @click="selected_module='GiveDirections'">
                  <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div>
                  Give directions
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'NumberSequence'?'btn-primary':'btn-light']" type="button" @click="selected_module='NumberSequence'">
                  Number sequence
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MoneyRecognition'?'btn-primary':'btn-light']" type="button" @click="selected_module='MoneyRecognition'">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0" style="font-size:10px">NEW</span>
                  </div> -->
                    Money recognition
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MatchingShapes'?'btn-primary':'btn-light']" type="button" @click="selected_module='MatchingShapes'">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  Matching shapes
                </button>
              </div>
              <div class="col-8 text-center">
                <div v-show="selected_module === 'ClockGame'">
                  <p class="lead">Practise telling the time with analog and digital clocks</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:7" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module === 'NumberSequence'">
                  <p class="lead">Practise identifying numbers in a sequence orthographically and numerically</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'MoneyRecognition'">
                  <p class="lead">Practise recognising coins and bank notes</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:8" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'GiveDirections'">
                  <p class="lead">Practise giving directions</p>

                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'MatchingShapes'">
                  <p class="lead">Practise matching shapes</p>

                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Cognitive_life_skill from '@/assets/images/dashboard/slt/training/Cognitive_life_skill.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'life_skills',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      subscription_tier: sessionStorage.getItem("tier"),
      selected_module: '',
      local_number_exercises: 5,
      Cognitive_life_skill: Cognitive_life_skill, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "lifeskills_modal": new Modal(document.getElementById('lifeskills_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('lifeskills_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('lifeskills_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>