<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container mb-5">
      <h1 class="display-5">Reading practice</h1>

      <div class="card p-2">
        <div class="row text-center">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
               
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Match letters to words</p>
                <img :src="matchLettersImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="letter_word_match()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('letter_word')"> Exercise overview</button>
              </div>
            </div>
            
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
            
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Read a menu at a restaurant</p>
                <img :src="readMenuImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="read_menu()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('menu')"> Exercise overview</button>
              </div>
            </div>
            
          </div>    
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
       

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Match a sentence to an image</p>

                <img :src="readingImagesImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="reading_images()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('reading_images')"> Exercise overview</button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row mt-4 text-center">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="position-relative">
              </div>

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>4</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Read short sentences</p>
                <img :src="orlaImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="orla()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('short_sentence')"> Exercise overview</button>
              </div>
            </div>
            
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>5</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Reading comprehension (personal interests)</p>
                <img :src="readingComprehensionImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="reading_comprehension()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('reading_comprehension')"> Exercise overview</button>
              </div>
            </div>
            
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>

                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>6</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Reading comprehension (news)</p>
                <img :src="readingComprehensionNewsImg" class="img-fluid" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="reading_comprehension_news()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('reading_comprehension_news')"> Exercise overview</button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benfite_statement }}</h5>
                  </div>
                  
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <Posture_instructions/> -->

    </div>
    <Footer class="mt-auto"></Footer>
</div>
</template>
<script>


import readingImagesImg from '@/assets/images/instructions/reading_images.png'
import orlaImg from '@/assets/images/instructions/orla.png'
import readMenuImg from '@/assets/images/instructions/read_menu.png'
import matchLettersImg from '@/assets/images/instructions/letter_word_pair.png'
import readingComprehensionImg from '@/assets/images/instructions/reading_comprehension.png'
import readingComprehensionNewsImg from '@/assets/images/instructions/reading_comprehension_news.png'

import Posture_instructions from '../components/posture_instructions.vue'
import Footer from '@/views/Footer.vue'

import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
    name: "Reading_aphasia_instructions",
    components: {Posture_instructions, Navbar, Footer},
    props: {
    },
    data() {
        return {
          readingComprehensionNewsImg:readingComprehensionNewsImg,
          readingComprehensionImg:readingComprehensionImg,
          readingImagesImg:readingImagesImg,
          matchLettersImg:matchLettersImg,
          readMenuImg:readMenuImg,
          orlaImg,orlaImg,
          userCategory: "",
          region: "",
          explanation: "",
          module_title:'',
          benfite_statement:'',
          module_cate:''
        };
    },
    created() {
        this.userCategory = localStorage.getItem("userCategory");
        this.region = localStorage.getItem("region");
        // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
        // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
        // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
        // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
        this.explanation = "";
        window.scrollTo(0, 0);

    },
    methods: {
        orla() {
          this.$router.push({ name: 'orla'});
        },
        reading_images() {
          this.$router.push({ name: 'reading_images'});
        },
        letter_word_match() {
          this.$router.push({ name: 'letter_word_match'});
        },
        read_menu() {
          this.$router.push({ name: 'read_menu'});
        },
        gotoBenefit(cate)
        {
          this.module_cate = cate;
          if(cate == 'letter_word')
          {
            this.module_title = 'Match letters to words'
            this.benfite_statement = 'This exercise helps you practise identifying letters in words. A key step towards recognising words.'
          }
          else if (cate === 'reading_images')
          {
            this.module_title = 'Match a sentence to an image'

            this.benfite_statement = 'This exercise helps you identify key words and their position in the sentence.'

          }
          else if (cate === 'reading_comprehension')
          {
            this.module_title = 'Read a text and answer questions about it'

            this.benfite_statement = 'This exercise helps you practise your understanding of the main information found in a short text.'


          }
          else if (cate === 'reading_comprehension_news')
          {
            this.module_title = 'Read the news and answer questions about it'

            this.benfite_statement = 'This exercise helps you practise your understanding of the main information found in a short text.'


          }
          else if(cate == 'menu')
          {
            this.module_title = 'Read a menu at a restaurant'
            this.benfite_statement = 'Reading menus encourage more fun practice of reading words. The more you practise, the better you become. This exercise aims to help you improve reading everyday life words.'
          }
          else if(cate == 'short_sentence')
          {
            this.module_title = 'Read short sentences'
            this.benfite_statement = 'This exercise helps you practise reading common sentences in daily life. It aims to help you improve reading sentences.'
          }
          else
          {
            console.log('unknown category = '+cate)
          }
          let start_pop = new Modal(document.getElementById('start_pop'));
          start_pop.show()
        },
        gotoModule()
        {

          if(this.module_cate == 'letter_word')
          {
            this.letter_word_match()
          }
          else if (this.module_cate === 'reading_comprehension')
          {
            this.reading_comprehension()
          }
          else if (this.module_cate === 'reading_comprehension_news')
          {
            this.reading_comprehension_news()
          }
          else if (this.module_cate === 'reading_images')
          {
            this.reading_images()
          }
          else if(this.module_cate == 'menu')
          {
            this.read_menu()
          }
          else if(this.module_cate == 'short_sentence')
          {
            this.orla()
          }
          else
          {
            console.log('unknown category = '+this.module_title)
          }
        },
        reading_comprehension()
        {
          this.$router.push({ name: 'reading_comprehension_setup'});
 
        },
        reading_comprehension_news()
        {
          this.$router.push({ name: 'reading_comprehension_news_mid'});
 
        }
    },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}

</style>