<template>
    <div class="row mt-2" v-if="userCategory == 'slp'">
      <div class="card card-body">
        <div>
          <span class="custom-text">Sound frequency analysis results</span>
        </div>
        <table class="table mt-4">
          <thead>
            <tr>
              <th scope="col">Exercise Name</th>
              <th scope="col">Loudness (dB)</th>
              <th scope="col">Mean F0</th>
              <th scope="col">Std Dev F0</th>
              <th scope="col">Jitter (%)</th>
              <th scope="col">Shimmer (%)</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row, index in jittershimmer_table" v-bind:key="index">
              <td>{{ row.Name }}</td>
              <td>{{ row.loudness<50?'<50':row.loudness }}</td>
              <td>{{ row.meanF0 }}</td>
              <td>{{ row.stdevF0 }}</td>
              <td>{{ row.localJitter }}</td>
              <td>{{ row.localShimmer }}</td>
              <td>
                <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
              </td>
            </tr>
            <tr class="table-group-divider" v-if="jittershimmer_table && jittershimmer_table.length > 1">
                <th scope="col">Average value</th>
                <th scope="col">{{ (jittershimmer_table.reduce((sum, value) => sum + value.loudness, 0) / jittershimmer_table.length).toFixed(0) }}</th>
                <th scope="col">{{ (jittershimmer_table.reduce((sum, value) => sum + value.meanF0, 0) / jittershimmer_table.length).toFixed(0) }}</th>
                <th scope="col">{{ (jittershimmer_table.reduce((sum, value) => sum + value.stdevF0, 0) / jittershimmer_table.length).toFixed(1) }}</th>
                <th scope="col">{{ (jittershimmer_table.reduce((sum, value) => sum + value.localJitter, 0) / jittershimmer_table.length).toFixed(2) }}</th>
                <th scope="col">{{ (jittershimmer_table.reduce((sum, value) => sum + value.localShimmer, 0) / jittershimmer_table.length).toFixed(2) }}</th>
              </tr>
          </tbody>
        </table>
        <div class="row mt-2">
          <span>Typical value ranges</span>
        </div>
        <table class="table mt-4">
          <thead>
            <tr>
              <th scope="col">Jitter (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row, index in voiceNormTable" v-bind:key="index">
              <td>{{ row.localJitter }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>
  
<script>
  import firebase from 'firebase/app'
  import {ref, onUpdated, onMounted} from 'vue'
  import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'jittershimmer_table',
    components: {
      Audio_Playback
    },
    props: {
      jittershimmer_table: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      const userCategory = ref(localStorage.getItem('userCategory'))

      let voiceNormTable = [{
        localJitter : '0-1.2',
        localShimmer : '2.8-6.3'
      }]

      onMounted( () => {

      });

      const audio_player = ref(null)


      const playAudio = (audio_file_location) => {

        let storage = firebase.storage()
        storage.ref(audio_file_location).getDownloadURL().then((url) => {
          audio_player.value = new Audio(url);
          audio_player.value.play();

          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });

        }).catch((error) => {

        })
      }

      const playRecording = (address) => {
        playAudio(address)
      }

      const pauseAudio = () => {
        audio_player.value.pause()
        audio_player.value = null
      }

      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      const downloadRecording = (address) => {
        let storage = firebase.storage();

        storage.ref(address).getDownloadURL().then((url) => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {

        });
      }

      return {
        userCategory,
        downloadRecording,
        pauseAudio,
        playRecording,
        voiceNormTable
      };

    }
  }
</script>