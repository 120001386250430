<template>
    <div>
      <Layout>
        <template v-slot:content>
  
          <div class="row">
              <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card card-body">
                  <div class="row">
                    <p class="text-30">
                        {{instruction_text}}
                        <img :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say(instruction_text)">
                    </p>
                  </div>
                  <div class="row">
                    <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card-body h-100">
                  <div v-if="answer_options.length>2">
                      <div class="row justify-content-around" style="margin-bottom:40px">
                        <div class="col-10" >

                          <div class="card card-body custom-card_blue" @click="select_button(0)">
                            <span class="read-text" style="cursor: pointer">{{answer_options[0].text}} </span>
                          </div>
                        </div>
                        <div class="col-2" >

                          <img :src="audioImg" class="img-fluid mx-auto d-block" style="cursor: pointer;" @click="listenSentence(0)">
                        </div>

                      </div>
                      <div class="row justify-content-around" style="margin-bottom:40px">
                        <div class="col-10" >

                          <div class="card card-body custom-card_pink" @click="select_button(1)">
                            <span class="read-text" style="cursor: pointer">{{answer_options[1].text}} </span>
                          </div>
                        </div>
                        <div class="col-2" >

                          <img :src="audioImg" class="img-fluid mx-auto d-block" style="cursor: pointer;" @click="listenSentence(1)">
                        </div>

                      </div>
                      <div class="row justify-content-around" style="margin-bottom:40px">
                        <div class="col-10" >

                          <div class="card card-body custom-card_green" @click="select_button(2)">
                            <span class="read-text" style="cursor: pointer">{{answer_options[2].text}} </span>
                          </div>
                        </div>
                        <div class="col-2" >

                          <img :src="audioImg" class="img-fluid mx-auto d-block" style="cursor: pointer;" @click="listenSentence(2)">
                        </div>

                      </div>
                  </div>
                </div>
            </div>
          </div>
        </template>

        <template v-slot:sidebar>
          <Sidebar class="h-100"
            :current_exercise_index="current_exercise_index"
            :total_exercises="total_exercises"
          
            :has_skip="true"
            @skip="nextExercise()"
  
            :has_show_results="!first_exercise"
            @show_results="goToResults()"
            >
          </Sidebar>
        </template>
      </Layout>

    </div>
  </template>
  
  <script>
  import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
  import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
  import homeplanImg from '@/assets/images/homeplan.png'
  import crossImg from '@/assets/images/cross.png'
  import doneIcon from '@/assets/images/icon_green_check.png'
  import audioImg from '@/assets/images/audio_logo.png'
  import loadingSVG from '@/assets/images/loader.svg'
  import {save_pre_computed_results} from '@/utils/results/data_save_utils'
  

  import {logActivity} from '@/media_input/utils'
  import {startConfetti} from '@/common_js/confetti'
  import { useTTS } from '@/stores/tts'
  import {BV_Points} from '@/utils/bv_points'
  
  /*
  
  {
     "Address":[
        "/images/word_naming_easy/onion_1.jpg",
        "/images/word_naming_easy/onion_2.jpg"
     ],
     "category":"vegetables",
     "difficulty":"easy",
     "exercise_type":"name_a_picture_easy",
     "hints":[
        "This is a vegetable",
        "This vegetable has layers",
        "This vegetable grows in the ground"
     ],
     "instruct":"Press Record. Say the word that matches the picture below",
     "name":"onion;onions;red_onion;white_onion",
     "word_type":"noun",
     "wrong_words":"tomato;sweetcorn;pepper",
     "plan_name":"Picture naming",
     "assessment_type":"SLP_TRAINING",
     "curr_exercise_path":"Picture naming/16",
     "ex_path":"Picture naming/16",
     "today":"2023-07-11 17:20",
     "audioIns1":null,
     "imageAddress":[
        "/images/word_naming_easy/onion_1.jpg",
        "/images/word_naming_easy/onion_2.jpg"
     ],
     "videoAddress":null,
     "audioAddress":null,
     "audioIns2":null,
     "videoIns":null,
     "exercise_type":"name_a_picture_easy",
     "url":null,
     "url2image":null,
     "audioIns":null,
     "Ex_Description":"Press Record. Say the word that matches the picture below",
     "module_name":"Picture naming",
     "has_results":false,
     "current_exercise_index":0,
     "number_exercises":5
  }
  
  */
  export default {
      name: 'reading_images',
      props: {
        exForm: {
          type: Object,
        },
      },
      components: {Layout, Sidebar},
      data() {
        return {
          instruction_text:"Pick the text that describes the picture",
          answer_options:[],
          sound_hints:false,
          recognition_setting: 0,
          audioImg:audioImg,
          loadingSVG: loadingSVG,
          diffMarks:{
            0:{
              style:{color: '#000000'},
              label:'Auto'
            },
            1:{
              style:{color: '#000000'},
              label:'Manual'
            },
          },
          answer_text: '',
          answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
          imgSrc: loadingSVG,
          hints:['It is used to cook','It is made of metal'],
          showHints:false,
          answer_options:['Saucepan','Fridge'],
          showOptions:false,
          showedOptions:false,
          showedAnswer:false,
          first_exercise:true,
          chosen_image: '',
          target_word: '',
          single_target_word: '',
          audio_player: null,
          phoneme_single_target_word: '',
          target_img_number: '',
          instructions_msg: '',
          homeplanImg: homeplanImg,
          crossImg: crossImg,
          doneIcon: doneIcon,
          inputAcc:'',
          play_pause_text: 'Start',
          current_exercise_index: 0,
          total_exercises:1,
          started: false,
          target_words: [],
          currentSentence: '',
          best_answer:'',
          results:{'correct':0,'incorrect':0,'total':0},
          uid: '',
          userType: '',
          region:'UK',
          resultsTable: [],
          half_word_url: null,
          retest_count: null,
          is_recording: false,
          congatulationsOnce: false,
          bv_tts: useTTS().tts_engine,
          bv_points: new BV_Points(),
        }
      },
      created() {
  
        const _this = this

        _this.userType = localStorage.getItem('userCategory');
  
        let region = localStorage.getItem('region');
          
        if(region!=undefined && region!=null)
        {
          _this.region = region
        }
    
        this.bv_tts.say('')

        if(this.userType=='slp')
        {
          let client_number = localStorage.getItem('client_uid');
  
          if(client_number!='undefined' && client_number!=null && client_number!='')
          {
            _this.client_number = client_number; 
          }
        }
  
        let uid = localStorage.getItem('uid');
        _this.uid = uid;   
  
        logActivity( 'accessed', 'reading_images')
  
  
        this.setup();
  
      },
      mounted()
      {
        const _this = this

        this.bv_tts.say(this.instruction_text)
  
        // if(_this.recognition_setting==0)
        // {
        //   let inital_text = 'Press Start. Name the picture.'
  
        //   _this.bv_tts.say(inital_text) 
        // }
      },
      beforeUnmount()
      {    
        this.bv_tts.cancel()
  
      },
      methods: 
      {
        saveResults()
        {
          const _this = this
  
          if(this.results['correct']!=0 || _this.results['total']!=0)
          {
            let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1, Sound_Hints:_this.sound_hints}
  
            let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}
  
            let results = {all_results:all_results, plot_results:plot_results}
  
            var reading_image_data = {resultsTablePre: {exercise_type: 'Reading Images', exercise: 'Reading Images', results: results, audio_address:''}}
  
  
            save_pre_computed_results(_this.exForm, _this.$firebase, reading_image_data)
  
  
            _this.$emit('logExerciseResults', reading_image_data);
          }
        },
        listenSentence(index)
        {
          this.bv_tts.say(this.answer_options[index].text)
          this.sound_hints = true
        },        
        select_button(index)
        {
            if(this.answer_options[index].correct)
            {
                this.found_correct_word()
            }
            else
            {
              this.found_incorrect_word()
                
            }

        },
        nextExercise()
        {
          const _this = this;
          _this.saveResults()
  
          _this.$emit('nextExercise');
        },
        goToResults()
        {
          const _this = this
  
          _this.saveResults()
          let goToResultsData = {curr_ex_done:false}
          _this.$emit('goToResults', goToResultsData);
        },
        setup()
        {
          let _this = this;
  
          _this.answer_text = '';

  
          _this.first_exercise = !_this.exForm.has_results

   
  
          let img_address = _this.exForm.imageAddress[Math.floor(Math.random() * _this.exForm.imageAddress.length)]

          let correct_sentences = _this.exForm.correct_sentence

          let correct_sentence = correct_sentences[Math.floor(Math.random() * correct_sentences.length)]

          let incorrect_sentences = [..._this.exForm.incorrect_sentences]

          // let correct_sentence = 'The man is eating a hot dog'
          // let incorrect_sentences = ['The woman is drinking','the dog is barking']

          _this.current_exercise_index = _this.exForm.current_exercise_index + 1
          _this.total_exercises = _this.exForm.total_all_type_exercises
          _this.retest_count = null

          let n_options = 3

          let correct_option_index = Math.floor(Math.random() * n_options)

          _this.answer_options = []

          for(let i=0;i<3;++i)
          {
            if(i==correct_option_index)
            {
                _this.answer_options.push({text:correct_sentence,correct:true})


            }
            else
            {
                
                let incorrect_option_index = Math.floor(Math.random() * incorrect_sentences.length)



                _this.answer_options.push({text:incorrect_sentences[incorrect_option_index],correct:false})

                incorrect_sentences.splice(incorrect_option_index,1)


            }
          }

            
            var storageRef = _this.$firebase.storage().ref();
            storageRef.child(img_address).getDownloadURL().then(function(url) {
                _this.imgSrc = url;
            }).catch(function(error) {

            });
       
        },
        found_correct_word(curr_sentence)
        {
          const _this = this
  
  
          _this.best_answer = curr_sentence
  
          _this.answer_text = 'Congratulations! You got the right phrase'
          _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          startConfetti(this)

          _this.bv_tts.system_say('Good job')

          
          setTimeout(() => {
              this.nextExercise()
          }, 1000);


          if(_this.results['total'] == 0)
          {
            _this.results['total'] = 1

            _this.results['correct'] = 1
            _this.results['incorrect'] = 0

          }
  
        },
        found_incorrect_word(curr_sentence)
        {
          const _this = this
  
  
          _this.best_answer = curr_sentence
  
          _this.answer_text = 'Not quite right! Try again'
          this.bv_tts.system_say(_this.answer_text)
          _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'

          
          if(_this.results['total'] == 0)
          {

            _this.results['total'] = 1

            _this.results['correct'] = 0
            _this.results['incorrect'] = 1
          }
  
        },
        showAnswer()
        {
          let _this = this;
          _this.showOptions = true
          _this.answer_text = 'The answer is '+_this.single_target_word;
          _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'
  
          if(_this.results['correct'] == 0)
          {
            _this.showedAnswer = true
            _this.results['correct'] = 0
            _this.results['incorrect'] = 1
            _this.results['total'] = 1
          }
        },
      }
  }
  </script>
  
  <style scoped>
  .custom-button-BV
  {
      color: white;
      background-color: #195e52;
      border-color: #195e52;
      height: 20mm;
      width: 35mm;
  }

  
.custom-card_blue{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
}

.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.custom-card_green{
    background-color: lightseagreen;
    border-color: lightseagreen;
    font-size: 20px;
}
  </style>
  
  <!-- Results Descriptions
  {
    "Name a picture": {
      "<key>": { 
        "audio_address": "<empty>",
        "resultsTablePre": {
          "audio_address": "<empty>",
          "category": "<string>",
          "difficulty": "<string>",
          "exercise": "Word Naming",
          "exercise_type": "Word Naming",
          "results": {
            "all_results": {
              "Correct": "<int>",
              "Identified_Answer": "string",
              "Incorrect": "<int>", //right now this is always 0, but we should improve
              "Show_Answer": "<bool>",
              "Show_Options": "<bool>",
              "Sound_Hints": "<bool>",
              "Total": "<int>" //number tries
            },
            "plot_results": {
              "Correct": "<int>",
              "Incorrect": "<int>"
            }
          },
          "word": "<target_word>",
          "word_type": "<string>" //noun, verb, adjective
        },
        "results_type": "pre_computed"
  
      },
      "assessment_type": "USER_TRAINING/SLP_TRAINING",
      "plan_name": "Name a picture"
    }
  } -->
  
  
  <!--
  {
    "Name a picture": { --decide the module name ID (this or 'Word Naming')
      "<key>": { --move under 'exercises'
        "audio_address": "<empty>", --not used remove
        "resultsTablePre": {
          "audio_address": "<empty>", --not used remove
          "category": "<string>",
          "difficulty": "<string>",
          "exercise": "Word Naming", --change to 'Name of picture'
          "exercise_type": "Word Naming", --change to 'Name a picture'
          "results": {
            "all_results": {
              "Correct": "<int>",
              "Identified_Answer": "string",
              "Incorrect": "<int>", //right now this is always 0, but we should improve
              "Show_Answer": "<bool>",
              "Show_Options": "<bool>",
              "Sound_Hints": "<bool>",
              "Total": "<int>" //number tries
            },
            "plot_results": {
              "Correct": "<int>",
              "Incorrect": "<int>"
            }
          },
          "word": "<target_word>",
          "word_type": "<string>" //noun, verb, adjective
        },
        "results_type": "pre_computed" --not used remove
  
      },
      "assessment_type": "USER_TRAINING/SLP_TRAINING",
      "plan_name": "Name a picture" --not used remove
    }
  } -->