export function valueCrawler(firebaseHandle, target_key, dest_array)
{

    firebaseHandle.forEach(function (childSnapshot) {

    let c_snapshot = childSnapshot

    if(childSnapshot.hasChildren())
    {


        valueCrawler(c_snapshot, target_key, dest_array)

    }
    else if(childSnapshot.key == target_key)
    {
        dest_array.push(childSnapshot.val())
    }
    })
}

export function child_key_value_Crawler(firebaseHandle, target_key, target_value, dest_array)
{

    firebaseHandle.forEach(function (childSnapshot) {

        let c_snapshot = childSnapshot



        if(childSnapshot.hasChildren())
        {
            child_key_value_Crawler(c_snapshot, target_key, target_value, dest_array)
        }
        else((typeof childSnapshot.val() === 'string' || childSnapshot.val() instanceof String))
        {

            let value =  childSnapshot.val()


            let value_test = value==target_value


            if(value_test && childSnapshot.key==target_key)
            {

                dest_array.push(firebaseHandle)

            }
        }
    })
}

export function keyCrawler(firebaseHandle, target_key, dest_array)
{

    firebaseHandle.forEach(function (childSnapshot) {

        let c_snapshot = childSnapshot


        if(childSnapshot.key == target_key)
        {
            dest_array.push(firebaseHandle)
        }
        else if(childSnapshot.hasChildren())
        {

            keyCrawler(c_snapshot, target_key, dest_array)

        }
    })
}

export function keyCrawler2(firebaseHandle, target_key, dest_array)
{

    firebaseHandle.forEach(function (childSnapshot) {

    let c_snapshot = childSnapshot

        if(childSnapshot.hasChildren())
        {
            keyCrawler(c_snapshot, target_key,dest_array)

        }
        else if(childSnapshot.key == target_key)
        {
            console.debug('found2')

            dest_array.push(firebaseHandle)
        }
    })

}