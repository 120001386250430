<template>
  <div class="row d-flex mb-3" style="min-height: 100vh;">
    <div class="col-xl-10 pe-0 d-flex flex-column">
      <div class="card card-body h-100">
        <div class="row">
          <p style="font-size: x-large">In this game you will see a few numbers (like the ones shown below). <br> <br> Click on the numbers in an increasing order. <br><br> For the example below, you should click in the following sequence: 1 <i class="bi bi-caret-right-fill"></i> 3 (three) <i class="bi bi-caret-right-fill"></i> 6 <i class="bi bi-caret-right-fill"></i> 7<br><br> The difficulty level will increase as you are getting better at it.</p>
        </div>
        <div class="row">
          <img :src="numImg" class="mx-auto d-block" style="width:500px">
        </div>
        
      </div>
    </div>
    <div class="col-xl-2" style="padding-left: 0px">
      <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
        <button class="btn btn-primary btn-lg w-100 mt-4" @click="startGame()">Start Game</button>
      </div>
    </div>
  </div>
</template>
<script>


import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import numImg from '@/assets/images/instructions/num_sequence3.png'
import { useTTS } from '@/stores/tts'

export default {
  name: 'Instructions',
  props: 
  {

  },
  emits: ["showGame"],
  data(){
    // NewsPopup() {
    return {
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      numImg:numImg,
      bv_tts: useTTS().tts_engine,

      
    }
  },
 
  //
  created() {
    let _this = this;
    _this.start_object = _this.$route.params.start_object;

    _this.userCategory = localStorage.getItem('userCategory');
    _this.region = localStorage.getItem('region');

  

  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

  },
  mounted()
  {
    setTimeout(() => {
      this.bv_tts.say_sentences(['In this game you will see a few numbers, like the ones shown below.','Click on the numbers in an increasing order. '])
    }, 500)

  },
  methods: {
    startGame()
    {
      this.$emit('showGame', true)
    },
    gotoClick(num)
    {
      this.clicked = [false,false,false,false];
      this.clicked[num] = true;
    },
    
  },
}
</script>
<style scoped>

</style>