<template>
  <div >
    <h1 class="display-5">{{title}}</h1>
    <div class="card card-body">
      <div class="m-4">
      <p class="lead">{{ instruct }}</p>
      <div class="card card-body">
        <div class="mb-4" v-for="row in qn_size" :key="row">
          <h5>
              {{row}}. {{qs[row-1]}}
          </h5>
          <h1></h1>
          <h1></h1>
          <h1></h1>
          <h1></h1>
          <FormKit
            v-model="form.options[row-1]"
            :options="radio_options"
            wrapper-class= "form-check-inline ms-4"
            input-class= "form-check-input ms-2"
            option-class= "list-group"
            options-class= "list-group list-group-horizontal justify-content-center mt-4"
            type="radio"
          />
          <h1></h1>
          <h1></h1>
          <h1></h1>

          <hr/>

        </div>
        <button class="btn btn-primary btn-lg"  @click="checkresult()"> Save Results</button>
      </div>

    </div>
  </div>
</div>

</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {VHI_computeZScore, VHI_computeStandardScore} from '@/utils/session/exercise_type/vhi/vhi'

import {getTodayString} from '@/media_input/utils'
import { Modal } from 'bootstrap'

export default {
  name: 'vhi',
  props: {
      exForm: {
        type: Object,
      },
    },
  data(){
    return {
      title:'',
      questionnaire_type:'',
      instruct:'',
      questionnaire_data:{},
      questionnaire_address:'/Exercises/General/Questionnaires/VHI',
      qn_size:10,
      results_dict:null,
      qs:[],
      rating:[],
      q_type:[],
      radio_options: [],
      rate_scores:[0,1,2,3,4],
      population_mean: 0,
      population_std: 1,
      z_score: 0,
      z_score_meaning: '',
      showResults: false,
      form:{
        options:[]
      },
      msg_color: '',
      results_modal: ''
    }
  },
  watch: {
    showResults(new_results, old_results) {
      if (new_results) {
          this.results_modal = new Modal(document.getElementById('results_modal'));
          this.results_modal.show();
      } else {
        if (this.results_modal) {
          this.results_modal.hide();
        }
      }
    }
  },
  async created() {
    await this.loadQuestionnaire();
  },
  methods: {
    async loadQuestionnaire()
    {
      let _this = this;

      this.questionnaire_address = this.exForm.questionnaire_address

    

      let ref_questionnaires = _this.questionnaire_address
      var do_once = true;
      let count = 0;
      _this.qs = [];
      _this.rating = []
      _this.q_type = []


      return new Promise(resolve => {


      getData(ref_questionnaires , function (err, result) {

          if (do_once) {

            do_once = false;

            let curr_results = result.val()

            _this.questionnaire_data = curr_results

            _this.instruct = curr_results['instruct']

            _this.questionnaire_type = curr_results['questionnaire_type']

            let questions = curr_results['Ex']
            
              for(let k in questions)
              {
                _this.qs.push(questions[k]);

              }

              

              if('QuestionType' in curr_results)
              {

                let question_types = curr_results['QuestionType'] 

                            
                for(let k in question_types)
                {
                  _this.q_type.push(question_types[k]);

                }


              }

              

              _this.qn_size = questions.length


            for(let i=0; i<_this.rate_scores.length;i++)
            {
                let input = {judge:'',score:i}
                _this.rating.push(input);
            }
            result.child('options').forEach(function (childSnapshot) {

              _this.rating[childSnapshot.val()].judge = childSnapshot.key;
              
            }); 

            for(let k in _this.rating)
            {
              _this.radio_options.push(
              {
                label: _this.rating[k].judge,
                value: _this.rating[k].judge,              
              })
            }
            
            _this.radio_options = _this.radio_options.slice()
            // _this.radio_options = Object.assign({}, ..._this.rating.map((i) => ({[i.judge]: i.score})))

            for(let k in _this.radio_options)
            {
              console.debug('_this.radio_options['+k+'] = ' + _this.radio_options[k])
            }

            resolve()
          }


        });
      })
    },
    checkresult()
    {
        const _this = this
        // console.debug('form option= '+this.form.options)

        let total_value = 0
        let total_questions = 0
         
        _this.results_dict = {
          chosen_options:_this.form.options,
          // questions:_this.qs,
          // options:_this.rating
          
          questionnaire_address:_this.questionnaire_address


        }

        let rating_scores = []

        for(let i in _this.form.options)
        {
          let curr_result = _this.form.options[i]

          let curr_result_value = 0

          for(let k in _this.rating)
          {
            if(_this.rating[k].judge == curr_result)
            {
              curr_result_value = k

              total_value+=parseInt(curr_result_value)

              rating_scores.push(parseInt(curr_result_value))

              total_questions+=1
            }
          }

        }

        this.results_dict['rating_scores'] = rating_scores


        if(total_questions==_this.qn_size)
        {
          this.make_computations()
        }
        else
        {
          alert("There are still some unanswered questions. They all need to be filled before computing the Voice Handicap Index.")
        }
    },
    make_computations()
    {
      this.results_dict['Questionnaire_Name'] = ''

      if(this.questionnaire_type==='vhi')
      {
        this.results_dict['VHI'] = {}

        if('Stats' in this.questionnaire_data)
        {
          this.results_dict['Questionnaire_Name'] = 'VHI 30 Questions'
          let population_mean = this.questionnaire_data['Stats']['Mean']
          let population_std = this.questionnaire_data['Stats']['STD']


          let vhi_z_score = VHI_computeZScore(this.form.options,this.rating,population_mean,population_std)

          for(let k in vhi_z_score)
          {
            this.results_dict['VHI'][k] = vhi_z_score[k]
          }

        }
        else
        {
          this.results_dict['Questionnaire_Name'] = 'VHI 10 Questions'

        }


        let vhi_standard_score = VHI_computeStandardScore(this.form.options,this.rating,this.q_type)
   
        for(let k in vhi_standard_score)
        {
          this.results_dict['VHI'][k] = vhi_standard_score[k]
        }


 
      }


      this.saveResults()
    },
    saveResults()
    {
      const _this = this

      if(_this.results_dict!=null)
      {

        for(let k in this.results_dict)
        {
          console.debug('this.results_dict['+k+'] = ' + this.results_dict[k])
        }



        var questionnaire_data = {resultsTablePre: {audio_address:'',exercise_type: 'Questionnaire', exercise: this.questionnaire_type, results: _this.results_dict}}

        // _this.exForm.curr_exercise_path = 'VHI'
        
        // _this.exForm.module_name = 'VHI'
        save_pre_computed_results(_this.exForm,_this.$firebase,questionnaire_data)

        // _this.bv_points.add(10)

        _this.$emit('logExerciseResults', questionnaire_data);
      }

      _this.$emit('nextExercise');
    },

    
  },
}
</script>
<style scoped>
.page_title {
  width: 100%;
  height: 40px;
  background-color: #7cc543;
}
.page_main {
  width: 100%;
  height: calc(100vh - 40px);
  background-color: #195e52;
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 40px;
  margin-left: 2%;
  font-size: 30px;
}
.el-menu-item{
  font-size:20px;
}

</style>