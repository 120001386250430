<template>
  
  <div class="row mt-2" >
    <div class="card card-body" v-if="userCategory !== 'patient'">
      <div>
        <span class="custom-text">Intelligibility analysis results</span>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Ground Truth</th>
            <th scope="col">Loudness (dB)</th>

            <th scope="col">Accuracy</th>

            <th scope="col">Phoneme Quality</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in word_intelligibility" v-bind:key="index">
            <td>{{ row.GroundTruth }}</td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.Accuracy }}</td>

            <td class="text-start">
              <div class="mt-2">
                <p class="lead" :class="['d-inline p-1 me-1']" :style="'cursor: pointer;color:white;background-color:'+row.phoneme_intelligibility_info[id]['colour']" v-for="(dummy, id) in row.phoneme_intelligibility_info" :key="id" @click="listenPhoneme(row.phonemes_display[id])">
                  {{row.phonemes_display[id]}}
                </p>
              </div>
              <div class="mt-2">
                <b v-for="(pho_word, id) in row.phoneme_word_intelligibility_info" :key="id" :style="'color:'+row.phoneme_word_intelligibility_info[id]['colour'].toLowerCase()" class="custom-text20"> 
                {{row.phoWord_display[id]}}
                </b>
                <!-- <p class="lead" :class="['d-inline', {'text-danger' : row.PhoWordGrade[id] === 'Needs Improvement', 'text-success' : row.PhoWordGrade[id] === 'Very Good', 'text-warning' : row.PhoWordGrade[id] !== 'Very Good' && row.PhoWordGrade[id] !== 'Needs Improvement'}]" style="cursor: pointer;" v-for="(phoneme, id) in row.PhoWordGrade" :key="id"><strong>{{row.phoWord_display[id]}}</strong></p> -->
              </div>
              <!-- <div class="mt-2">
                <p class="lead"  v-for="(phoneme, id) in row.PhoWordGrade" :key="id"><strong>{{row.PhonemeQuality[id]}}</strong></p>
              </div> -->

            </td>
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
            <td><button class="btn btn-primary" @click="listenHowToSayIt(row['GroundTruth'])"><i class="bi bi-ear"></i> Hear it </button></td>
          </tr>
          <tr class="table-group-divider" v-if="word_intelligibility && word_intelligibility.length > 1"> 
            <th scope="col">Average value</th>
            <th scope="col">{{ (word_intelligibility.reduce((sum, value) => sum + value.loudness, 0) / word_intelligibility.length).toFixed(0) }}</th>
            <th scope="col">{{ (word_intelligibility.reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / word_intelligibility.length).toFixed(0) + '%' }}</th>
            <th scope="col" colspan="2">
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[3].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[3].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[2].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[2].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[1].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[1].grade}} </span></div>
              <div class="d-inline"><div class="d-inline" :style="'background-color:'+intelligibility_grading[0].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[0].grade}} </span></div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card card-body" v-else>
      <div>
        <p class="h4 text-start">Intelligibility analysis results</p>
      </div>
      <table class="table mt-4">
        <thead>
          <tr>
            <th scope="col">Target words</th>
            <th scope="col">Loudness (dB)</th>

            <th scope="col">Accuracy</th>
            <th scope="col"><div class="ms-4 d-flex">What does the grade mean?</div></th>

            <th scope="col">Pronunciation breakdown</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in word_intelligibility" v-bind:key="index">
            <th>{{ row.GroundTruth }}</th>

            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.Accuracy }}</td>
            <td><div class="ms-4 d-flex text-start">{{ row.GradeMeaning }}</div></td>


            <td><b v-for="(pho_word, id) in row.phoneme_word_intelligibility_info" :key="id" :style="'color:'+row.phoneme_word_intelligibility_info[id]['colour'].toLowerCase()" class="custom-text20"> 
            {{row.phoWord_display[id]}}</b>
            </td>

            <!-- <td><b v-for="(pho_word, id) in row.PhoWordGrade" :key="id" :style="row.PhoWordGrade[id] === 'Needs Improvement' ? 'color:#ff0000' : row.PhoWordGrade[id] === 'Very Good' ? 'color:#00b33c' : 'color:#cca300'" class="custom-text20"> 
            {{row.phoWord_display[id]}}</b>
            </td> -->
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
            <td><button class="btn btn-primary" @click="listenHowToSayIt(row['GroundTruth'])"><i class="bi bi-ear"></i> Hear it </button></td>
          </tr>
          <tr class="table-group-divider" v-if="word_intelligibility && word_intelligibility.length > 1"> 
            <th scope="col">Average value</th>
            <th scope="col">{{ (word_intelligibility.reduce((sum, value) => sum + value.loudness, 0) / word_intelligibility.length).toFixed(0) }}</th>
            <th scope="col">{{ (word_intelligibility.reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / word_intelligibility.length).toFixed(0) + '%' }}</th>
            <th scope="col" colspan="2">
              <div class="ms-4 d-flex">
                <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[3].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[3].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[2].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[2].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+intelligibility_grading[1].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[1].grade}} </span></div>
              <div class="d-inline"><div class="d-inline" :style="'background-color:'+intelligibility_grading[0].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{intelligibility_grading[0].grade}} </span></div>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import { useTTS } from '@/stores/tts'
import firebase from 'firebase/app'
import constants from '@/utils/constants'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
  name: 'word_intelligibility',
  components: {
    Audio_Playback
  },
  props: {
    word_intelligibility: {
      type: Object,
      default: null
    },
  },
  setup(props) {
    const userCategory = ref(localStorage.getItem('userCategory'))

    const intelligibility_grading = constants.intelligibility_grading_strict

    const audio_player = ref(null)

    const playAudio = (audio_file_location) => {

      let storage = firebase.storage()
      storage.ref(audio_file_location).getDownloadURL().then((url) => {
        audio_player.value = new Audio(url);
        audio_player.value.play();

        audio_player.value.addEventListener('ended', () => {
          audio_player.value = null
        });

      }).catch((error) => {

      })
    }


    const listenHowToSayIt = (text) => {
      let word = text.toLowerCase()

      let storage_file = '/BV/audio_resources/audio_hints/sample_words/' + word + '.mp3'
      let storage = firebase.storage()

      storage.ref(storage_file).getDownloadURL().then(onResolve, onReject);

      function onResolve(url) {
        let audio = new Audio(url);
        audio.play();
      }

      function onReject(error) {
        let bv_tts =  useTTS().tts_engine
        bv_tts.say(text)
      }
    }

    const listenPhoneme = (phoneme) => {
      let audio_file_location = 'audios/phoneme_pronunciation_new/' + phoneme + '.mp4'
      playAudio(audio_file_location)
    }
    
    onMounted( () => {

    });

    return {
      userCategory,
      audio_player,
      listenHowToSayIt,
      listenPhoneme,
      intelligibility_grading
    };

  }
}
</script>