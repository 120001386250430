<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar :enableBack=true></Navbar>
    <div class="container-fluid">
      <h1 class="display-5">Session results</h1>
      <div class="card card-body">        
        <div class="row mt-2">
          <span style="font-size:24px">Please see your analysis results below.</span>
        </div>
        <div v-if="showLoudness" class="row mt-2">
          <span class="title_txt" style="font-size:18px"> Average loudness is {{avg_loudness}} dB</span>
        </div>

        <Result_Sections :results="results"></Result_Sections>

      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>

import Result_Sections from '@/views/session/Result/result.vue'
import { getData } from '@/firebase/index'

import { roundNumber } from "@/utils/utils"
import { parseProccesedAudioExerciseData, process_resultsTablePre } from '@/utils/results/common'

import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'module_results',
  components: {
    Footer, 
    Navbar,
    Result_Sections
  },
  data() {
    return {
      exercise_data_list: [],

      avg_loudness: 0,
      
      showLoudness: false,

      results: {},

      module_name: '',      
    }
  },
  created(){

  },
  async mounted() {
    let _this = this;

    let audio_address_exercises_paths = JSON.parse(_this.$route.query.audio_address_exercises_paths) //list of exercises paths that have audio address 
    let root_path = JSON.parse(_this.$route.query.root_path) //list of exercises paths that have audio address

    _this.module_name = _this.$route.query.module_name

    let exercises_promises = []

    if( audio_address_exercises_paths && audio_address_exercises_paths.length > 0 ) {
      let n_results = audio_address_exercises_paths.length;
      
      let avg_loudness_array = []

      for(let n=0; n<n_results; n++) {
        exercises_promises.push(new Promise((resolve, reject) => {
          getData(audio_address_exercises_paths[n], async function (err, result) {
            let exercise_data = result.val();

            console.log(audio_address_exercises_paths[n])
            console.log(exercise_data)
            if (exercise_data) {
              
              _this.results = parseProccesedAudioExerciseData(exercise_data, _this.results, audio_address_exercises_paths[n])
              
              if ('loudness' in exercise_data) {

                avg_loudness_array.push(Number(exercise_data['loudness']['loudness']))
              }

              _this.results = await process_resultsTablePre(exercise_data, _this.results)

              if (avg_loudness_array.length>0) {
                _this.avg_loudness = 0

                for (let k in avg_loudness_array) {
                  _this.avg_loudness = _this.avg_loudness + avg_loudness_array[k]
                }

                _this.avg_loudness = roundNumber(_this.avg_loudness/avg_loudness_array.length, 2)
                _this.showLoudness = true
              }

              resolve(exercise_data)
            }
          });
        }))
      }



    }

    //these are for interactive session
    getData(root_path, function (err, snapshot) {
      let module_object = snapshot.val()

      if ('session_slt_id' in module_object[_this.module_name]) {
        _this.session_slt_id = module_object[_this.module_name]['session_slt_id']
      } 

      if ('exercises' in module_object[_this.module_name] && module_object[_this.module_name]['exercises'].length > 0) {

        for (let exercise_data of module_object[_this.module_name]['exercises']) {
          if (exercise_data) {
            if('session_intelligibility' in exercise_data) {
              if (!_this.results.hasOwnProperty('session_intelligibility')) {
                _this.results['session_intelligibility'] = []
              }

              if (exercise_data['session_intelligibility'].hasOwnProperty('note')) {
                if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('heard_value')) {
                  exercise_data['session_intelligibility']['note']['heard_value'] = ''
                }
                if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('intelligibility_score')) {
                  exercise_data['session_intelligibility']['note']['intelligibility_score'] = ''
                }
                if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('note')) {
                  exercise_data['session_intelligibility']['note']['note'] = ''
                }
              } else {
                exercise_data['session_intelligibility']['note'] = { 'heard_value' : '', 'intelligibility_score': '', 'note': ''}
              }
              
              _this.results['session_intelligibility'].push(exercise_data['session_intelligibility'])
            }
            
            if('session_questionnaire' in exercise_data) {
              if (!_this.results.hasOwnProperty('session_questionnaire')) {
                _this.results['session_questionnaire'] = []
              }

              let question = exercise_data['session_questionnaire']['question']
              let answer = exercise_data['session_questionnaire']['answer']
              let options = exercise_data['session_questionnaire']['options']

              let data = {question: question, answer: answer, options: options}

              _this.results['session_questionnaire'].push(data)
            }
            
            if('session_phonation' in exercise_data) {
              if (!_this.results.hasOwnProperty('session_phonation')) {
                _this.results['session_phonation'] = []
              }

              let note = exercise_data['session_phonation']['note']

              let data = {note: note}

              _this.results['session_phonation'].push(data)
            }
          }
        }
      }
    })

  },
  updated() {
  },
  methods: {
  },
}
</script>
<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .title_txt {
    float: left;
    line-height: 40px;
    margin-left: 2%;
    font-size: 30px;
  }
  .custom-text{
    font-size: 18px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }

  canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  #chartjs-radar{
    width : 60%;
    height: 60%;
  }
  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
</style>