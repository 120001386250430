<template>
  <div>
  
    <div class="row mt-2">
      <div class="card card-body" v-show="cookie_theft_results && cookie_theft_results.length > 0">
        <div>
          <p class="h4 text-start">Cookie theft results</p>
        </div>
        <div class="row mt-2 text-start">
          <span style="font-size: large;">
            <!-- Overall, your sentence pronunciation quality <b :style="results['avg_sentence_intel'] === 'needs improvement' ? 'color:#ff0000' : results['avg_sentence_intel'] === 'is very good' ? 'color:#00b33c' : 'color:#cca300'"> {{results['avg_sentence_intel']}} : {{results['avg_sentence_interp']}}</b> -->
          </span>
        </div>
        <div class="row" style="text-align: left;">
          <!-- <thead> -->
            <!-- <tr> -->
              <div class="col-md-6">Transcription</div>

              <div class="col"> 
                <button v-if="!colour_change_switch['playback']" class="btn" style="color:red;border-color: red" @click="colourChange('playback')"> Playback OFF</button>
                <button v-if="colour_change_switch['playback']" class="btn" style="background-color:red;color: white;" @click="colourChange('playback')"> Playback ON</button>
              </div>
              <div class="col"> 
                <button v-if="!colour_change_switch['subject']" class="btn" style="color:#ff00ff;border-color: #ff00ff" @click="colourChange('subject')"> Add subject</button>
                <button v-if="colour_change_switch['subject']" class="btn" style="background-color:#ff00ff;color: white;" @click="colourChange('subject')"> Adding subject</button>
              </div>
              <div class="col"> 
                <button v-if="!colour_change_switch['object']" class="btn" style="color:#ff9d5c;border-color: #ff9d5c" @click="colourChange('object')"> Add object</button>
                <button v-if="colour_change_switch['object']" class="btn" style="background-color:#ff9d5c;color: white;" @click="colourChange('object')"> Adding object</button>
              </div>
              <div class="col"> 
                <button v-if="!colour_change_switch['verb']" class="btn" style="color: #47A7F4;border-color: #47A7F4" @click="colourChange('verb')"> Add verb</button>
                <button v-if="colour_change_switch['verb']" class="btn" style="background-color:#47A7F4;color: white;" @click="colourChange('verb')"> Adding verb</button>
              </div>
              <div class="col"> 
                <button v-if="!colour_change_switch['clear']" class="btn" style="color: #6D7880;border-color: #6D7880" @click="colourChange('clear')"> Clear colour</button>
                <button v-if="colour_change_switch['clear']" class="btn" style="background-color:#6D7880;color: white;" @click="colourChange('clear')"> Clearing colour</button>
              </div>
            <!-- </tr> -->
          <!-- </thead> -->
          
        </div>
        <table class="table mt-4" style="text-align: left;">
          <!-- <thead>
            
          </thead> -->
          <tbody class="table-group-divider" >
            <tr v-for="row, index in cookie_theft_results" v-bind:key="index">
              <td>
                <strong class="editable" contenteditable="true"
                  v-for="(word, id) in row['all_words']" 
                  :key="id"
                  :style="'cursor: pointer;color:'+ row['all_words'][id]['colour'].toLowerCase()"
                  @click="action_selection_cookie_theft(row, id)"
                  @input="event => updated_row[index][id] = event.target.innerText.trim()"
                >
                  {{row['all_words'][id]['word']}}&nbsp;
                </strong>

              </td> 
              <td class="text-end">
                
                <button class="btn btn-outline-secondary me-2" v-if="updated_row[index].length > 0" @click="handleWordChange(index)">Save</button>
                <div class="btn-group"> 
                  <button v-if="audio_player == null || audio_player_index !== index" class="btn btn-primary"  @click="playRecording(row, index)">
                    <i class="bi bi-volume-up"></i> Playback
                  </button>
                  <button v-else class="btn btn-danger"  @click="pauseAudio()"><i class="bi bi-sign-stop"></i> Stop</button>
                  <button class="btn btn-secondary" @click="downloadRecording(row['AudioAddress'])"> <i class="bi bi-download"></i> </button>
                </div>
              </td>
            </tr>
            <tr class="table-group-divider"></tr>
            
          </tbody>
        </table>
        <div class="row" style="text-align: left;" v-if="cookie_theft_results && cookie_theft_results.length >= 1">
          <div class="col-md-8">Summary</div>
        </div>
        <div class="row">
          <div class="col">
            <b>Subject</b>
          </div>
          <div class="col">
            <b>Object</b>
          </div>
          <div class="col">
            <b>Verb</b>
          </div>
        </div>
        <div class="row">
          <strong class="col" v-for="(dummy, word_type) in cookie_theft_results[0]['processed_info']" :key="word_type">
            <p v-for="(dummy, word) in cookie_theft_results[0]['processed_info'][word_type]"
              :style="'cursor: pointer;color:'+cookie_theft_results[0]['colour_code'][word_type].toLowerCase()">
              {{ word }} = {{cookie_theft_results[0]['processed_info'][word_type][word]['count']  }}
            </p>
            <p :style="'cursor: pointer;color:'+cookie_theft_results[0]['colour_code'][word_type].toLowerCase()"></p>
          </strong>
        </div>
        <tr class="table-group-divider"></tr>
        <div class="row" style="text-align: left;">
          <div class="col-md-8">Total</div>
        </div>    
        <div class="row" v-if="cookie_theft_results && cookie_theft_results.length >= 1">
          <strong class="col"
            v-for="(dummy,word_type) in cookie_theft_results[0]['processed_info']" 
            :key="word_type"                       
          >
            <p>
              {{ Object.keys(cookie_theft_results[0]['processed_info'][word_type]).length }}
            </p>
          </strong>
        </div>
      </div>
    </div>

  </div>
</template>    

<script>

import {ref, onUpdated, onMounted, watch} from 'vue'
import firebase from 'firebase/app'

import {processCookieTheftInfo } from '@/utils/results/postprocessing/language_postprocessing'
import {storeUpdatedCookieTheftInfo } from '@/utils/results/saving/language_saving'
import { update } from 'lodash';



export default {
    name: 'cookie_theft_section',
    components: {
    },
    props: ['results'],
    setup(props, {emit}) {

      onMounted( () => {

      })

      watch(
        () => props.results['cookie_theft'],
        (newResults) => {
            cookie_theft_results.value = newResults;
        },
        { immediate: true }
      );

      const cookie_theft_results = ref(props.results['cookie_theft'])

      const colour_change_switch = ref({
          verb: false,
          object: false,
          clear: false,
          playback: true
      })
      const target_colour = ref('')
      const target_identified_word_type = ref('')
      const audio_player_index = ref(-1)
      const audio_player = ref(null)
      const updated_row = ref({ 0: []})

      const action_selection_cookie_theft = (row, id) =>{
        if(target_colour.value != '')
        {
          row['all_words'][id]['colour'] = target_colour.value
          row['all_words'][id]['identified_word_type'] = target_identified_word_type.value
        }

        
        let offset = row['all_words'][id]['Offset']


        if((offset!=undefined || offset!=null) && colour_change_switch.value['playback']) {
          offset = offset/10000000
          playRecording(row,0,offset)
        }
        


        let temp = processCookieTheftInfo(row['all_words'])
        console.log(temp)
        row['all_words'][id] = temp[1][id]

        cookie_theft_results.value[0]['processed_info'] = temp[0] //processed_info

        storeUpdatedCookieTheftInfo(row['exercise_path'], temp[1]) //all_words updated
      }

      const handleWordChange = (index) => {
        
        for (let i=0; i < updated_row.value[index].length; i++) {
          let word = updated_row.value[index][i]
          if (word != null) {
            console.log(cookie_theft_results.value[0]['all_words'][i]['word'])
            cookie_theft_results.value[0]['all_words'][i]['word'] = word
          }
        }
        updated_row.value[index] = []

        let temp = processCookieTheftInfo(cookie_theft_results.value[0]['all_words'])

        cookie_theft_results.value[0]['processed_info'] = temp[0] //processed_info

        storeUpdatedCookieTheftInfo(row['exercise_path'], temp[1]) //all_words updated
      }

      const colourChange = (categ) =>{
        
        colour_change_switch.value[categ] = !colour_change_switch.value[categ]

        if(categ == 'subject')
        {

          if(colour_change_switch.value[categ] == true)
          {
            target_colour.value = '#ff00ff'
            target_identified_word_type.value = 'characters'

            colour_change_switch.value['verb'] = false
            colour_change_switch.value['object'] = false
            colour_change_switch.value['clear'] = false
            colour_change_switch.value['playback'] = false


          } else {
            target_identified_word_type.value = ''

            target_colour.value = ''
          }
        }
        else if(categ == 'verb')
        {
          if(colour_change_switch.value[categ] == true)
          {
            target_colour.value = '#47A7F4'

            target_identified_word_type.value = 'actions'

            colour_change_switch.value['subject'] = false
            colour_change_switch.value['object'] = false
            colour_change_switch.value['clear'] = false
            colour_change_switch.value['playback'] = false

          }
          else
          {
            target_identified_word_type.value = ''

            target_colour.value = ''
          }
        }
        else if(categ == 'object')
        {
          if(colour_change_switch.value[categ] == true)
          {
            target_colour.value = '#ff9d5c'
            target_identified_word_type.value = 'objects'

            colour_change_switch.value['verb'] = false
            colour_change_switch.value['subject'] = false
            colour_change_switch.value['clear'] = false
            colour_change_switch.value['playback'] = false


          }
          else
          {
            target_identified_word_type.value = ''

            target_colour.value = ''
          }
        }
        else if(categ == 'clear')
        {
          if(colour_change_switch.value[categ] == true)
          {
            target_colour.value = '#000000'
            target_identified_word_type.value = ''

            colour_change_switch.value['verb'] = false
            colour_change_switch.value['subject'] = false
            colour_change_switch.value['object'] = false
            colour_change_switch.value['playback'] = false

          }
          else
          {
            target_identified_word_type.value = ''

            target_colour.value = ''
          }
        }
        else if(categ == 'playback')
        {
          if(colour_change_switch.value[categ] == true)
          {


            target_identified_word_type.value = ''

            target_colour.value = ''
            colour_change_switch.value['verb'] = false
            colour_change_switch.value['subject'] = false
            colour_change_switch.value['object'] = false
            colour_change_switch.value['clear'] = false


          }
          else
          {
            target_identified_word_type.value = ''

            target_colour.value = ''
          }
        }
        else
        {
          console.debug('category unknown = '+categ)
        }
      }



      const playRecording = (row,index,start_time=0) => {
        audio_player_index.value = index
        let address = row['AudioAddress']
        playAudio(address,start_time)
      }

      const playAudio = (audio_file_location, start_time=0) => {

        let storage = firebase.storage()
        storage.ref(audio_file_location).getDownloadURL().then((url) => {

          if(audio_player.value)
          {
            audio_player.value.pause()
            audio_player.value = null
          }



          audio_player.value = new Audio(url);
          audio_player.value.currentTime = start_time


          audio_player.value.play();
          
          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });

        }).catch((error) => {

        })
        }

      const pauseAudio = () => {
        audio_player.value.pause()
        audio_player.value = null
      }

      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      const downloadRecording = (address) => {
        let storage = firebase.storage();

        storage.ref(address).getDownloadURL().then((url) => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {

        });
      }

      return {
        handleWordChange,
        action_selection_cookie_theft,
        colourChange,
        playRecording,
        downloadRecording,
        pauseAudio,
        colour_change_switch,
        target_colour,
        target_identified_word_type,
        audio_player,
        audio_player_index,
        cookie_theft_results,
        updated_row

        
      };

    }
  }
  </script>