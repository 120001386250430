



export async function sendMessage(conversationHistory,role) {

    console.debug('SendMessage')

    // appendMessage('You', userInput);
    // conversationHistory.push({ role: 'user', content: userInput }); // Add user input to conversation history

    const botReply = await interactWithChatbot(role,conversationHistory);

    console.debug('botReply = ') + botReply
    
    // appendMessage('ChatGPT', botReply);
    // conversationHistory.push({ role: 'assistant', content: botReply }); // Add assistant's reply to history

    return botReply

}

export async function interactWithChatbot(role,conversationHistory) {

    console.debug('interactWithChatbot')
    const messages = conversationHistory.map(msg => ({ role: msg.role, content: msg.content }));
    const prompt = `You are a ${role}.` + (messages.length > 0 ? "\nUser says: " + messages.map(msg => `${msg.role}: ${msg.content}`).join("\n") : "");

    console.debug('prompt = ' + prompt)

    let apiKey = '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'

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + apiKey
        },
        body: JSON.stringify({
            model: 'gpt-3.5-turbo',  // or 'gpt-4' if you have access
            messages: [{ role: 'system', content: `You are a ${role}.` }, ...messages] // Include conversation history in the request
        })
    });

    const data = await response.json();

    console.debug(data)
    return data.choices[0].message.content;
}