<template>
  <div>
    <div class="card card-body mt-2">
      <span style="display:flex;justify-content:center;font-size:22px"> {{name}}</span>
      <div class="row mt-2 justify-content-center">
        <div class="col-4">
          <div class="row">
            <span class="custom-text"> Correct answers: {{ correct }}</span>
          </div>
          <div class="row">
            <span class="custom-text"> Incorrect answers: {{ incorrect }} </span>
          </div>
        </div>
        <div class="col-2">
          <div style="margin:auto">
            <canvas ref="canvasRef" style="height:175px; width:100%;"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import { ref, onUpdated, onMounted, watch, nextTick } from 'vue';
  import Chart from 'chart.js/auto';
  import 'chartjs-adapter-luxon';

  export default {
    name: 'correct_incorrect_indicator',
    components: {},
    props: {
      correct: {
        type: Number,
        default: 0
      },
      incorrect: {
        type: Number,
        default: 0
      },
      name: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      const chartRef = ref(null); // Store chart instance
      const canvasRef = ref(null); // Reference to the canvas
      
      const correct_prop = props.correct
      const incorrect_prop = props.incorrect

      const createPieChart = (correct, incorrect) => {
        console.log('Creating pie chart with correct:', correct, 'and incorrect:', incorrect);

        const data = {
          labels: ['Correct', 'Incorrect'],
          datasets: [{
            data: [correct, incorrect],
            backgroundColor: ['rgba(99, 255, 64, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            borderColor: ['rgba(99, 255, 64, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1
          }]
        };

        const options = {
          responsive: true,
          maintainAspectRatio: false
        };

        if (chartRef.value) {
          chartRef.value.destroy();
        }

        const ctx = canvasRef.value?.getContext('2d');
        if (!ctx) {
          console.error('Canvas rendering context not available');
          return;
        }
        
        chartRef.value = new Chart(ctx, {
          type: 'pie',
          data,
          options
        });

        console.log('New chart created:', chartRef.value);

        if (canvasRef.value) {
          canvasRef.value.style.width = '100%'; 
          canvasRef.value.style.height = '175px';
          canvasRef.value.width = canvasRef.value.offsetWidth;
          canvasRef.value.height = 175; 
          //chartRef.value.resize();
        }
      };

      onMounted(() => {
        createPieChart(correct_prop, incorrect_prop);
      });

      //this watch doesn't work with chart.js
      watch([() => props.correct, () => props.incorrect], ([newCorrect, newIncorrect], [oldCorrect, oldIncorrect]) => {
        console.log('Props changed:');
        console.log('New values - correct:', newCorrect, 'incorrect:', newIncorrect);
        console.log('Old values - correct:', oldCorrect, 'incorrect:', oldIncorrect);
        if (!canvasRef.value) {
          console.error('Canvas is not available in watch');
          return;  // Exit if canvas is null
        } else {

          console.error("Canvas is available")
          nextTick(() => {
            console.error(canvasRef.value?.getContext('2d'))
            //createPieChart(newCorrect, newIncorrect);

          })
        }

      });

      return {
        canvasRef
      };
    }
  }
</script>
