<template>
  
  <div class="row mt-2">
    <div class="card card-body" v-if="userCategory == 'patient'">
      <div>
        <span class="custom-text">Sentence intelligibility analysis results</span>
        <!-- <button style="float: right; padding: 3px 0" type="text">Operation button</button> -->
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <td></td>
            <!-- <th scope="col">Ground Truth</th> -->
            <th scope="col">Loudness (dB)</th>

            <th scope="col">Accuracy</th>

            <th scope="col">Word Quality</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in sentence_intelligibility" v-bind:key="index">
            <!-- <td>{{ row.GroundTruth }}</td> -->
            <td></td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.Accuracy }}</td>

            <td>
              <p class="lead text-wrap">
                <strong
                  v-for="(word, id) in row.sentence_intelligibility_info" 
                  :key="id" 
                  :style="'cursor: pointer;color:'+row.sentence_intelligibility_info[id]['colour'].toLowerCase()"
                  @click="select_word_sentence_intelligibility(row,index, id)">
                  {{row.Words[id]}}&nbsp;
                </strong>

                <!-- <strong
                v-for="(word, id) in row.WordGrade" 
                :key="id" 
                :class="[{'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" 
                style="cursor: pointer;" @click="listenPhoneme(row.Words[id])"
                >
                {{row.Words[id]}}&nbsp;
                </strong>  -->
                <!-- <td><b v-for="(pho_word, id) in row.phoneme_word_intelligibility_info" :key="id" :style="'color:'+row.phoneme_word_intelligibility_info[id]['colour'].toLowerCase()" class="custom-text20"> 
                  {{row.phoWord_display[id]}}</b>
                </td> --> 

              </p>
            </td>
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
          </tr>
          <tr class="table-group-divider" v-if="sentence_intelligibility && sentence_intelligibility.length >= 1"> 
            <th scope="col">Average value</th>
            <th scope="col">{{ (sentence_intelligibility.reduce((sum, value) => sum + value.loudness, 0) / sentence_intelligibility.length).toFixed(0) }}</th>
            <th scope="col">{{ (sentence_intelligibility.reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / sentence_intelligibility.length).toFixed(0) + '%' }}</th>
            <th scope="col">
                <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[4].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[4].grade}} </span></div>
                <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[3].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[3].grade}} </span></div>
                <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[2].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[2].grade}} </span></div>
                <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[1].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[1].grade}} </span></div>
                <div class="d-inline"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[0].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[0].grade}} </span></div>

                <!--                   <div class="d-inline me-3"><div class="bg-success d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Good </span></div>
              <div class="d-inline me-3"><div class="bg-warning d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>OK </span></div>
              <div class="d-inline me-3"><div class="bg-danger d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Need improvement </span></div>
              <div class="d-inline"><div class="bg-secondary d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Did not recognise </span></div> -->
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card card-body" v-else>
      <div>
        <p class="h4 text-start">Sentence intelligibility analysis results</p>
      </div>
      <div class="row mt-2 text-start">
        <span style="font-size: large;">
          Overall, your sentence pronunciation quality <b :style="avg_sentence_intel === 'needs improvement' ? 'color:#ff0000' : avg_sentence_intel === 'is very good' ? 'color:#00b33c' : 'color:#cca300'"> {{avg_sentence_intel}} : {{avg_sentence_interp}}</b>
        </span>
      </div>
      <table class="table mt-4" style="text-align: left;">
        <thead>
          <tr>
            <!-- <th scope="col">Reference</th> -->

            <th scope="col">Loudness (dB)</th>

            <th scope="col">Accuracy</th>

            <th scope="col">Pronunciation breakdown</th>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="table-group-divider" >
          <tr v-for="row, index in sentence_intelligibility" v-bind:key="index">
            
            <!-- <td>{{ row.GroundTruth }}</td> -->

            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.Accuracy }}</td>

            <td>
              <strong
              v-for="(word, id) in row.sentence_intelligibility_info" 
              :key="id" 
              :style="'cursor: pointer;color:'+row.sentence_intelligibility_info[id]['colour'].toLowerCase()"
              @click="select_word_sentence_intelligibility(row,index,id)"
              >
              {{row.Words[id]}}&nbsp; 
              </strong>
              <!-- <strong
              v-for="(word, id) in row.WordGrade" 
              :key="id" 
              :class="[{'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" 
              style="cursor: pointer;" @click="listenPhoneme(row.Words[id])"
              >
              {{row.Words[id]}}&nbsp;
              </strong> -->
            </td>
            
            <td>
              <div class="btn-group"> 
                <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
              </div>
            </td>
          </tr>
          <tr class="table-group-divider" v-if="sentence_intelligibility && sentence_intelligibility.length >= 1"> 
            
            <th scope="col">{{ (sentence_intelligibility.reduce((sum, value) => sum + value.loudness, 0) / sentence_intelligibility.length).toFixed(0) }}</th>
            <th scope="col">{{ (sentence_intelligibility.reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / sentence_intelligibility.length).toFixed(0) + '%' }}</th>
            <th scope="col">
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[4].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[4].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[3].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[3].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[2].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[2].grade}} </span></div>
              <div class="d-inline me-3"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[1].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[1].grade}} </span></div>
              <div class="d-inline"><div class="d-inline" :style="'background-color:'+sentence_intelligibility_grading[0].colour">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>{{sentence_intelligibility_grading[0].grade}} </span></div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import constants from '@/utils/constants'
import firebase from 'firebase/app'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

export default {
  name: 'sentence_intelligibility',
  components: {
    Audio_Playback
  },
  props: {
    sentence_intelligibility: {
      type: Object,
      default: null
    },
    avg_sentence_intel: {
      type: String,
      default: null
    },
    avg_sentence_interp: {
      type: String,
      default: null
    },
  },
  setup(props) {
    const userCategory = ref(localStorage.getItem('userCategory'))
    
    const sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict

    onMounted( () => {

    });

    const audio_player = ref(null)

    
     
      

    const playAudio = (audio_file_location,start_time=0) => {


      let storage = firebase.storage()
      storage.ref(audio_file_location).getDownloadURL().then((url) => {

        if(audio_player.value)
        {
          audio_player.value.pause()
          audio_player.value = null
        }

        audio_player.value = new Audio(url);
        audio_player.value.currentTime = start_time

        audio_player.value.play();
        
        audio_player.value.addEventListener('ended', () => {
          audio_player.value = null
        });

      }).catch((error) => {

      })
    }

    const playRecording = (row,index,start_time=0) => {
        // audio_player_index.value = index
        let address = row['AudioAddress']
        playAudio(address,start_time)
      }

    const select_word_sentence_intelligibility = (row,index,id) =>
    {

      // this.a = row

      let offset = row['Offset'][id]

      if(offset!=undefined || offset!=null)
      {
        if(offset<0)
        {
          offset = 0
          
        }
        offset = offset/10000000
        console.debug('offset 2 = ' + offset)
        playRecording(row,index,offset)
      }
    }
    
    return {
      userCategory,
      sentence_intelligibility_grading,
      select_word_sentence_intelligibility,
    };

  }
}
</script>