<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <div class="card card-body">
            <h2>
              {{ instructions_text }}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="runTTS()">
            </h2>
            <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->

          </div>
        </div>
        <div class="row" style="height: 10mm;">
          <p class="h5" :style="interaction_message_color"> {{  interaction_message}}</p> 
        </div>

        <div v-if="task==3" class="row">
          <div class="d-flex justify-content-center">
            <button v-for="i in full_text_array.length" :key="i" :class="btn_class[i-1]" style="margin-left: 2mm;font-size: xx-large;" @click="clickWord(i-1)">
              {{ full_text_array[i-1] }}
            </button>

          </div>
        </div>
        <div v-if="task!=3" style="cursor: default;">
        <div class="row">
          <div class="bg-dark text-white">
            <h1  style="margin-bottom:40px">MENU</h1>
            <!-- <hr style="border: 10px solid #fff;"> -->
          </div>
          </div>
        <div class="row">

          <div  class="col-md-4 bg-dark text-white">
            <div v-if="menu['starter'].length>0">
              <div>
                  <h2 style="margin-bottom:1px">STARTER</h2>
                  <hr style="border: 5px solid #fff;">
              </div>
              <div v-for="i,index in menu['starter']" :key="i" @click="selectMenuItem('starter',index)" style="cursor: pointer;">

                <div v-if="selected_dish.course==='starter' && selected_dish.index==index" class="bg-light text-black" >
                  <h3>{{ i.name}} </h3>
                  <h4> £{{ i.price }}</h4>

                  <!-- <p v-if="'ingredients' in i"></p> -->

                  <hr v-if="index<menu['starter'].length-1">
                  <hr v-else style="border: 5px solid #ff0000;">


                </div>
                <div v-else>

                  <h3>{{ i.name}} </h3>
                  <h4> £{{ i.price }}</h4>

                  <!-- <p v-if="'ingredients' in i"></p> -->

                  <hr v-if="index<menu['starter'].length-1">
                  <hr v-else style="border: 5px solid #ff0000;">

                </div>
                
              </div>
              
                
            </div>
          </div>
          <div  class="col-md-4 bg-dark text-white">
            <div v-if="menu['main'].length>0">
              <div>
                  <h2 style="margin-bottom:1px">MAIN</h2>
                  <hr style="border: 5px solid #fff;">
              </div>
              <div v-for="i,index in menu['main']" :key="i" @click="selectMenuItem('main',index)" style="cursor: pointer;">
                <div v-if="selected_dish.course==='main' && selected_dish.index==index" class="bg-light text-black" >
                    <h3>{{ i.name}} </h3>
                    <h4> £{{ i.price }}</h4>

                    <!-- <p v-if="'ingredients' in i"></p> -->

                    <hr v-if="index<menu['main'].length-1">
                    <hr v-else style="border: 5px solid #ff0000;">


                  </div>
                  <div v-else>

                    <h3>{{ i.name}} </h3>
                    <h4> £{{ i.price }}</h4>

                    <!-- <p v-if="'ingredients' in i"></p> -->

                    <hr v-if="index<menu['main'].length-1">
                    <hr v-else style="border: 5px solid #ff0000;">

                  </div>
              </div>
              
                
            </div>
          </div>
          
          <div  class="col-md-4 bg-dark text-white" >

            <div v-if="menu['dessert'].length>0">
              <div>
                <h2 style="margin-bottom:1px">DESSERT</h2>
                <hr style="border: 5px solid #fff;">
              </div>
              <div v-for="i,index in menu['dessert']" :key="i" @click="selectMenuItem('dessert',index)" style="cursor: pointer;">
                <div v-if="selected_dish.course==='dessert' && selected_dish.index==index" class="bg-light text-black" >
                  <h3>{{ i.name}} </h3>
                    <h4> £{{ i.price }}</h4>

                    <!-- <p v-if="'ingredients' in i"></p> -->

                    <hr v-if="index<menu['dessert'].length-1">
                    <hr v-else style="border: 5px solid #ff0000;">

                  </div>
                  <div v-else>

                    <h3>{{ i.name}} </h3>
                    <h4> £{{ i.price }}</h4>

                    <!-- <p v-if="'ingredients' in i"></p> -->

                    <hr v-if="index<menu['dessert'].length-1">
                    <hr v-else style="border: 5px solid #ff0000;">

                  </div>
                </div>
              </div>
            </div>         
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
  
          :has_show_results="!first_exercise"
          @show_results="goToResults()"
          >
          <template v-slot:top>
            <hr/>
            <p class="lead" font-size="medium">Task {{Math.min(task,n_tasks)}} of {{n_tasks}}</p>
            <!-- <button v-show="if_recorder && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="playPause()">Record</button> -->
            <button v-show="is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>

            <button v-show="!start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="start()"><strong>{{ start_btn_text }}</strong>
              <i v-bind:class="[start_btn_text=== 'Start' ? 'bi bi-play-circle' : 'bi bi-arrow-clockwise']" style="margin-left: 8mm;"></i>
            </button>
            <button v-show="start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Reading...
            </button>
            
            <button v-if="task!=1" class="btn-info btn btn-lg w-100 mt-4" @click="nextTask()">Next task <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i></button>
            <!-- <button class="btn btn-secondary btn-lg w-100 mt-2" @click="skipExercise()">Skip level</button> -->
            <!-- show result was here -->
          </template>
        </Sidebar>

      </template>
    </Layout>
    <div class="modal fade" id="finished_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Congratulations</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body m-2">
            <div class="row">
              <div class="row">
                <h4 >{{ finished_text }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100"   data-bs-dismiss="modal" @click="nextExercise()" >Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="nothing_heard_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sorry, we couldn't hear you well</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body m-3">
            <div class="row">
              <div class="row">
                <h4 >Click on Start to try again. Or Next to skip this exercise</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Back to the exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Modal } from 'bootstrap'
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
import audioImg from '@/assets/images/audio_logo.png'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity,removePunctuationFromString} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import SpeechRec from '@/media_input/SpeechRecognition'
import { useTTS } from '@/stores/tts'

  // SLT\Exercises\Reading comprehension\Reading task
  export default {
    name: 'read_menu',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data(){
      return {
        menu_options:{
            "starter":
            {
                "simple":[   
                    {"name":"Bread"},
                    {"name":"Soup"},
                    {"name":"Salad"},
                    {"name":"Mushrooms"},
                    {"name":"Eggs"}
                ],                
                "normal":[
                    {"name": "Prawn Cocktail","ingrediends":[]},
                    {"name": "Garlic Bread","ingrediends":[]},
                    {"name": "Tomato Soup","ingrediends":[]},
                    {"name": "Carrot Soup","ingrediends":[]},
                    {"name": "Chicken Wings","ingrediends":[]},
                    {"name": "Stuffed Mushrooms","ingrediends":[]},
                    {"name": "Poached Eggs","ingrediends":[]},
                    {"name": "Spring Rolls","ingrediends":[]},
                    {"name": "Avocado Toast","ingrediends":[]}
                ]
            },
            "main":
            {
                "simple":[              
                    {"name":"Steak"},
                    {"name":"Pasta"},
                    {"name":"Tofu"},
                    {"name":"Fish"},
                    {"name":"Pork"},
                    {"name":"Rice"},
                    {"name":"Curry"},
                    {"name":"Hamburger"},
                    {"name":"Pie"},
                ],                
                "normal":[
                    {"name": "Beef Wellington", "ingredients": []},
                    {"name": "Fish and Chips", "ingredients": []},
                    {"name": "Pork Tacos", "ingredients": []},
                    {"name": "Pasta Bolognese", "ingredients": []},
                    {"name": "Vegetable Stir Fry", "ingredients": []},
                    {"name": "Pork Dumplings", "ingredients": []},
                    {"name": "Steak Pie", "ingredients": []},
                    {"name": "Ham and Cheese Pie", "ingredients": []},
                    {"name": "Cucumber Sandwich", "ingredients": []},
                    {"name": "Lamb Roast", "ingredients": []}
                ]
                
            },
            "dessert":
            {
                "simple":[          
                    {"name": "Ice cream"},
                    {"name": "Cake"},
                    {"name": "Fruit"},
                    {"name": "Biscuits"},
                    {"name": "Custard"}
                ],                
                "normal":[
                    {"name": "Strawberry Pancake", "ingredients": []},
                    {"name": "Mango Ice Cream", "ingredients": []},
                    {"name": "Vanilla Ice Cream", "ingredients": []},
                    {"name": "Lemon Pie", "ingredients": []},
                    {"name": "Chocolate Biscuit", "ingredients": []},
                    {"name": "Coffee Cake", "ingredients": []},
                    {"name": "Banana Cake", "ingredients": []},
                    {"name": "Pear Crumb", "ingredients": []},
                    {"name": "Bread and Butter Pudding", "ingredients": []},
                    {"name": "Sticky Toffee Pudding", "ingredients": []},
                    {"name": "Mince Pie", "ingredients": []}
                ]
            }
        },
        level_properties:{
          1:{'level':1,'n_menu_items':1,'menu_level':'simple'},
          2:{'level':2,'n_menu_items':2,'menu_level':'simple'},
          3:{'level':3,'n_menu_items':2,'menu_level':'normal'},
          4:{'level':4,'n_menu_items':3,'menu_level':'normal'},
          5:{'level':5,'n_menu_items':4,'menu_level':'normal'},
        },
        n_tasks:5,
        selected_dish:{'course':'','index':0},
        random_course:'',
        n_menu_items:3,
        menu_level:'simple',
        menu:{"starter":[],"main":[],"dessert":[]},
        instructions_text:'',
        finished_text:'You have finished one exercise. Click next to do more.',
        first_exercise:true,
        userCategory:'',
        sentence_time_gap:300,
        start_btn_text:'Start',
        region:'UK',
        current_exercise_index: 0,
        total_exercises: 0,
        base_level_address:'/Levels/Read_menu',
        level_address:'',
        no_hear_message_timeout:null,
        audioImg:audioImg,
        readAndHighlightTimeout: [],
        currentSentence: '',
        speech_rec:null,
        continue_read_and_highlight:false,
        target_words:null,
        is_recording:false,
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        current_setup:{},
        current_question_id:0,
        total_question_num:1,
        answer_type:'',
        total_q_a:[],
        if_recorder:true,
        btn_class: [],
        interaction_message_color:'',
        interaction_message:'',
        level_up_counter:0,
        level_up_target:4,
        curr_level_properties:null,
        task:1,
        random_index:0,
        start_btn_disabled:false,
        bv_tts: useTTS().tts_engine,
        full_sentence: 'I am well, thanks',
        full_text_array:[]
      }
    },
    created() {
  
      const _this = this
  
      this.userCategory = localStorage.getItem('userCategory');
      let region = localStorage.getItem('region');
        
      if(region!=undefined && region!=null)
      {
        _this.region = region
      }

      // this.menu['starter'] = this.menu_options['starter']['simple']
      // this.menu['main'] = this.menu_options['main']['simple']
      // this.menu['dessert'] = this.menu_options['dessert']['simple']



      _this.first_exercise = !_this.exForm.has_results

      logActivity( 'accessed', 'read_menu')
      
      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises

      // this.full_sentence = this.exForm.name
  
      // _this.full_text_array = this.full_sentence.split(' ')

      this.getUserLevel()


    },
    beforeUnmount()
    {
      this.clearAll()




    },
    mounted()
    {

    },
    computed: {
      currentSentence_found: function () {
        const _this = this

        console.debug('this.currentSentence = ' + this.currentSentence)
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.is_recording)
        {

            let curr_sentence = this.currentSentence.toString().toLowerCase()

            var regex = /[.,]/g;

            curr_sentence = curr_sentence.replace(regex, '');

            let words = curr_sentence.split(' ')

            console.debug('words = ' + words)

            let copy_target_words = [..._this.target_words]; //reactivity

            let found_something = false


            for(let i in words)
            {

                let minimum_distance = 100

                let word = words[i]

                console.debug('word = ' + word)

                for(let k in copy_target_words)
                {
                  console.debug('copy_target_words[k] = ' + copy_target_words[k])
                  
                  let distance = LevenshteinDistance(copy_target_words[k],word)

                  distance = distance/Math.max(copy_target_words[k].length,1)


                  if(distance<minimum_distance && distance<0.25)
                  {
                      // best_answer = this.target_words[i]
                      // this.stop_recording()
                      // found_something = true

                      const index = _this.target_words.indexOf(copy_target_words[k]);
                      if (true) { // only cut when it is the first word

                        // const display_index = this.full_text_array.indexOf(copy_target_words[k])


                        // this.btn_class[index] = 'correct-button'


                        found_something = true

                        if(_this.target_words.length>0)
                        {

                          _this.target_words.splice(index, 1); // 2nd parameter means remove one item only


                        }



                      }   
                  }
                }
            }

            if(found_something)
            {
              this.speech_rec.stop()

              this.is_recording = false

              setTimeout(() => {
                this.foundAllWords()
              }, 200);


            }
            return curr_sentence
        }
        return ''
      },
    },
    methods: {
        randomiseMenu()
        {

          this.n_menu_items = this.curr_level_properties.n_menu_items

          if(this.n_menu_items==1)
          {
            this.n_tasks = 4
          }
          this.menu_level = this.curr_level_properties.menu_level


            for(let course in this.menu_options)
            {
                this.menu[course] = []

                let previous_indexes = []

                while(this.menu[course].length<this.n_menu_items)
                {
                    let random_index = Math.floor(Math.random() * this.menu_options[course][this.menu_level].length)

                    if(!previous_indexes.includes(random_index))
                    {
                        previous_indexes.push(random_index)

                        this.menu[course].push(this.menu_options[course][this.menu_level][random_index])
                    }

                }
                

            }

            this.randomisePrices()
            this.$forceUpdate()
            this.setupTask()

        },
        randomisePrices()
        {

          let price_ranges = {'starter':[500,800],'main':[1100,1500],'dessert':[400,700]}



          for(let course in this.menu)
          {
            let prev_prices = []

            let min_price = price_ranges[course][0]
            let max_price = price_ranges[course][1]
            let delta_price = max_price-min_price


            for(let i in this.menu[course])
            {
              let not_found = true

              while(not_found)
              {
                let random_price = Math.floor(Math.random()*delta_price/10)
                random_price = min_price + random_price*10

                random_price=random_price/100

                random_price = (Math.round(random_price * 100) / 100).toFixed(2);


                if(!prev_prices.includes(random_price))
                {
                  not_found = false
                  prev_prices.push(random_price)
                  this.menu[course][i]['price'] = random_price
                }


                
              }

            }

          }

        },
        clearAll()
        {
          this.bv_tts.cancel()
          window.speechSynthesis.cancel()


          this.is_recording = false
          this.start_btn_disabled = false

          this.interaction_message = ''

          
          if (this.no_hear_message_timeout) {
              window.clearTimeout(this.no_hear_message_timeout); // will do nothing if no timeout with id is present
          }

          for(let n in this.readAndHighlightTimeout)
          {
            if (this.readAndHighlightTimeout[n]) {
              window.clearTimeout(this.readAndHighlightTimeout[n]); // will do nothing if no timeout with id is present
            }


          }


        },
        skipExercise()
        {

          this.clearAll()
          this.$emit('nextExercise');
        },
        foundAllWords()
        {
          //this.interaction_message = 'Well done, you did it!'
          // this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          startConfetti(this)

          this.if_recorder = false

          this.results['correct'] += 1
          this.results['total'] += 1

          this.updateLevel(true)



          setTimeout(() => {
              this.nextTask()
          }, 1000);
        },
        getUserLevel()
        {
          const _this = this


          
          let userType = localStorage.getItem('userCategory');


          let uid = localStorage.getItem('uid');

          this.level_address = '/Users/' + uid + this.base_level_address


          if(userType!=undefined || userType==='slp')
          {
              let client_number = localStorage.getItem('client_uid');

              if(client_number!='undefined' && client_number!=null && client_number!='')
              {

                  this.level_address = '/Users/'+ client_number + this.base_level_address

              }
          }


          let do_once = true


          getData(_this.level_address, function (err, result) {

            if(do_once)
            {
              do_once = false
              
              let current_level = result.child('current_level').val()
              let lvl_up_counter = result.child('level_up_counter').val()


              if(current_level==undefined || lvl_up_counter==undefined)
              {
                current_level = 1
                lvl_up_counter = 0
                updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
              }

              _this.level_up_counter = lvl_up_counter

              if(current_level-1<0 || current_level-1>=Object.keys(_this.level_properties).length)
              {
                current_level = 1
                
              }
              
              // if(current_level==1)
              // {
              //   current_level=2
              // }
              _this.current_level = current_level


              _this.curr_level_properties = _this.level_properties[_this.current_level]

            


              _this.randomiseMenu()
            }
          })



        },

        updateLevel(correct)
        {

          if(correct)
          {
            this.level_up_counter++
            console.debug('this.level_up_counter = ' + this.level_up_counter)
            if(this.level_up_counter >= this.level_up_target)
            {
                this.level_up_counter = 0
                this.current_level++

                if(this.current_level>Object.keys(this.level_properties).length)
                {
                  this.current_level = Object.keys(this.level_properties).length
                }
                else
                {
                  this.show_level_up_message = true

                }

            }
          }
          else
          {
            this.level_up_counter--

            if(this.level_up_counter<0)
            {
              this.level_up_counter = 0
            }
          }

          updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

        },


        saveResults()
        {
          const _this = this;

          if(_this.results['total']>0)
          {
            let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}
            
            let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
            let results = {all_results: all_results, plot_results:plot_results}
            
            var readMenuData = {resultsTablePre: {exercise_type: 'Read Menu', task: _this.task, results: results, audio_address:''}}
            
            
            save_pre_computed_results(_this.exForm,_this.$firebase,readMenuData)
            _this.$emit('logExerciseResults', readMenuData);

            _this.results['correct'] = 0
            _this.results['incorrect'] = 0
            _this.results['total'] = 0



          }
          

        },
        nextTask()
        {
          if(this.task==2 && this.results['total']==0)
          {
            this.results['total'] = 1
            this.results['incorrect'] = 1
            this.updateLevel(false)


          }
          this.task = this.task+1

          this.saveResults()

          this.clearAll()

          this.setupTask()

        },
        nextExercise()
        {
          const _this = this;
       
          
          this.clearAll()

          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
            const _this = this
           
            
            
            var goToResultsData = {curr_ex_done:false}
            
            
            _this.$emit('goToResults', goToResultsData);
                        
        },
      playPause()
      {

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))

        this.is_recording = true



        this.speech_rec.start(this.target_words,false,this.region)

        this.no_hear_message_timeout = setTimeout(() => {
          if(this.is_recording)
          {
            this.speech_rec.stop()
            this.is_recording = false

            let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
            nothing_heard_pop.show()

          }
        }, 30000);


      },
      runTTS()
      {

        if(this.task==3)
        {
          this.bv_tts.say(this.instructions_text)

        }
        else
        {
          this.start()
        }

      },
      start()
      {
        if(this.task===1)
        {

          this.runSelectMenuDish()

        }
        else if(this.task==2)
        {
          this.runReadDishAloud()
        }
        else if(this.task==3)
        {
          this.runChoralReading()

        }
        else if(this.task==4)
        {
          this.runFindDish()
        }
        else if(this.task==5)
        {
          this.runFindPriceDish()
        }
      },
      setupTask()
      {

        if (this.no_hear_message_timeout) {
            window.clearTimeout(this.no_hear_message_timeout); // will do nothing if no timeout with id is present
        }

        this.bv_tts.cancel()

        this.start_btn_text = 'Start'

        this.interaction_message = ''

        if(this.task==1)
        {
          this.setupSelectMenuItem()
        }
        else if(this.task==2)
        {
          this.setupReadDishAloud()

        }
        else if(this.task==3)
        {
          this.setupChoralReading()

        }
        else if(this.task==4)
        {
          this.setupFindDish()
        }
        else if(this.task==5 && this.n_menu_items>1)
        {

          this.setupFindPriceDish()
        }
        else
        {

          this.finishedAllTasks()
        }

      },
      finishedAllTasks()
      {

        if(this.show_level_up_message)
        {
          this.finished_text = 'You have been doing really well! We will increase the difficulty level a little for you. Press next.'


        }
               
          
        this.bv_tts.say(this.finished_text)

        let finished_pop = new Modal(document.getElementById('finished_pop'));
        finished_pop.show()

      },
      setupSelectMenuItem()
      {
        // task 1

        let courses = Object.keys(this.menu)

        this.random_course = Math.floor(Math.random()*courses.length)

        this.random_course = courses[this.random_course]

        if(this.n_menu_items==1)
        {
          this.instructions_text = 'Click on the ' + this.random_course.toUpperCase() + ' dish'

        }
        else
        {
          this.instructions_text = 'Click on the ' + this.random_course.toUpperCase() + ' dish you want to eat today'

        }



        this.bv_tts.say(this.instructions_text)


        this.start_btn_text = 'Repeat'


      },
      runSelectMenuDish()
      {
        // task 1
        this.bv_tts.say(this.instructions_text)

      },
      selectMenuItem(course,index)
      {
        if(this.task==1)
        {
          if(course!=this.random_course)
          {
            let tts_1 = 'You picked a ' + course + ' dish.'
            let tts_2 = 'You need to pick a ' + this.random_course + ' dish'

            this.interaction_message = tts_1+tts_2
            this.interaction_message_color = 'color:red;white-space: pre-wrap'

            this.bv_tts.say_sentences([tts_1,tts_2], this.sentence_time_gap)
          }
          else
          {
            this.selected_dish.course = course
            this.selected_dish.index = index
            this.nextTask()
          }

        }
        else if(this.task==4 || this.task==5)
        {
          let curr_dish = this.menu[course][index]['name']

          console.debug('this.target_click_word = ' + this.target_click_word)
          console.debug('curr_dish = ' + curr_dish)



          if(curr_dish===this.target_click_word)
          {
            if(this.first_attempt)
            {
              this.results.correct = this.results.correct + 1
              this.results.total = this.results.total + 1

              this.updateLevel(true)

            }

            this.first_attempt = true
            this.interaction_message = 'Well done! You got it right'
            this.interaction_message_color = 'color:green;white-space: pre-wrap'
            startConfetti(this)
            this.$forceUpdate()

            this.nextTask()

          }
          else
          {
            if(this.first_attempt)
            {
              this.results.incorrect = this.results.incorrect + 1
              this.results.total = this.results.total + 1

              this.updateLevel(false)


            }

            this.first_attempt = false
            this.interaction_message = 'Oops, a tricky one. Try again!'
            this.bv_tts.say(this.interaction_message)
            this.interaction_message_color = 'color:red;white-space: pre-wrap'
            this.$forceUpdate()
            // setTimeout(() => {
            //     this.interaction_message = ''
            //     this.$forceUpdate()
            // }, 5000);

          }

        }
        

      },
      runListenAndRead()
      {
        this.bv_tts.say(this.full_sentence)
        this.start_btn_text = 'Repeat'

      },
      setupChoralReading()
      {

        // Task 3

        let dish = this.menu[this.selected_dish.course][this.selected_dish.index].name

        let order_sentences = [
        "I would like to order the " + dish.toLowerCase(),
        dish + " for my " + this.random_course,
        "I will be having the " + dish.toLowerCase() + ", please",
        "Could I have the " + dish.toLowerCase() + ", please?"
        ]
        let order_sentence = order_sentences[Math.floor(Math.random()*order_sentences.length)]

        this.full_text_array = order_sentence.split(' ')

        this.instructions_text = "Click Start. Let's read it together."
        this.bv_tts.say(this.instructions_text)

      },
      removeHighlightedWords()
      {
        for(let n in this.full_text_array)
        {
            this.btn_class[n] = ''

        }
        this.$forceUpdate()

      },
      runChoralReading()
      {
        // Task 3
        const _this = this

        this.start_btn_disabled = true

        this.bv_tts.cancel()

        this.removeHighlightedWords()

        
   

        _this.continue_read_and_highlight = true

        // _this.bv_tts.say(this.full_sentence)
        _this.readAndHighlight(0)
        // setTimeout(function () {_this.readAndHighlight(0)}, 4000);
        this.start_btn_text = 'Repeat'

      },   
      readAndHighlight(n)
      {
          const _this = this

          _this.readAndHighlightTimeout = []


          if(n<this.full_text_array.length)
          {

              let curr_word = this.full_text_array[n]

              
              _this.btn_class[n] = 'correct-button'
              this.$forceUpdate()

              _this.bv_tts.say(curr_word)



              _this.readAndHighlightTimeout.push(setTimeout(function() { 

                if(_this.continue_read_and_highlight)
                {
                  _this.readAndHighlight(n+1)

                }

              },1300) )  
          }
          else
          {
            this.start_btn_disabled = false
          }

      },

      setupReadDishAloud()
      {
        // task 2

        let target_dish = this.menu[this.selected_dish.course][this.selected_dish.index].name


        this.$forceUpdate()

        this.instructions_text = 'Say aloud the dish highlighted on the menu.'

        this.bv_tts.say(this.instructions_text)

        this.target_words = []

        let temp_target_dish = removePunctuationFromString(target_dish)

        this.target_words = temp_target_dish.split(' ')

        this.playPause()

      },
      runReadDishAloud()
      {
        // task 2

        this.instructions_text = 'Say aloud the dish you want to eat.'

        this.bv_tts.say(this.instructions_text + 'It is highlighted on the menu')

        //this.playPause()
      },

      setupFindDish()
      {
        // task 4

        let courses = []
        
        for(let i in this.menu)
        {
          if(i!=this.random_course)
          {
            courses.push(i)
          }
        }

        this.selected_dish = {'course':'','index':0}

        this.random_course = Math.floor(Math.random()*courses.length)

        this.random_course = courses[this.random_course]



        this.random_index = Math.floor(Math.random() * this.menu[this.random_course].length);

        this.target_click_word = this.menu[this.random_course][this.random_index].name

        this.instructions_text = 'Click on the following dish.'

        let first_text = 'Click on the following dish.'

        // let end_text = 'Press Repeat to hear it again.'

        let the_word_is = ' The dish is; ' + this.target_click_word


        this.bv_tts.say(first_text + the_word_is)
        this.start_btn_text = 'Repeat'



      },
      runFindDish()
      {
        // task 4


        let first_text = 'Click on the following dish.'


        let the_word_is = ' The dish is; ' + this.target_click_word


        this.bv_tts.say(first_text + the_word_is)

      },
      setupFindPriceDish()
      {

        //Task 5


        let courses = []
        
        for(let i in this.menu)
        {
          if(i!=this.random_course)
          {
            courses.push(i)
          }
        }

        this.selected_dish = {'course':'','index':0}

        this.random_course = Math.floor(Math.random()*courses.length)

        this.random_course = courses[this.random_course]

        let target_options = ['expensive','cheap']

        let random_option = Math.floor(Math.random()*target_options.length)

        random_option = target_options[random_option]

        let selected_price = 0
        let multiplier = 1

        if(random_option==='cheap')
        {
          selected_price = 100
          multiplier = -1
        }

        let target_dish_index = 0
        this.target_click_word = 'nothing'
        


        for(let i in this.menu[this.random_course])
        {
          if((this.menu[this.random_course][i].price*multiplier)>(selected_price*multiplier))
          {
            target_dish_index = i
            this.target_click_word = this.menu[this.random_course][i].name
            selected_price = this.menu[this.random_course][i].price
          }
        }

        console.debug('this.target_click_word = ' + this.target_click_word)

        let first_text = 'Click on the most expensive ' + this.random_course.toUpperCase() + ' dish'

        if(random_option==='cheap')
        {
          first_text = 'Click on the cheapest ' + this.random_course.toUpperCase() + ' dish'


        }

        this.instructions_text = first_text
        this.bv_tts.say(first_text)

      },
      runFindPriceDish()
      {
        this.bv_tts.say(this.instructions_text)

      },
    }    
  }
  </script>
  <style scoped>
  
  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }

  .basic-button
  {
    color: black;
    background-color: lightgrey;
    border-color: black;
  }

  .basic-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button
  {
    color: blueviolet;
    background-color: lightgray;
    border-color: black;
    width: 45mm;
  }
  .incorrect-wide-button
  {
    color: white;
    background-color: red;
    border-color: red;
    width: 45mm;
  }

  .correct-button
  {
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    color:white;
    background-color: red;
    border-color: red;
  }
  
  </style>
