<template>
  <div>
    <Instructions v-if="!showGame" @showGame="(v) => showGame = v" />
    <Module v-if="showGame" 
          :exForm="exForm"  
          @logExerciseResults="logExerciseResults($event)"
          @goToResults="goToResults($event)"
          @nextExercise="nextExercise()" />
  </div>
</template>

<script>

import Instructions from './components/instructions.vue'
import Module from './components/module.vue'
import {logActivity} from '@/media_input/utils'

export default {
  name: 'Number_sequence',
  components: {Instructions, Module},
  /*

  {
   "Name":"Number sequence easy",
   "difficulty":"easy",
   "exercise_type":"number_sequence",
   "plan_name":"Number sequence",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Number sequence/Exercises/General/Number Sequence/exercises/easy/",
   "ex_path":"Number sequence/Exercises/General/Number Sequence/exercises/easy/",
   "today":"2023-07-11 17:27",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"number_sequence",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "module_name":"Number sequence",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
  }

  */
  props: 
  {
    exForm: {
      type: Object,
    },
  },
  emits: [
    'logExerciseResults',
    'goToResults',
    'nextExercise',
  ],
  data(){
    return {
      userCategory: "",
      showGame: false
    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    
    logActivity( 'accessed', 'number_sequence')



    if (this.exForm.current_exercise_index != 0) {
      this.showGame = true
    }
  },
  methods: {
    logExerciseResults(number_sequence_data) {
      this.$emit('logExerciseResults', number_sequence_data);
    },
    nextExercise() {
      this.$emit('nextExercise');
    },
    goToResults(goToResultsData) {
      this.$emit('goToResults', goToResultsData);
    }

  },
}
</script>

<!-- Results Description
{
  "Number Sequence": {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "exercise": "Number Sequence",
        "exercise_type": "Life Skills",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Difficulty": "<Lv#>",
            "Incorrect": "<int>",
            "Total": "<int>"
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Number Sequence"
  }
} -->