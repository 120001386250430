<template>      
  <div>
    <v-tour name="slt_dashboard_tour" :steps="steps"></v-tour>

    <div class="modal fade" tabindex="-1" id="welcome_slt_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Welcome to Beautiful Voice!</h5>
          </div>
          <div class="modal-body">
            <p>The platform is designed to help you save time with daily tasks including exercise creation, outcome measures, session reporting and patient monitoring.</p>

            <p>Please note that our quantitative analysis is for decision support only; it does not substitute speech and language therapy or assessments.</p>
            <p>Click on <strong>Start platform tour</strong> to learn the platform functionalities. You can revisit the tour any time by going to <strong>Settings</strong>.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="$tours['slt_dashboard_tour'].start()" data-bs-dismiss="modal" >Start platform tour</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-md-9 text-start">
        <h1 class="display-1">Hi {{ displayName }}!</h1>
        <h1 class="display-6 pb-4">Let's get started...</h1>
      </div>
      <div class="col-md-3">
        <div class="card card-body">
          <div class="text-center">
            <img :src="demoImg" class="img-responsive" style="width:100px; cursor:pointer" @click="clientDemo()">
            <br/>
            <span class="custom-text" style="cursor:pointer" @click="clientDemo()">Patient's View</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 v-dashboard-0">
      <div v-show="loaded_patient_list">
        <Patient_List @loaded_patient_list="(value) => loaded_patient_list=value"/>
      </div>
      <div v-show="!loaded_patient_list" class="mt-5">

        <div class="text-start">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p>Loading Patients</p>
        </div>
        <h5 class="placeholder-glow mt-4">
          <span class="placeholder col-6"></span>
        </h5>
        <p class="placeholder-glow">
          <span class="placeholder col-7"></span>
        </p>
        <p class="placeholder-glow">
          <span class="placeholder col-6"></span>
        </p>
        <p class="placeholder-glow">
          <span class="placeholder col-5"></span>
        </p>
        <p class="placeholder-glow">
          <span class="placeholder col-4"></span>
        </p>
        
      </div>

    </div>

  </div>
</template>
<script>


import Patient_List from './components/patient_list.vue'
import {updateDataDirectory, getUserDataDir} from '@/firebase/index'
import demoImg from '@/assets/images/demo.png'
import { Modal } from 'bootstrap'

export default {
  components: { Patient_List },
  name: 'slt_dashboard',
  props:  {
  
  },
  data(){
    return {
      demoImg:demoImg,
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      firstname: '',
      surname: '',
      displayName: '',

      loaded_patient_list: false,

      steps: [
        {
          target: '.v-dashboard-0',
          content: "<div class='text-start'>Here, you can find all your added patients. Demo Patient is a demonstration account for you to try out the platform functionalities.</div>",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-dashboard-1',
          content: "<div class='text-start'>You can add a new patient by clicking the button Add patient.</div>",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-dashboard-2',
          content: "<div class='text-start'>Successfully connected patients will have a phone symbol here. This will allow you to share exercises with the patient and view their results. You can make video calls with your patients directly through the platform.</div>",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-dashboard-3',
          content: "<div class='text-start'>You’ll be able to access their health page which contains all the exercises and results related to them. Click on the Demo Patient to continue the tour. </div>",
          params: {
            highlight: true
          }
        },
      ],
    }
  },
  created() {
    let _this = this
    
    sessionStorage.setItem('view_mode', '')
    localStorage.setItem('client_uid', '')

    let enteredOnce = true;

    getUserDataDir('Data/' , function (err, result) {
      if (enteredOnce) {
        enteredOnce = false;

        let startTour = result.child('startTour').val();

        if (startTour === null || startTour['dashboard'] === undefined || startTour['dashboard'] === false) {
          //_this.$tours['slt_dashboard_tour'].start()

          if (typeof startTour !== "object" || startTour === null) {
            startTour = {}
          }
          
          startTour['dashboard'] = true
          let uid = localStorage.getItem('uid')

          let ref_path = 'Users/' + uid + '/Data/'

          updateDataDirectory(ref_path, {'startTour': startTour})
        }

        _this.firstname = result.child('Firstname').val();
        _this.surname = result.child('Surname').val();

        if(result.hasChild('Email'))
        {
          let email = result.child('Email').val();

          localStorage.setItem('slt_email', email)

        }

        if(result.hasChild('userName'))
        {
          let name = result.child('userName').val();
          
          if(name !== '' || name != undefined || name != null) {
            _this.displayName = " " + name
          } else {
            _this.displayName = "";
          }
        } else if (_this.displayName === "" && result.child('firstname').val() != null)
        {
          _this.displayName = " " + result.child('firstname').val();
        } 
        else if (_this.displayName === "" && result.child('Firstname').val() != null)
        {
          _this.displayName = " " + result.child('Firstname').val();
        }else {
          _this.displayName = ""
        }

        localStorage.setItem('slt_name', _this.displayName)

      }
    });

  },
  mounted() {
    
    let enteredOnce = true;

    getUserDataDir('Data/' , function (err, result) {
      if (enteredOnce) {
        enteredOnce = false;

        let startTour = result.child('startTour').val();

        if (startTour === null || startTour['dashboard'] === undefined || startTour['dashboard'] === false) {

          let myModal = new Modal(document.getElementById('welcome_slt_modal'));
          myModal.show()

          //this.$tours['slt_dashboard_tour'].start()

          if (typeof startTour !== "object" || startTour === null) {
            startTour = {}
          }
          
          startTour['dashboard'] = true
          let uid = localStorage.getItem('uid')
          let ref_path = 'Users/' + uid + '/Data/'

          updateDataDirectory(ref_path, {'startTour': startTour})
        }
      }
    })


  },
  methods: {
    clientDemo() {
      localStorage.setItem('client_uid', '');

      this.resetAllExerciseLevels()

      this.$router.push({ name: 'client_demo' });
    },
    resetAllExerciseLevels() {
      let levels_folder = 'Levels'

      let uid = localStorage.getItem('uid')

      let ref_path = 'Users/' + uid + '/';
      updateDataDirectory(ref_path, {'Levels':{'temp':0}})

    }
  }   

}
</script>
<style scoped>
.custom-text{
    font-size: 20px;
    font-family: Arial;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}
</style>