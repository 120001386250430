<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  currentIndex="settings"></Navbar>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="display-5 ms-3">Settings</h1>
          <div class="row p-2">
            <Patient_setting 
              v-if="userCategory === 'patient'" 
              :tier_name="tier_name" 
              :next_payment_due="next_payment_due">
            </Patient_setting>
            <Slt_Setting 
              v-if="userCategory === 'slp'" 
              :tier_name="tier_name" 
              :next_payment_due="next_payment_due">
            </Slt_Setting>
          </div>
        </div>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue'
import Patient_setting from './patient/setting.vue'
import Slt_Setting from './slt/setting.vue'
import {getUserDataDir} from '@/firebase/index'
import Footer from '../Footer.vue'

export default {
    components: { Navbar, Footer, Patient_setting, Slt_Setting },
    name: 'Settings',
    data(){
      return {
        userCategory: localStorage.getItem('userCategory'),

        issue_lib: ['Slurred/choppy speech','Difficulty in moving lips, jaw or tongue','Hoarse/breathy voice','Dysphasia/aphasia'],
        issue_flag: [false,false,false,false],

        tier_name: "",
        next_payment_due: "",
        email: ""
      }
    },
    created() {
      const _this = this

      if(_this.userCategory != 'slp') {
        _this.readCondition()
      }

      getUserDataDir('Subscription/', function (err, result) {
        let sub = result.val()

        sessionStorage.setItem("expiry", sub['expiry'])
        sessionStorage.setItem("tier", sub['id'])

        if (sub['id'] && sub['id'].includes('Free')) {
          if (sub['trial']) {

            const jsonString = JSON.stringify(sub['trial']);

            sessionStorage.setItem('trial', jsonString);
          }
        }

        if (sub.hasOwnProperty('license_status')) {
          for (const [key, value] of Object.entries(sub['license_status'])) {
            if (value === 'assigned') {
              sessionStorage.setItem("license_status", 'assigned')
            } else if (value === 'assigned_premium') {
              sessionStorage.setItem("license_status", 'assigned_premium')
            } else {

            }
          }
        } else {
          sessionStorage.setItem("license_status", null)
        }
        
        let tier = sessionStorage.getItem("tier")

        let timestamp = Number(sessionStorage.getItem("expiry")*1000)
        
        _this.next_payment_due = new Date(timestamp).toDateString()
        
        if (_this.userCategory == 'patient') {
          if (tier === "T1") {
            _this.tier_name = "Homework only plan"
          } else if (tier === "T2") {
            _this.tier_name = "Super plan"
          } else if (tier === "T3") {
            _this.tier_name = "Premium plan"
          } else if (tier === "F1" || tier === 'Free_1') {
            _this.tier_name = "Free plan"
          } else if (tier === "Trial1") {
            _this.tier_name = "Trial plan"
          } 
          else {
            _this.tier_name = "Invalid"
          }
        } else if (_this.userCategory == 'slp') {
          if (tier === "T1") {
            _this.tier_name = "Individual plan 5 Licences"
          } else if (tier === "T2") {
            _this.tier_name = "Individual plan 10 Licences"
          } else if (tier === "T3") {
            _this.tier_name = "Premium plan"
          } else if (tier === "F1" || tier === 'Free_1') {
            _this.tier_name = "Free plan"
          } else if (tier === "Trial1") {
            _this.tier_name = "Trial plan"
          } 
          else {
            _this.tier_name = "Invalid"
          }
        }
      });
    },
    methods: {
      readCondition()
      {
        let _this  = this;

        let user_page_dir = 'Data/';
        let do_once = true;

        getUserDataDir(user_page_dir , function (err, result) {

          if (do_once) {
            do_once = false;

            console.debug('get distype')

            if(result.hasChild('Qs_diseaseType')) {

              let result_condition = result.child('Qs_diseaseType')

              let num_issue = result_condition.numChildren();

              if(num_issue==undefined || num_issue==null || num_issue<1) {
                for(let i=0;i<4;++i) {
                  _this.issue_flag[i] = true;
                }
                console.debug('_this.issue_flag = ' + _this.issue_flag)
              }
              else {
                for(let i=0;i<num_issue;i++) {
                  let issue = result_condition.child(i).val();
                  let issue_id = _this.issue_lib.indexOf(issue);
                  _this.issue_flag[issue_id] = true;
                }
              }
            }
            else {
              for(let i=0;i<4;++i) {
                _this.issue_flag[i] = true;
              }
            }
            localStorage.setItem('issue_flag',_this.issue_flag);
            _this.email = result.child('Email').val();
            
            let interests_array = []
            if(result.hasChild('interests')) {
              let temp_interests_array = result.child('interests').val()

              if(temp_interests_array!=undefined && temp_interests_array!=null) {
                interests_array = temp_interests_array
              }
            }
            localStorage.setItem('interests_array', JSON.stringify(interests_array)) 
          }
        });  
      }
  }
}
</script>
  

<style scoped>

.custom-text
{
  font-size:20px;
  color: white;
}

</style>
  
  