import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router';
import { firebaseInstance } from './firebase'
import { handleGlobalError } from './telemetry/errorHandler.js';
import tracekitPlugin from './telemetry/tracekit.js';

///////////////////////////////////////////////
///these need to be checked
import VueAnimXYZ from '@animxyz/vue3'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { plugin, defaultConfig } from '@formkit/vue'

import VueConfetti from 'vue-confetti';
import "bootstrap"
import Vue3Tour from 'vue3-tour'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import veProgress from 'vue-ellipse-progress'
import FloatingVue from 'floating-vue'
import Toast from "vue-toastification";


//////////////////////////////////////////////
//CSS Goes here
import '@animxyz/core'
import 'animate.css';
import "hover.css";
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import 'floating-vue/dist/style.css'
import 'vue3-tour/dist/vue3-tour.css'

import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap/dist/css/bootstrap.min.css"

import './scss/styles.scss'

import "vue-toastification/dist/index.css";
import ConfettiExplosion from "vue-confetti-explosion";
/////////////////////////////////////////////

const app = createApp(App)
const pinia = createPinia()


/////////////////////////////////////////////////////////////
app.config.globalProperties.$firebase = firebaseInstance
/////////////////////////////////////////////////////////////

router.beforeEach(async (to, from, next) => {
  try {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !(await firebaseInstance.getCurrentUser())) {

      if (to.fullPath.toLowerCase().includes('subscribe')) {
        localStorage.setItem('redirectAfterLogin', to.fullPath);
      }
      next('signIn')
    } else {
      const backdrop = document.querySelector('.modal-backdrop')
      if (backdrop) {
        backdrop.remove();
      }

      next()
    }

  } catch (error) {
    console.error('Error during router beforeEach:', error);
    next();
  }
});

// Vue's global error handler
app.config.errorHandler = (err, instance, info) => {
  handleGlobalError(err, instance, info)
}

// Router error handler
router.onError((err) => {
  handleGlobalError(err, null, 'router')
})

// Global JS error handler
window.onerror = (message, source, lineno, colno, error) => {
  const errorInfo = error || new Error(message);
  const sourceInfo = `script:${source}:${lineno}:${colno}`;
  handleGlobalError(errorInfo, null, sourceInfo)
}

// Global unhandled promise rejection handler
window.onunhandledrejection = (event) => {
  handleGlobalError(event.reason, null, 'unhandledrejection')
}


app.use(tracekitPlugin);

app.use(pinia)
app.use(router)
app.use(VueAxios, axios)

app.use(plugin, defaultConfig)

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(VueAnimXYZ)
app.use(ConfettiExplosion)
app.use(VueConfetti)
app.use(Vue3Tour)
app.use(veProgress)
app.use(FloatingVue)


const toast_options = {
  position: "top-right",
  timeout: 6000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

app.use(Toast, toast_options);

app.mount('#app')


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

console.debug(import.meta.env.VITE_PRODUCTION == "false" ?'Development mode ' + import.meta.env.VITE_VERSION : 'BeautifulVoice Platform ' + import.meta.env.VITE_VERSION)

/*if (import.meta.env.VITE_PRODUCTION === "true") {
  console.log = function() {};
  console.debug = function() {};
  console.error = function() {};
}*/