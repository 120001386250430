<template>
  
  <div class="row mt-2">

      <div>
        <span class="custom-text">Transcription results</span>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <td>Topic</td>
            <!-- <th scope="col">Loudness (dB)</th>

            <th scope="col">Accuracy</th>
            <th scope="col">Fluency</th> -->

            <th scope="col">Transcription</th>
            <!-- <th scope="col"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in transcription" v-bind:key="index">
            <td>{{ row.Topic }}</td>
            <!-- <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.Accuracy }}</td>
            <td>{{ row.Fluency }}</td> -->

            <td>
              <p class="lead text-wrap">
                <strong
                  v-for="(word, id) in row.sentence_intelligibility_info" 
                  :key="id" 
                  >
                  {{row.Words[id]}}&nbsp;
                </strong>

                <!-- <strong
                v-for="(word, id) in row.WordGrade" 
                :key="id" 
                :class="[{'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" 
                style="cursor: pointer;" @click="listenPhoneme(row.Words[id])"
                >
                {{row.Words[id]}}&nbsp;
                </strong>  -->
                <!-- <td><b v-for="(pho_word, id) in row.phoneme_word_intelligibility_info" :key="id" :style="'color:'+row.phoneme_word_intelligibility_info[id]['colour'].toLowerCase()" class="custom-text20"> 
                  {{row.phoWord_display[id]}}</b>
                </td> --> 

              </p>
            </td>
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
          </tr>
        
        </tbody>
      </table>

  </div>

</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import constants from '@/utils/constants'
import firebase from 'firebase/app'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

export default {
  name: 'transcription',
  components: {
    Audio_Playback
  },
  props: {
    transcription: {
      type: Object,
      default: null
    },
    // avg_sentence_intel: {
    //   type: String,
    //   default: null
    // },
    // avg_sentence_interp: {
    //   type: String,
    //   default: null
    // },
  },
  setup(props) {
    const userCategory = ref(localStorage.getItem('userCategory'))
    
    const sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict

    onMounted( () => {

    });

    const audio_player = ref(null)

    const playAudio = (audio_file_location) => {

      let storage = firebase.storage()
      storage.ref(audio_file_location).getDownloadURL().then((url) => {
        audio_player.value = new Audio(url);
        audio_player.value.play();
        
        audio_player.value.addEventListener('ended', () => {
          audio_player.value = null
        });

      }).catch((error) => {

      })
    }

    const playRecording = (address) => {
      playAudio(address)
    }

    const select_word_sentence_intelligibility = (row,index,id) =>
    {
      console.debug('select_word_sentence_intelligibility')

      console.debug(row)

      this.a = row

      let offset = row['Offset'][id]
      console.debug('offset = ' + offset)
      if(offset!=undefined || offset!=null)
      {
        if(offset<0)
        {
          offset = 0
          
        }
        offset = offset/10000000
        console.debug('offset 2 = ' + offset)
        playRecording(row,'sentencegibility',index,offset)
      }
    }
    
    return {
      userCategory,
      sentence_intelligibility_grading,
      select_word_sentence_intelligibility,
    };

  }
}
</script>