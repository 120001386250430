<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <div class="card card-body">
            <h2 v-if="total_q_a.length>current_question_id">
              {{ total_q_a[current_question_id].qs }}
              <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(total_q_a[current_question_id].qs)">
            </h2>
            <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->

            <!-- level 3 -->
            <div v-if="current_level == 3" class="d-flex justify-content-center mt-4" style="font-size: x-large;">
              <button v-for="i in passive_sentence_display.length" :key="i" :class="btn_class[i-1]" style="margin-left: 2mm;font-size: xx-large;" @click="removeBtns(i-1,passive_sentence_display,passive_sentence_rand)">
                {{ passive_sentence_display[i-1] }}
              </button>
            </div>

        
            <!-- level 4 -->
            <div class="card card-body" v-if="current_level == 4"> 

              
            <div v-if="current_level == 4" class="d-flex justify-content-center mt-4" style="font-size: x-large;">
              <button v-for="i in full_passive_array.length" :key="i"  class="basic-btn" style="margin-left: 2mm;font-size: xx-large;">
                {{ full_passive_array[i-1] }}
              </button>
            </div>
              <div class="row justify-content-center" style="font-size: xx-large;margin-top: 5mm;margin-bottom: 5mm;">
                <i class="bi bi-arrow-down-square-fill"></i>
              </div>
              <div class="d-flex justify-content-center" style="font-size: x-large;">
                <button v-for="i in active_sentence_display.length" :key="i" :class="btn_class[i-1]" style="margin-left: 2mm;font-size: xx-large;" @click="removeBtns(i-1,active_sentence_display,active_sentence_rand)">
                  {{ active_sentence_display[i-1] }} 
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="height: 15mm;">
          <p class="h5" :style="interaction_message_color"> {{  interaction_message}}</p> 
        </div>
        <div v-if="current_level<3" class="d-flex justify-content-center">
          <button v-for="i in full_passive_array.length" :key="i" :class="btn_class[i-1]" style="margin-left: 2mm;font-size: xx-large;" @click="checkResult(i-1)">
            {{ full_passive_array[i-1] }}
          </button>
          <img :src="audioImg" style="width:50px;;cursor: pointer;margin-left: 2mm;" @click="bv_tts.say(full_passive_array)">
        </div>
        <div v-if="current_level === 3 && passive_sentence_rand.length>0"> 
          
          <div class="d-flex justify-content-center" style="font-size: x-large;">
            <button v-for="i in passive_sentence_rand.length" :key="i" v-show="passive_sentence_rand[i-1]!=undefined && passive_sentence_rand[i-1].ifShow" class="btn btn-outline-primary" style="margin-left: 2mm;font-size: xx-large;" @click="moveBtns(i-1,passive_sentence_rand,passive_sentence_display)">
              {{ passive_sentence_rand[i-1].value }} 
            </button>
          </div>
        </div>
        <div v-if="current_level === 4 && active_sentence_rand.length>0 ">             
          <div class="d-flex justify-content-center" style="font-size: x-large;">
            <button v-for="i in active_sentence_rand.length" :key="i" v-show="active_sentence_rand[i-1]!=undefined && active_sentence_rand[i-1].ifShow" class="btn btn-outline-primary" style="margin-left: 2mm;font-size: xx-large;" @click="moveBtns(i-1,active_sentence_rand,active_sentence_display)">
              {{ active_sentence_rand[i-1].value }} 
            </button>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          
          :has_skip="true"
          @skip="skipExercise()"

          :has_show_results="!first_exercise"
          @show_results="goToResults()"
          >
          <template v-slot:top>
            <button v-show="if_recorder && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="playPause()"><strong>Record <i class="bi bi-play-circle" style="margin-left: 5mm;"></i></strong></button>

            <button v-show="is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>

            <!-- skip was here -->
            
          </template>
        </Sidebar>
      </template>
    </Layout>
    <div class="modal fade" id="nothing_heard_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sorry, we couldn't hear you well</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body m-3">
            <div class="row">
              <div class="row">
                <h4 >Click on Record to try again. Or Skip level to skip exercises involving speaking</h4>

              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Back to the exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import { Modal } from 'bootstrap'

import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
import audioImg from '@/assets/images/audio_logo.png'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import SpeechRec from '@/media_input/SpeechRecognition'
import { useTTS } from '@/stores/tts'

  // SLT\Exercises\Receptive Language\reversible passives.docx
  export default {
    name: 'reversible_passive',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data(){
      return {
        first_exercise:true,
        userCategory:'',
        copy_full_passive_array:[],
        region:'UK',
        current_exercise_index: 0,
        total_exercises: 0,
        audioImg:audioImg,
        currentSentence: '',
        nothing_heard_timeout:null,
        speech_rec:null,
        target_words:null,
        is_recording:false,
        diff_level:'easy', //easy, medium, hard for image/word level
        used_rand_id:[], //needs to be remembered/passed from an outer layer
        target_pair:{},
        active_passive:'passive',
        base_level_address:'/Levels/Reversible_passive',
        level_address:'',
        preposition:'by',
        word_arr_acted_on:[],
        word_arr_acting:[],
        verb_arr:[],
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        full_passive_array:[],
        full_sentence_string:'',
        full_active_array:[],
        active_sentence_array:[],
        active_sentence_display:[],
        active_sentence_rand:[],
        passive_sentence_array:[],
        passive_sentence_display:[],
        passive_sentence_rand:[],
        current_level:1,
        current_setup:{},
        current_question_id:0,
        total_question_num:1,
        answer_type:'',
        total_q_a:[],
        if_recorder:false,
        level_2_question_number:0,
        btn_class: [],
        interaction_message_color:'',
        interaction_message:'',
        level_up_counter:0,
        level_up_target:3,
        btn_move_counter:0,
        bv_tts: useTTS().tts_engine,
      }
    },
    created() {
  
      const _this = this
  
      this.userCategory = localStorage.getItem('userCategory');
      this.region = localStorage.getItem('region');
      // this.loadImages();
  

      _this.first_exercise = !_this.exForm.has_results

      logActivity( 'accessed', 'reversible_passive')
      
      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises

      this.loadDifficulty();
    },
    beforeUnmount()
    {

      this.clearAll()



    },
    mounted()
    {

    },
    computed: {
      currentSentence_found: function () {
        const _this = this

        console.debug('this.currentSentence = ' + this.currentSentence)
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.is_recording)
        {

            let curr_sentence = this.currentSentence.toString().toLowerCase()

            var regex = /[.,]/g;

            curr_sentence = curr_sentence.replace(regex, '');

            let words = curr_sentence.split(' ')

            console.debug('words = ' + words)

            let copy_target_words = [..._this.target_words]; //reactivity

            let found_something = false


            for(let i in words)
            {

                let minimum_distance = 100

                let word = words[i]

                console.debug('word = ' + word)

                for(let k in copy_target_words)
                {
                  
                  let distance = LevenshteinDistance(copy_target_words[k],word)

                  distance = distance/Math.max(copy_target_words[k].length,1)


                  if(distance<minimum_distance && distance<0.25)
                  {
                      // best_answer = this.target_words[i]
                      // this.stop_recording()
                      // found_something = true

                      const index = _this.target_words.indexOf(copy_target_words[k]);
                      if (index == 0) { // only cut when it is the first word

                        const display_index = this.copy_full_passive_array.indexOf(copy_target_words[k])


                        this.btn_class[display_index] = 'correct-button'


                        found_something = true


                        _this.target_words.splice(index, 1); // 2nd parameter means remove one item only

                      }   
                  }
                }
                console.debug('_this.target_words = ' + _this.target_words)
            }

            if(_this.target_words.length==0)
            {
              this.speech_rec.stop()

              this.is_recording = false

              _this.results['total'] = 1
              _this.results['correct'] = 1


              this.level_2_question_number = this.level_2_question_number + 1
              this.result_response()

              // this.foundAllWords()
            }
            return curr_sentence
        }
        return ''
      },
    },
    methods: {

        clearAll()
        {
          this.bv_tts.cancel()
          if(this.nothing_heard_timeout)
          {
            window.clearTimeout(this.nothing_heard_timeout); // will do nothing if no timeout with id is present

          }

        },
        skipExercise()
        {
          updateDataDirectory(this.level_address,{current_level:this.current_level+1,level_up_counter:0,used_ex_id:[-1]})

          this.$emit('nextExercise');
        },
        foundAllWords()
        {
          this.interaction_message = 'Well done, you did it!'
          this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          startConfetti(this)

          this.if_recorder = false

          this.results['correct'] = 1
          this.results['total'] = 1


          setTimeout(() => {
              this.nextExercise()
          }, 1200);
        },
        
        nextExercise()
        {
          const _this = this;

          this.saveResults()
          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
          const _this = this


          this.saveResults()
          
            
            
          var goToResultsData = {curr_ex_done:false}
          
          
          _this.$emit('goToResults', goToResultsData);
          
            
        },
        saveResults()
        {
            const _this = this


            if(_this.results['total']>0)
            {
              let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}
            
              let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
              let results = {all_results:all_results, plot_results:plot_results}
              
              var reversiblePassiveData = {resultsTablePre: {exercise_type: 'Reversible Passive', exercise: _this.current_level, results: results, audio_address:''}}
              
              save_pre_computed_results(_this.exForm,_this.$firebase,reversiblePassiveData)

              _this.$emit('logExerciseResults', reversiblePassiveData);


            }
            

            _this.results = {'correct':0,'incorrect':0,'total':0}
            
        },
        loadDifficulty()
        {

            let _this = this

            let userType = localStorage.getItem('userCategory');


            let uid = localStorage.getItem('uid');

            this.level_address = '/Users/' + uid + this.base_level_address


            if(userType!=undefined || userType==='slp')
            {
                let client_number = localStorage.getItem('client_uid');

                if(client_number!='undefined' && client_number!=null && client_number!='')
                {

                    this.level_address = '/Users/'+ client_number + this.base_level_address

                }
            }


            let do_once = true

            getData(_this.level_address, function (err, result) {

                if(do_once)
                {
                  do_once = false
                  
                  let current_level = result.child('current_level').val()
                  let lvl_up_counter = result.child('level_up_counter').val()
                  let used_exercise_id = result.child('used_ex_id').val()

                  if(used_exercise_id==undefined || used_exercise_id==null || used_exercise_id.length<=1)
                  {
                    used_exercise_id = [-1]
                  }


                  console.debug('current_level = ' + current_level)

                  console.debug('lvl_up_counter = ' + current_level)
                  console.debug('used_exercise_id = ' + current_level)

                  if(current_level==undefined || lvl_up_counter==undefined || used_exercise_id == undefined)
                  {
                    console.debug('level reload')
                    current_level = 1
                    lvl_up_counter = 0
                    used_exercise_id = []
                    updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter,used_ex_id:[-1]})
                  }



                  console.debug('current_level = ' + current_level)


                  _this.current_level = current_level
                  // _this.current_level = 2 //fixed for trsting

                
                  _this.level_up_counter = lvl_up_counter
                  _this.used_rand_id = used_exercise_id

                  _this.loadSettings()
                }

                
            })
        },
        loadSettings()
        {
            let _this = this
            this.current_question_id = 0;
            let diff_ref = _this.$firebase.database().ref('Exercises').child('General').child('Aphasia').child('Reversible_passive')
            var do_once = true;
            getData(diff_ref , function (err, result) {

                if (do_once) 
                {

                    let settings = result.val()
                    // console.debug('start')
                    
                    // console.debug('current level= '+_this.current_level)

                    if(_this.current_level>=5)
                    {
                      _this.current_level = 1
                    }
                    
                    let level_key = 'level'+_this.current_level

                    console.debug('level loaded= '+level_key)
                    _this.current_setup = settings[level_key]
                    _this.diff_level = _this.current_setup.word_diff
                    // console.debug(' !diff_level = '+_this.current_setup.word_diff)

                    // console.debug('keys = ' + Object.keys(_this.current_setup))


                    let full_q_a = _this.current_setup['q_a']
                    _this.total_question_num = Object.keys(full_q_a).length
                    
                    do_once = false;
                    
                    _this.initialiser()
                }

            });
        },
      initialiser()
      {
        let _this = this

        _this.full_passive_array = []
        _this.total_q_a  = []
        _this.if_recorder = false
        _this.passive_sentence_array = []
        _this.passive_sentence_display = []
        _this.passive_sentence_rand = []



        let data_ref = _this.$firebase.database().ref('Exercises').child('Resources').child('Pair_relation').child(_this.preposition).child(_this.diff_level)
        var do_once = true;
        getData(data_ref , function (err, result) {

        if (do_once) 
        {
            let total_num = result.numChildren()
            let rand_ids = Math.floor(Math.random()*total_num)



            _this.used_rand_id = -1
            if(!Array.isArray(_this.used_rand_id))
            {
              _this.used_rand_id = [_this.used_rand_id]
            }


            console.debug('_this.used_rand_id = ' + _this.used_rand_id)


            while(_this.used_rand_id.includes(rand_ids))
            {
                rand_ids = Math.floor(Math.random()*total_num)
            }
            _this.used_rand_id.push(rand_ids)

            let a = result.val()

            let keys = Object.keys(a)

            _this.target_pair = a[keys[rand_ids]]
            
            let pair1 = _this.target_pair.pairs.pair1
            let pair2 = _this.target_pair.pairs.pair2

            if(_this.active_passive == 'passive')
            {
                _this.word_arr_acted_on = _this.composeWords(pair2.name,pair2.description)
                _this.word_arr_acting = _this.composeWords(pair1.name,pair1.description)
                // console.debug('sentence = '+word_arr_acted_on + ' '+word_arr_acting)
                _this.verb_arr = _this.composeVerb(_this.active_passive, _this.target_pair.relation,pair2.description)
                _this.verb_arr.push(_this.preposition)
                _this.full_passive_array = _this.word_arr_acted_on.concat(_this.verb_arr,_this.word_arr_acting); 
                _this.full_sentence_string = _this.words2Sentence(_this.full_passive_array)
                // console.debug('full sentrence = '+_this.full_passive_array)

                let active_verb_arr = _this.composeVerb('active', _this.target_pair.relation,pair1.description);
                _this.full_active_array = _this.word_arr_acting.concat(active_verb_arr,_this.word_arr_acted_on)
                // console.debug('word_arr_acting= '+_this.word_arr_acting.length+' arr_acted= '+_this.word_arr_acted_on.length)
                
                let omit_word_acting = _this.omit_subject_array(_this.word_arr_acting,'space')
                let omit_word_acted = _this.omit_subject_array(_this.word_arr_acted_on,'space')
                let omit_verb_active = _this.omit_subject_array(active_verb_arr,'space')

                let omit_article_acting = _this.omit_article_array(_this.word_arr_acting,'delete')
                let omit_article_acted = _this.omit_article_array(_this.word_arr_acted_on,'delete')

                _this.active_sentence_display = omit_word_acting.concat(omit_verb_active,omit_word_acted)
                _this.active_sentence_array = omit_article_acting.concat(active_verb_arr,omit_article_acted);
                let active_rand_btn_value = _this.randBtns(_this.active_sentence_array)

                let temp_active_sentence_rand = []
                for(let active_id in active_rand_btn_value)
                {
                  temp_active_sentence_rand.push({value:active_rand_btn_value[active_id],ifShow:true})
                }

                _this.active_sentence_rand = temp_active_sentence_rand


                let omit_verb_passive = _this.omit_subject_passive_array(_this.verb_arr,'space')
                let only_verb_passive = _this.omit_articles_passive_array(_this.verb_arr,'delete')

                _this.passive_sentence_display = omit_word_acted.concat(omit_verb_passive,omit_word_acting)
                _this.passive_sentence_array = omit_article_acted.concat(only_verb_passive,omit_article_acting)
                let passive_rand_btn_value = _this.randBtns(_this.passive_sentence_array)

                let temp_passive_sentence_rand = []
                for(let passive_id in passive_rand_btn_value)
                {
                  temp_passive_sentence_rand.push({value:passive_rand_btn_value[passive_id],ifShow:true})
                }

  

                _this.passive_sentence_rand = temp_passive_sentence_rand
                

                _this.initialiseBtns()

                for(let cnt in _this.current_setup.q_a)
                {
                    let questions = _this.current_setup.q_a[cnt].question

                    
                    _this.answer_type = _this.current_setup.q_a[cnt].answer

                    let answers = _this.findExactAnswers()

                    _this.total_q_a.push({qs: questions,answer: answers,correct:true})
                }
                _this.bv_tts.say(_this.total_q_a[_this.current_question_id].qs)

                
            }
            
            // if(_this.num_getData == _this.num_option) //if all options data are fetched
            // {
            //     let rand_img_add = _this.randSequence(_this.target_category)
            //     for(let kk = 0;kk<_this.num_option;kk++)
            //     {
            //         var storageRef = _this.$firebase.storage().ref();
            //         storageRef.child(rand_img_add[kk].image_address[0]).getDownloadURL().then(function(url) {
            //             console.debug('!image_address = ' + rand_img_add[kk].image_address[0])
            //                         _this.all_images.push({'word':rand_img_add[kk].word,'img_display':url,'frameColour':'#FFFFFF'})
            //                     }).catch(function(error) {
            //                         console.debug(' err image_address = ' + rand_img_add[kk].image_address[0])

            //         });
            //     }
                
            // }
            
            do_once = false;
            
        }

        });
      },
      playPause()
      {

        this.copy_full_passive_array = []
        for(let k in this.full_passive_array)
        {
          let curr_word = this.full_passive_array[k]

          let split_word = curr_word.split(' ')

          this.copy_full_passive_array.push(split_word[0])
        }



        if(this.level_2_question_number==0)
        {
          this.target_words = [this.copy_full_passive_array[1],this.copy_full_passive_array[3],this.copy_full_passive_array[6]]

        }
        else if(this.level_2_question_number==1)
        {
          this.target_words = [this.copy_full_passive_array[6]]

        }
        else if(this.level_2_question_number==2)
        {
          this.target_words = [this.copy_full_passive_array[1]]

        }
        else
        {
          this.target_words = [this.copy_full_passive_array[3]]

        }



        console.log('this.target_words = ' + this.target_words)

        this.bv_tts.cancel()

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))

        this.is_recording = true


        this.speech_rec.start(this.target_words,false,this.region)

        this.nothing_heard_timeout = setTimeout(() => {
          if(this.is_recording)
          {
            this.speech_rec.stop()
            this.is_recording = false

            let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
            nothing_heard_pop.show()

          }
        }, 20000);

        



      },
      omit_subject_array(word_array,delete_space)
      {
        //only keep the articles
        let omitted_array = []

        if(delete_space == 'space')
        {
          if(word_array.length>1)
          {
            omitted_array.push(word_array[0])
            for(let i=1;i<word_array.length;i++)
            {
              omitted_array.push('?')
            }
          }
          else
          {
            omitted_array.push('?')
          }
        }
        else if(delete_space == 'delete')
        {
          if(word_array.length>1)
          {
            omitted_array.push(word_array[0])
          }
        }
        
        return omitted_array
      },
      omit_article_array(word_array,delete_space)
      {
        //only keep the word iteself without any articles
        let omitted_array = []
        if(delete_space == 'space')
        {
          if(word_array.length>1)
          {
            omitted_array.push('?')
            for(let i=1;i<word_array.length;i++)
            {
              omitted_array.push(word_array[i])
            }
          }
          else
          {
            omitted_array.push(word_array[0])
          }
        }
        else if(delete_space == 'delete')
        {
          if(word_array.length>1)
          {
            for(let i=1;i<word_array.length;i++)
            {
              omitted_array.push(word_array[i])
            }
          }
          else
          {
            omitted_array.push(word_array[0])
          }
        }
        
        return omitted_array
      },
      omit_subject_passive_array(word_array,delete_space)
      {
        //only articles and preposition
        let omitted_array = []
        if(delete_space == 'space')
        {
          for(let i=0;i<word_array.length;i++)
          {
            if(i == 0||i==word_array.length-1)
            {
              omitted_array.push(word_array[i])
            }
            else
            {
              omitted_array.push('?')
            }
          }
          
        }
        else if(delete_space == 'delete')
        {
          for(let i=0;i<word_array.length;i++)
          {
            if(i == 0||i==word_array.length-1)
            {
              omitted_array.push(word_array[i])
            }
          }
        }
        
        return omitted_array
      },
      omit_articles_passive_array(word_array,delete_space)
      {
        //only the word iteself
        let omitted_array = []
        if(delete_space == 'space')
        {
          for(let i=0;i<word_array.length;i++)
          {
            if(i == 0||i==word_array.length-1)
            {
              omitted_array.push('?')
            }
            else
            {
              omitted_array.push(word_array[i])
            }
          }
          
        }
        else if(delete_space == 'delete')
        {
          for(let i=0;i<word_array.length;i++)
          {
            if(i != 0&&i!=word_array.length-1)
            {
              omitted_array.push(word_array[i])
            }
          }
        }
        
        return omitted_array
      },
      randBtns(arr)
      {
        let rand_arr = []
        let temp_arr = [...arr]
        for(let i in arr)
        {
          let rand_id = Math.floor(Math.random() * temp_arr.length);
          rand_arr.push(temp_arr[rand_id])
          temp_arr.splice(rand_id,1)
        }
        return rand_arr
      },
      moveBtns(arr_id, btn_arr,display_arr)
      {
        btn_arr[arr_id].ifShow = false
        let answer_arr = this.total_q_a[this.current_question_id].answer
        // console.debug('asnwer arr = '+answer_arr+'system answr= '+this.total_q_a[this.current_question_id].answer)
        this.appendText(display_arr,btn_arr[arr_id].value)
        if(this.btn_move_counter == btn_arr.length)
        {
          let diff_id_in_display = this.compareArrays(answer_arr,display_arr)
          if(diff_id_in_display.length == 0)
          {
            this.interaction_message = 'Well done, you did it!'
            this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
            this.process_level_3_4_results(true)
            startConfetti(this)
            this.result_response()
          }
          else
          {
            this.process_level_3_4_results(false)

            for(let k in diff_id_in_display)
            {
              this.btn_class[diff_id_in_display[k]] = 'incorrect-wide-button'
            }
          }
        }
      },
      process_level_3_4_results(correct)
      {
        if(this.results['total'] == 0)
        {
          if(correct)
          {
            this.results['correct'] = 1

          }
          else
          {
            this.results['incorrect'] = 1

          }
          this.results['total'] = 1

        }

      },
      appendText(array,input)
      {
        for(let i in array)
        {
          if(array[i] == '?')
          {
            array[i] = input
            this.btn_move_counter++
            break
          }
        }
      },
      compareArrays(target_arr,arr2)
      {
        let same_counter = 0
        let result = []
        if(target_arr.length != arr2.length)
        {
          for(let j in arr2)
          {
            result.push(j)
          }
        }
        else
        {
          for(let i in arr2)
          {
            if(arr2[i] == target_arr[i])
            {
              same_counter++
            }
            else
            {
              result.push(i)
            }
          }
          if(same_counter == target_arr.length)
          {
            result = []
          }
          
        }
        return result

      },
      removeBtns(id,display_arr,btn_arr)
      {
        for(let i in btn_arr)
        {
          if(btn_arr[i].value == display_arr[id])
          {
            btn_arr[i].ifShow = true
            display_arr[id] = '?'
            this.btn_class[id] = 'basic-wide-button'
            this.btn_move_counter--
            break
          }
        }
      },
      
      initialiseBtns()
      {
        this.btn_class = []
        if(this.current_level<3)
        {
          for(let ii in this.full_passive_array)
          {
              if(this.full_passive_array[ii] == 'by')
              {
                  this.btn_class.push('btn btn-primary')
              }
              else
              {
                  this.btn_class.push('basic-button')
              }
          }
        }
        else if(this.current_level == 3)
        {
          for(let i in this.passive_sentence_display)
          {
            if(this.passive_sentence_display[i] == '?')
            {
              this.btn_class.push('basic-wide-button')
            }
            else
            {
              this.btn_class.push('basic-button')
            }
          }
        }
        else if(this.current_level == 4)
        {
          for(let i in this.active_sentence_display)
          {
            if(this.active_sentence_display[i] == '?')
            {
              this.btn_class.push('basic-wide-button')
            }
            else
            {
              this.btn_class.push('basic-button')
            }
          }
        }
        
      },
      findExactAnswers()
      {
        let answers = ''
        let _this = this
        if(_this.answer_type == 'verb')
        {
            answers = _this.verb_arr[_this.verb_arr.length-2]
            _this.if_recorder = false
        }
        else if(_this.answer_type == 'acting')
        {
            answers = _this.word_arr_acting[_this.word_arr_acting.length-1]
            _this.if_recorder = false
        }
        else if(_this.answer_type == 'acted')
        {
            answers = _this.word_arr_acted_on[_this.word_arr_acted_on.length-1]
            _this.if_recorder = false
        }
        else if(_this.answer_type == 'record_sentence')
        {
            answers = _this.words2Sentence(_this.full_passive_array)
            _this.if_recorder = true
        }
        else if(_this.answer_type == 'record_acting')
        {
            answers = _this.word_arr_acting[_this.word_arr_acting.length-1]
            _this.if_recorder = true
        }
        else if(_this.answer_type == 'record_acted')
        {
            answers = _this.word_arr_acted_on[_this.word_arr_acted_on.length-1]
            _this.if_recorder = true
        }
        else if(_this.answer_type == 'sequence_passive')
        {
            answers = _this.full_passive_array
            _this.if_recorder = false
        }
        else if(_this.answer_type == 'sequence_active')
        {
            answers = _this.full_active_array
            _this.if_recorder = false
        }
        else
        {
            console.debug('Undefined answer = '+_this.answer_type)
        }

        return answers
      },
      words2Sentence(words_arry)
      {
        let sentence = ''
        for(let i in words_arry)
        {
            sentence = sentence+words_arry[i]+' '
        }
        return sentence
      },
      composeWords(word,description)
      {
        let word_arry = []
        if(this.diff_level == 'easy')
        {
          if(description == 'plural')
          {
            word_arry = ['the',word+'s']
          }
          else
          {
            word_arry = ['the',word]
          }
        }
        else
        {
          if(description == 'singular')
          {
              let rand_id = Math.floor(Math.random()*3)
              if(rand_id == 0)
              {
                  word_arry = ['the',word]
              }
              else
              {
                  word_arry = ['a',word]
              }
          }
          else if(description == 'plural')
          {
              word_arry = [word+'s']
          }
          else if(description == 'uncountable')
          {
              word_arry = [word]
          }
          else if(description == 'article')
          {
              word_arry = ['the',word]
          }
          else
          {
              console.debug('ERR: description = '+description)
          }
        }
        

        return word_arry

      },
      composeVerb(mode, verb_options,acting_subj_description)
      {
        let verb_arr = []
        if(mode == 'passive')
        {
            let verb = verb_options.passive
            if(acting_subj_description == 'singular')
            {
                verb_arr = ['is', verb]
            }
            else if(acting_subj_description == 'plural')
            {
                verb_arr = ['are', verb]
            }
            else if(acting_subj_description == 'uncountable')
            {
                verb_arr = ['is', verb]
            }
            else if(acting_subj_description == 'article')
            {
                verb_arr = ['is', verb]
            }
            else
            {
                console.debug('ERR: description = '+acting_subj_description)
            }
        }
        else if(mode == 'active')
        {
            let verb = verb_options.active
            if(acting_subj_description == 'singular')
            {
                verb_arr = [verb+'s']
            }
            else if(acting_subj_description == 'plural')
            {
                verb_arr = [verb]
            }
            else if(acting_subj_description == 'uncountable')
            {
                verb_arr = [verb+'s']
            }
            else if(acting_subj_description == 'article')
            {
                verb_arr = [verb+'s']
            }
            else
            {
                console.debug('ERR: description = '+acting_subj_description)
            }
        }
        else
        {
            console.debug('ERR: mode = '+mode)
        }

        return verb_arr
      },
      checkResult(input_id)
      {
        let input = this.full_passive_array[input_id]
        if(input == this.total_q_a[this.current_question_id].answer)
        {

            if(this.first_attempt)
            {
              this.results.correct = this.results.correct + 1
              this.results.total = this.results.total + 1

            }

            this.first_attempt = true
            this.btn_class[input_id] = 'correct-button'
            this.interaction_message = 'Well done! You got it right'
            this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'
            startConfetti(this)
            //this.$forceUpdate()
            this.result_response()
        }
        else
        {

            if(this.first_attempt)
            {
              this.results.incorrect = this.results.incorrect + 1
              this.results.total = this.results.total + 1

            }

            this.first_attempt = false
            this.btn_class[input_id] = 'incorrect-button'
            this.interaction_message = 'Oops, a tricky one. Try again!'
            this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'
            this.total_q_a[this.current_question_id].correct = false
            //this.$forceUpdate()
            setTimeout(() => {
                this.btn_class[input_id] = 'basic-button'
                this.interaction_message = ''
                //this.$forceUpdate()
            }, 1200);

        }

      },
      result_response()
      {

        this.clearAll()

        if(this.current_question_id<this.total_question_num-1)
        {

          this.saveResults()
            setTimeout(() => {
                this.nextQuestion()
            }, 1200);
        }
        else
        {
            let temp_counter = 0
            for(let kk in this.total_q_a)
            {
                if(this.total_q_a[kk].correct)
                {
                    temp_counter++
                }
            }
            if(temp_counter == this.total_q_a.length)
            {
                this.level_up_counter++
                if(this.level_up_counter == this.level_up_target)
                {
                    this.level_up_counter = 0
                    this.current_level++
                    this.used_rand_id = [-1]
                }
                console.debug('update current level= '+this.current_level +' counter = '+this.level_up_counter)
                updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter,used_ex_id:this.used_rand_id})
                // this.nextExercise()
                setTimeout(() => {
                    this.nextExercise()
                }, 1200);
            }
            else
            {
                // this.nextExercise()
                setTimeout(() => {
                    this.nextExercise()
                }, 1200);
            }
            
        }
      },
      nextQuestion()
      {
        this.current_question_id++
        this.interaction_message = ''
        this.bv_tts.say(this.total_q_a[this.current_question_id].qs)
        this.initialiseBtns()
        //this.$forceUpdate()
      },
      
    },
  }
  </script>
  <style scoped>
  
  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }

  .basic-button
  {
    color: black;
    background-color: lightgrey;
    border-color: black;
  }

  .basic-wide-button
  {
    color: blueviolet;
    background-color: lightgray;
    border-color: black;
    width: 45mm;
  }
  .incorrect-wide-button
  {
    color: white;
    background-color: red;
    border-color: red;
    width: 45mm;
  }

  .correct-button
  {
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    color:white;
    background-color: red;
    border-color: red;
  }
  
  </style>

  <!-- Results Description
  {
  "Reversible Passive": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Aphasia": { //Need to remove this layer
          "ReversiblePassive": { //Need to remove this layer
            "UserLevel": { //Need to remove this layer
              "<key>": {
                "audio_address": "<empty>",
                "resultsTablePre": {
                  "audio_address": "<empty>",
                  "exercise": "<int>",
                  "exercise_type": "Reversible Passive",
                  "results": {
                    "all_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Total": "<int>"
                    },
                    "plot_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>"
                    }
                  }
                },
                "results_type": "pre_computed"
              }
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Reversible Passive"
  }
} -->