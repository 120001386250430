import {getData} from "../../../firebase"
import { valueCrawler } from "../../crawlers";

function makePromise(curr_target_dir) {
    return Promise.resolve(curr_target_dir);
}


export async function Create_News_Exercise(config)
{

    let region = config.region.toUpperCase()
    let topics = config.topics
    let news_size = config.news_size
    let type = config.type


    let template_target_dir = '/Exercises/General/News/'+ region + '/' + type + '/' + news_size + '/'

    let target_dirs = []

    const promiseArray = []
    for(let i in topics)
    {
        let curr_target_dir = template_target_dir + topics[i]
        target_dirs.push(curr_target_dir)

        console.debug('topics[i] = ' + topics[i])


        promiseArray.push(Get_Single_Target_News_Exercise(curr_target_dir))
    }



    const all_results = await Promise.all(promiseArray)


    let exForm_list = []

    for(let i in all_results)
    {
        let curr_topic_results = all_results[i]
        

        for(let n in curr_topic_results)
        {
            exForm_list.push(curr_topic_results[n])
        }
    }


    exForm_list = shuffle(exForm_list)

    let final_exForm_list = []

    if(config.n_exercises!=undefined && config.n_exercises!=null && config.n_exercises>0)
    {
      for(let i=0;i<Math.min(config.n_exercises,exForm_list.length);++i)
      {
          final_exForm_list.push(exForm_list[i])
      }
    }
    else
    {
      final_exForm_list = exForm_list
    }
    return final_exForm_list
}


export function Get_Single_Target_News_Exercise(targetDir)
{
    let exForm_list = new Promise(function(resolve)
    {
        let exForm_list = []
        
        let do_once = true

        console.debug('targetDir = ' + targetDir)
        getData(targetDir , function (err, result) {

            if (do_once) {

                do_once = false

                do_once = false;
                result.forEach(function (childSnapshot){

                    let curr_exForm = childSnapshot.val()

                    curr_exForm.url = curr_exForm.Url
                    curr_exForm.url2image = curr_exForm.Urltoimage
                    exForm_list.push(curr_exForm)


                })
                console.debug('topic exForm_list.length = ' + exForm_list.length)

                resolve(exForm_list)

            }
        });
    })

    return exForm_list
}


export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export function Input_Assistance(exForm_list,assistance_template)
{

  for(let i in exForm_list)
  {

    exForm_list[i].assist = assistance_template
  }

  return exForm_list
}

export function Input_No_Assistance(exForm_list)
{
  let assistance_template = []

  let exForm_list_out = Input_Assistance(exForm_list,assistance_template)

  return exForm_list_out
}

export async function Create_Exercise_From_Exercise_Directories(dir_array)
{


  const promiseArray = []
  for(let i in dir_array)
  {
      let curr_target_dir = dir_array[i]

      promiseArray.push(Create_Exercise_From_Single_Exercise_Directory(curr_target_dir))
  }

  const all_results = await Promise.all(promiseArray)


  let exForm_list = []

  for(let i in all_results)
  {
      let curr_dir_results = all_results[i]
      

      for(let n in curr_dir_results)
      {
          exForm_list.push(curr_dir_results[n])
      }
  }

  return exForm_list

}

export function Create_Exercise_From_Single_Exercise_Directory(targetDir)
{
  let exForm_list = new Promise(function(resolve)
  {
      let exForm_list = []
      
      let do_once = true

      getData(targetDir , function (err, result) {

          if (do_once) {

              do_once = false

              do_once = false;
              result.forEach(function (childSnapshot){

                  let curr_exForm = childSnapshot.val()

                  console.debug('curr_ex form ')

                  console.debug(curr_exForm)


                  exForm_list.push(curr_exForm)


              })

              resolve(exForm_list)

          }
      });
  })

  return exForm_list
  
}



export async function Create_Exercise_From_Base_Plans(base_plan_array)
{


  const promiseArray = []
  for(let i in base_plan_array)
  {
      let curr_target_dir = base_plan_array[i]

      promiseArray.push(Create_Exercise_From_Single_Base_Plan_Directory(curr_target_dir))
  }

  const exercise_addresses_joined = await Promise.all(promiseArray)

  const promiseArray2 = []

  let exercise_addresses = []

  for(let i in exercise_addresses_joined)
  {
    for(let n in exercise_addresses_joined[i])
    {
      exercise_addresses.push(exercise_addresses_joined[i][n])
      
    }
  }

  for(let i in exercise_addresses)
  {

    console.debug('exercise_addresses = ' + exercise_addresses[i])

    console.debug('here')

    promiseArray2.push(Create_Exercise_From_Single_Exercise_Address(exercise_addresses[i]))

  }

  let all_results = await Promise.all(promiseArray2)

  let exForm_list = all_results 

  return exForm_list

}


export async function Create_Exercise_From_Single_Base_Plan_Directory(targetDir)
{
  let exercise_addresses = new Promise( function(resolve)
  {
      // let exForm_list = []
      
      let do_once = true

      getData(targetDir , function (err, result) {

          if (do_once) {

              do_once = false

              let exercise_addresses = []
              valueCrawler(result,'address',exercise_addresses)

              for(let i in exercise_addresses)
              {
                console.debug('exercise_addresses_in = ' + exercise_addresses[i])
              }

              resolve(exercise_addresses)



          }
      })
  })

  return exercise_addresses

}




export async function Create_Exercise_From_Exercise_Address_List(exercise_addresses)
{
  const promiseArray = []

  for(let i in exercise_addresses)
  {

    promiseArray.push(Create_Exercise_From_Single_Exercise_Address(exercise_addresses[i]))

  }

  let exForm_list = await Promise.all(promiseArray)


  return exForm_list



}

export function Create_Exercise_From_Single_Exercise_Address(targetAddress)
{
  let exForm_list = new Promise(function(resolve)
  {
      let exForm_list = []
      
      let do_once = true

      getData(targetAddress , function (err, result) {

          if (do_once) {

              do_once = false

              exForm_list = result.val()

              resolve(exForm_list)


          }
      });
  })

  return exForm_list
  
}

export async function Create_Exercise_From_Token_Test_Directories(dir_array)
{


  const promiseArray = []
  for(let i in dir_array)
  {
      let curr_target_dir = dir_array[i]

      promiseArray.push(Create_Exercise_From_Single_Token_Test_Exercise_Directory(curr_target_dir))
  }

  const all_results = await Promise.all(promiseArray)


  let exForm_list = []

  for(let i in all_results)
  {
      let curr_dir_results = all_results[i]
      

      for(let n in curr_dir_results)
      {
          exForm_list.push(curr_dir_results[n])
      }
  }

  return exForm_list

}

export function Create_Exercise_From_Single_Token_Test_Exercise_Directory(targetDir)
{
  let exForm_list = new Promise(function(resolve)
  {
      let exForm_list = []
      
      let do_once = true

      console.debug('targetDir = ' + targetDir)

      getData(targetDir , function (err, result) {

          if (do_once) {

            do_once = false

            let token_test_data = result.val()
            let curr_exForm = Create_Token_Test_Instructions(token_test_data)

            for(let i in curr_exForm)
            {
              exForm_list.push(curr_exForm[i])
            }


            resolve(exForm_list)

          }
      });
  })

  return exForm_list
  
}


export function Create_Token_Test_Instructions(token_test_data)
{
  let organised_instructions = []
  let color_info = []
  let allcolor = []

  console.debug('token_test_data = ' + token_test_data)

  for(let n in token_test_data)
  {
      console.debug('n = ' + n)
  }

  var color_info_handle = token_test_data["color_shape_size"]['color'];

  for(let color in color_info_handle)
  {
      
      let temp_color_info = {color_name:color,color_code:color_info_handle[color]}
      color_info.push(temp_color_info);
      allcolor.push("background:"+temp_color_info.color_code);

  }


  var shape_info_handle = token_test_data["color_shape_size"]['shape'];
  let allshape = []
  let shape_info = []

  for(let shape in shape_info_handle)
  {
      let temp_shape_info = {shape_name:shape,shape_code:shape_info_handle[shape]};
      allshape.push(temp_shape_info.shape_code);
      shape_info.push(temp_shape_info);

  }

  var size_info_handle = token_test_data["color_shape_size"]['size'];
  let allsize = []
  let size_info = []

  for(let size in size_info_handle)
  {
      let temp_size_info = {size_name:size,size_code:size_info_handle[size]};
      allsize.push(temp_size_info.size_code);
      size_info.push(temp_size_info);
  }

  let instruction_steps = token_test_data['steps']

  for(let i in instruction_steps)
  {

    let n_repeat = instruction_steps[i]['repeat']

    let prev_object = {}

    for(let n=0;n<n_repeat;++n)
    {
      if(instruction_steps[i].operation!='simple')
      {
        continue
      }


      let reshuffle = false
      if(n==0)
      {
        reshuffle = true
      }

      let full_token = instruction_steps[i]['full_token']
      let token_select = instruction_steps[i]['token_select']

      let num = instruction_steps[i]['num']

      let objects = []

      let text = 'Click on '
      
      let special_one = false;

      for(let k=0;k<num;++k)
      {
        let obj_properties = {}
        if(token_select=='any')
        {

          if(k==0)
          {
            text = text + 'one of the ';
            special_one = true;
          }
          else
          {
            text = text + 'and a '
            special_one = false;
          }


          let shape_or_color = Math.floor(Math.random()*2)

          if(shape_or_color==1)
          {
            let color_id = Math.floor(Math.random()*color_info.length)
            let color_name = color_info[color_id].color_name
            let color = color_info[color_id].color_code
            let color_code = 'background:'+color

            if('color' in prev_object)
            {
              if(prev_object.color == color_code)
              {
                if(color_id>0)
                {
                  color_id = 0
                  color_name = color_info[color_id].color_name
                  color = color_info[color_id].color_code
                  color_code = 'background:'+color
                }
                else
                {
                  color_id = 1
                  color_name = color_info[color_id].color_name
                  color = color_info[color_id].color_code
                  color_code = 'background:'+color

                }
              }
            }

            obj_properties.color_name = color_name
            obj_properties.color = color_code

            text = text + color_name + ' '
          }
          else
          {
            let shape_id = Math.floor(Math.random()*shape_info.length)
            let shape_name = shape_info[shape_id].shape_name
            let shape = shape_info[shape_id].shape_code

            if('shape' in prev_object)
            {
              if(prev_object.shape == shape)
              {
                if(shape_id>0)
                {
                  shape_id = 0
                  shape_name = shape_info[shape_id].shape_name
                  shape = shape_info[shape_id].shape_code
                }
                else
                {
                  shape_id = 1
                  shape_name = shape_info[shape_id].shape_name
                  shape = shape_info[shape_id].shape_code

                }
              }
            }

            obj_properties.shape_name = shape_name
            obj_properties.shape = shape

            text = text + shape_name + ' '

          }
        }
        else
        {
          if(k==0)
          {
            text = text + 'the '
          }
          else
          {
            text = text + 'and the '
          }

          if(full_token)
          {
            let size_id = Math.floor(Math.random()*size_info.length)
            let size_name = size_info[size_id].size_name
            let size = size_info[size_id].size_code

            obj_properties.size_name = size_name
            obj_properties.size = size

            text = text + size_name + ' '

          }

          
          let color_id = Math.floor(Math.random()*color_info.length)
          let color_name = color_info[color_id].color_name
          let color = color_info[color_id].color_code
          let color_code = 'background:'+color

          
          let shape_id = Math.floor(Math.random()*shape_info.length)
          let shape_name = shape_info[shape_id].shape_name
          let shape = shape_info[shape_id].shape_code

          if('shape' in prev_object && 'color' in prev_object)
          {
            if(prev_object.shape == shape && prev_object.color == color_code)
            {
              if(shape_id>0)
              {
                shape_id = 0
                shape_name = shape_info[shape_id].shape_name
                shape = shape_info[shape_id].shape_code
              }
              else
              {
                shape_id = 1
                shape_name = shape_info[shape_id].shape_name
                shape = shape_info[shape_id].shape_code

              }

              if(color_id>0)
              {
                color_id = 0
                color_name = color_info[color_id].color_name
                color = color_info[color_id].color_code
                color_code = 'background:'+color
              }
              else
              {
                color_id = 1
                color_name = color_info[color_id].color_name
                color = color_info[color_id].color_code
                color_code = 'background:'+color

              }
            }
          }

          obj_properties.color_code = color_name
          obj_properties.color = color_code

          obj_properties.shape = shape
          obj_properties.shape_name = shape_name

          text = text + color_name + ' ' + shape_name + ' '

        }

        prev_object = obj_properties

        objects.push(obj_properties)

        for(let t in obj_properties)
        {
          console.debug('key = ' + t)
          console.debug('obj_properties[t] = ' + obj_properties[t])
        }
      }

      if(num>1)
      {
        text = text + 'objects'
      }
      else
      {
        if(special_one)
        {
          text = text + 'objects'
        }
        else
        {
          text = text + 'object'
        }
      }

      let curr_instruction = {objects:objects,text:text,reshuffle:reshuffle,full_token:full_token}

      curr_instruction.exercise_type = 'token_test'

      curr_instruction.allcolor = allcolor
      curr_instruction.allshape = allshape
      curr_instruction.allsize = allsize
      curr_instruction.shape_info = shape_info
      curr_instruction.color_info = color_info
      curr_instruction.size_info = size_info

      organised_instructions.push(curr_instruction)

    }

  }

  return organised_instructions

}

export function randomiseExerciseSets(exercise_sets,n_exercises_per_set)
{

  let output_exercise_set = []

  for(let i in exercise_sets)
  {
    let n_exercises = n_exercises_per_set[i]
    let curr_exercise_set = exercise_sets[i]

    let shuffled_exercises = shuffle(curr_exercise_set)


    for(let n=0;n<n_exercises;++n)
    {
      output_exercise_set.push(shuffled_exercises[n])
    }
  }

  return output_exercise_set

}


export async function Create_Phoneme_Exercise_List(phoneme_list, words_per_phoneme)
{
    var do_once = true;
    let today = getTodayString()
     

    let targetDir = 'Exercises/Articulation/'

    let exForm_list = new Promise(function(resolve) {
      let exForm_list = []
      
      getData(targetDir , function (err, result) {
        if (do_once) {

          do_once = false;

          for(let i in phoneme_list) {
            Create_Single_Phoneme_Exercise_List(phoneme_list[i],words_per_phoneme,exForm_list,result,today)
          }
          console.debug('exForm_list.length = ' + exForm_list.length)
          resolve(exForm_list)
        }
      });
    })

    return exForm_list
}

import constants from "@/utils/constants"
import {getTodayString} from '@/media_input/utils'
import {capitalizeFirstLetter} from '@/media_input/words'


function Create_Single_Phoneme_Exercise_List(phoneme,words_per_phoneme,exForm_list,database,today,record_text)
{

    let temp_word_list = []


    database.child('Consonants').forEach(function(childSnapshot)
    {

        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {

            let exForm = childSnapshot.val()

            exForm.instruct = null
            // exForm.text = "Practise the sound /" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]+"/ with the video"
            exForm.text = "Practise the sound '" + constants.phonemeDisplayUKSpelling[childSnapshot.key.toLowerCase()]+"' with the video"
            exForm.curr_exercise_path = childSnapshot.key 
            exForm.today = today
            exForm.module_name = 'IPA_'+ childSnapshot.key
            exForm.assessment_type = "USER_TRAINING"

            exForm_list.push(exForm)            
            
        }
    });

    database.child('Vowels').forEach(function(childSnapshot)
    {

        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {

            let exForm = childSnapshot.val()

            exForm.instruct = null
            // exForm.text = "Practise the sound /" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]+"/ with the video"
            exForm.text = "Practise the sound '" + constants.phonemeDisplayUKSpelling[childSnapshot.key.toLowerCase()]+"' with the video"
            exForm.curr_exercise_path = childSnapshot.key 
            exForm.today = today
            exForm.module_name = 'IPA_'+ childSnapshot.key
            exForm.assessment_type = "USER_TRAINING"

            exForm_list.push(exForm)
            
        }
    });

    database.child('Words').forEach(function(childSnapshot)
    {
        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {
            childSnapshot.forEach(function(exerciseSnapshot)
            {

                let exForm = exerciseSnapshot.val()

                exForm.text =  capitalizeFirstLetter(exForm.text)
                // exForm.instruct = "Press Record and practise pronouncing the phoneme '" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()] + "' in word: "
                exForm.instruct = "Watch the video. Press Record and say the word: "
                exForm.curr_exercise_path = childSnapshot.key + "/" + exerciseSnapshot.key
                exForm.today = today
                exForm.module_name = 'IPA_'+ childSnapshot.key
                exForm.start_btn_text = 'Record'
                exForm.assessment_type = "USER_TRAINING"


                temp_word_list.push(exForm)

            
            });
        }
    });


    if(words_per_phoneme>0)
    {
        if(temp_word_list.length>words_per_phoneme)
        {
            temp_word_list = getRandom(temp_word_list, words_per_phoneme)
        }
        
    }


    for(let i in temp_word_list)
    {
        exForm_list.push(temp_word_list[i])
    }

}

function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

  
  