
export function count_db_requested_exercises(module_root) {
    let n_exercises = 0
  
    ///tmp workaround for incosistent database
    for (let key of Object.keys(module_root)) {
      if (key.toLowerCase().includes('module')) {
        //this shouldn't be correct as instruction types are also counted
        n_exercises = n_exercises + 1
      }
    }
    ////
  
    if (module_root.hasOwnProperty('number_exercises')) {
      n_exercises = Number(module_root['number_exercises'])
      return n_exercises
    }
  
    if (module_root.hasOwnProperty('exercises')) {
      for (let exercise of module_root['exercises']) {
  
        if(exercise)
        {
          let exercise_range = exercise['exercise_range']
          if (exercise_range) {
            if (exercise_range === 'multi_exercises') {
              n_exercises = n_exercises + Number(exercise['number_exercises'])
            } else {
                //this shouldn't be correct as instruction types are also counted
                //n_exercises = n_exercises + 1
            }
          }
        }
      }
    
    } else {
      
      if (n_exercises > 0) {      
        n_exercises = 5
        //do nothing it is a module0-2 module (so no exercises folder included)
      } else {
        n_exercises = 5
      }
    }
  
    return n_exercises
  }
  
  export function count_db_total_all_type_exercises(module_root) {
    let n_exercises = 0
  
    ///tmp workaround for incosistent database
    for (let key of Object.keys(module_root)) {
      if (key.toLowerCase().includes('module')) {
        //this shouldn't be correct as instruction types are also counted
        n_exercises = n_exercises + 1
      }
    }
  
    if (module_root.hasOwnProperty('number_exercises')) {
      n_exercises = Number(module_root['number_exercises'])
    }
    console.log(n_exercises)
    if (module_root.hasOwnProperty('exercises')) {
      for (let exercise of module_root['exercises']) {
        if(exercise) {
          let exercise_type = exercise['type']
          if (exercise_type) {
            if (!exercise_type.includes('demo_video')) 
            {
              n_exercises = n_exercises + 1
            }
          }
        }
      }
    }
  
    return n_exercises
  }