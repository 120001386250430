<template>
  <div>
    <Navbar  :enableBack=true></Navbar>
    
    <div class="container-fluid">
      <h1 class="display-5">IPA</h1>
      <div class="card">
        <div class="card-body">
          <p class="lead">{{startingText}}</p>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('iy')" ><h1><strong>{{phonemeDisplayDictionary['iy']}}</strong></h1></button>
              <p style="margin-left: 18px;">Sh<b style="color:#FF9333">ee</b>p</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('ih')" ><h1><strong>{{phonemeDisplayDictionary['ih']}}</strong></h1></button>
              <p style="margin-left: 18px;">D<b style="color:#FF9333">i</b>sh</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('uh')" ><h1><strong>{{phonemeDisplayDictionary['uh']}}</strong></h1></button>
              <p style="margin-left: 18px;">L<b style="color:#FF9333">oo</b>k</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('uw')" ><h1><strong>{{phonemeDisplayDictionary['uw']}}</strong></h1></button>
              <p style="margin-left: 18px;">Bl<b style="color:#FF9333">ue</b></p>
            </div>
            <div class="col">

            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('ihr')" ><h1><strong>{{phonemeDisplayDictionary['ihr']}}</strong></h1></button>
              <p style="margin-left: 18px;">D<b style="color:#FF9333">ea</b>r</p>
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('ehr')" ><h1><strong>{{phonemeDisplayDictionary['ehr']}}</strong></h1></button>
              <p style="margin-left: 18px;">B<b style="color:#FF9333">ea</b>r</p>
            </div>
            <div class="col">
              
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('eh')" ><h1><strong>{{phonemeDisplayDictionary['eh']}}</strong></h1></button>
              <p style="margin-left: 23px;"><b style="color:#FF9333">E</b>gg</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('ax')" ><h1><strong>{{phonemeDisplayDictionary['ax']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">A</b>live</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('er')" ><h1><strong>{{phonemeDisplayDictionary['er']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">Ear</b>th</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('ao')" ><h1><strong>{{phonemeDisplayDictionary['ao']}}</strong></h1></button>
              <p style="margin-left: 18px;">F<b style="color:#FF9333">or</b>k</p>
            </div>
            <div class="col">
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('ow')" ><h1><strong>{{phonemeDisplayDictionary['ow']}}</strong></h1></button>
              <p style="margin-left: 26px;">S<b style="color:#FF9333">o</b></p>
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('aw')" ><h1><strong>{{phonemeDisplayDictionary['aw']}}</strong></h1></button>
              <p style="margin-left: 18px;">H<b style="color:#FF9333">ou</b>se</p>
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('uhr')" ><h1><strong>{{phonemeDisplayDictionary['uhr']}}</strong></h1></button>
              <p style="margin-left: 18px;">P<b style="color:#FF9333">ur</b>e</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('ae')" ><h1><strong>{{phonemeDisplayDictionary['ae']}}</strong></h1></button>
              <p style="margin-left: 23px;">C<b style="color:#FF9333">a</b>t</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('ah')" ><h1><strong>{{phonemeDisplayDictionary['ah']}}</strong></h1></button>
              <p style="margin-left: 23px;">F<b style="color:#FF9333">u</b>n</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('aa')" ><h1><strong>{{phonemeDisplayDictionary['aa']}}</strong></h1></button>
              <p style="margin-left: 18px;">H<b style="color:#FF9333">ear</b>t</p>
            </div>
            <div class="col">
              <button class="btn btn-primary" style="width:80px; height: 80px" type="button" @click="makeSound('aar')" ><h1><strong>{{phonemeDisplayDictionary['aar']}}</strong></h1></button>
              <p style="margin-left: 18px;">S<b style="color:#FF9333">o</b>ck</p>
            </div>
            <div class="col">
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('ey')" ><h1><strong>{{phonemeDisplayDictionary['ey']}}</strong></h1></button>
              <p style="margin-left: 18px;">B<b style="color:#FF9333">a</b>ke</p>
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('ay')" ><h1><strong>{{phonemeDisplayDictionary['ay']}}</strong></h1></button>
              <p style="margin-left: 23px;">P<b style="color:#FF9333">ie</b></p>
            </div>
            <div class="col">
              <button class="btn btn-danger" style="width:80px; height: 80px" type="button" @click="makeSound('oy')" ><h1><strong>{{phonemeDisplayDictionary['oy']}}</strong></h1></button>
              <p style="margin-left: 16px;"><b style="color:#FF9333">Oy</b>ster</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('p')" ><h1><strong>{{phonemeDisplayDictionary['p']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">P</b>erch</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('f')" ><h1><strong>{{phonemeDisplayDictionary['f']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">F</b>ive</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('t')" ><h1><strong>{{phonemeDisplayDictionary['t']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">T</b>ent</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('th')" ><h1><strong>{{phonemeDisplayDictionary['th']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">Th</b>ick</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('ch')" ><h1><strong>{{phonemeDisplayDictionary['ch']}}</strong></h1></button>
              <p style="margin-left: 16px;"><b style="color:#FF9333">Ch</b>eese</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('s')" ><h1><strong>{{phonemeDisplayDictionary['s']}}</strong></h1></button>
              <p style="margin-left: 23px;"><b style="color:#FF9333">S</b>ea</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('sh')" ><h1><strong>{{phonemeDisplayDictionary['sh']}}</strong></h1></button>
              <p style="margin-left: 23px;"><b style="color:#FF9333">Sh</b>e</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('k')" ><h1><strong>{{phonemeDisplayDictionary['k']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">C</b>oin</p>
            </div>
          </div>
          <div class="row">
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('b')" ><h1><strong>{{phonemeDisplayDictionary['b']}}</strong></h1></button>
                <p style="margin-left: 18px;"><b style="color:#FF9333">B</b>oost</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('v')" ><h1><strong>{{phonemeDisplayDictionary['v']}}</strong></h1></button>
                <p style="margin-left: 18px;"><b style="color:#FF9333">V</b>oice</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('d')" ><h1><strong>{{phonemeDisplayDictionary['d']}}</strong></h1></button>
                <p style="margin-left: 18px;"><b style="color:#FF9333">D</b>oor</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('dh')" ><h1><strong>{{phonemeDisplayDictionary['dh']}}</strong></h1></button>
                <p style="margin-left: 18px;"><b style="color:#FF9333">Th</b>ese</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('jh')" ><h1><strong>{{phonemeDisplayDictionary['jh']}}</strong></h1></button>
                <p style="margin-left: 23px;"><b style="color:#FF9333">J</b>oy</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('z')" ><h1><strong>{{phonemeDisplayDictionary['z']}}</strong></h1></button>
                <p style="margin-left: 18px;"><b style="color:#FF9333">Z</b>one</p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('zh')" ><h1><strong>{{phonemeDisplayDictionary['zh']}}</strong></h1></button>
                <p style="margin-left: 16px;">Gara<b style="color:#FF9333">ge</b></p>
              </div>
              <div class="col">
                <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('g')" ><h1><strong>{{phonemeDisplayDictionary['g']}}</strong></h1></button>
                <p style="margin-left: 23px;"><b style="color:#FF9333">G</b>et</p>
              </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('h')" ><h1><strong>{{phonemeDisplayDictionary['h']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">H</b>and</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('m')" ><h1><strong>{{phonemeDisplayDictionary['m']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">M</b>ove</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('n')" ><h1><strong>{{phonemeDisplayDictionary['n']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">N</b>ice</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('ng')" ><h1><strong>{{phonemeDisplayDictionary['ng']}}</strong></h1></button>
              <p style="margin-left: 18px;">Ki<b style="color:#FF9333">ng</b></p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('r')" ><h1><strong>{{phonemeDisplayDictionary['r']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">R</b>hyme</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('l')" ><h1><strong>{{phonemeDisplayDictionary['l']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">L</b>augh</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('w')" ><h1><strong>{{phonemeDisplayDictionary['w']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">W</b>here</p>
            </div>
            <div class="col">
              <button class="btn btn-success" style="width:80px; height: 80px" type="button" @click="makeSound('y')" ><h1><strong>{{phonemeDisplayDictionary['y']}}</strong></h1></button>
              <p style="margin-left: 18px;"><b style="color:#FF9333">Y</b>ard</p>
            </div>
          </div>
        </div>
      </div>

        <div class="modal fade" id="basic_module_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">IPA Training</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close_modal()"></button>
              </div>
              <div class="modal-body">
                <Basic_exercise_popup v-if="exForm_list.length > 0"
                  :exForm_list="exForm_list"
                  :key="componentKey"
                  @closeDialog="close_modal">
                </Basic_exercise_popup>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import prePlansImg from '@/assets/images/prePlans.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import {getData, setData, getUserDataDir } from '@/firebase/index'
import {find_n_random_words_containing, find_n_random_words_containing_phonemes} from '@/media_input/words'
import constants from '@/utils/constants'

import {Create_Phoneme_Exercise_List} from '@/utils/session/functions/exercise_creation'
import {logActivity,getTodayString, getWeekNumber} from '@/media_input/utils'
import Basic_exercise_popup from './components/basic_exercise_module_popup.vue'
import { Modal } from 'bootstrap'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'Ipa',
  components: {Basic_exercise_popup, Navbar},
  data() {
    return { 
      user_plan_ref: null,
      user_plan: null,
      base_module_path:'BaseUserPlan/',
      plan_name: '',
      module_path: 'BaseUserPlan/PD_Intensive_EN/',
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      prePlansImg: prePlansImg,
      planImg: planImg,
      warnImg: warnImg,
      addImg: addImg,
      backImg:backImg,
      prePlans: [],
      isTemplate: false,
      newPart: false,
      menuVisible: false,
      sendEmailDialog: false,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      homeImg:homeImg,
      form: 
        {
            n_item:50,
            value:'',
            phoneme: null,
            day: null,
            changes_confirm: false,
            pre_loaded_exercises: null
        }, 
      planName: '',
      percent_total: 0,
      percent_week:25,
      totalColor:'#f56c6c',
      weekColor:'#6f7ad3',
      tableData: [],
      currentRow: null,
      phoneme_exercise_list:[],
      exForm_list: [],
      basic_module_on: false,
      exForm: null,
      current_exercise_index:0,
      componentKey:0,
      target_phoneme: '',
      moduleDialog:true,
      phonemeDisplayDictionaryExerciseConfig: null,
      phonemeDisplayDictionary: null,
      training_flag: false,
      start_object: {
        module_path:'', 
        assessment_type: "USER_TRAINING", 
        module_name: '', 
        entry:''
      },
      autoGenWords:[],
      pre_loaded_exercises:[],
      mod_name:'',
      startingText:'',
      userCategory: '',
      basic_module_modal: ''
    }
  },
  created() {
    let _this = this;

    // console.debug('constants.phonemeDisplayDictionary = ' + constants.phonemeDisplayDictionary)
    _this.userCategory = localStorage.getItem("userCategory");

    logActivity( 'accessed', 'IPA')
    _this.phonemeDisplayDictionary = constants.phonemeDisplayDictionary
    _this.phonemeDisplayDictionaryExerciseConfig = constants.phonemeDisplayDictionary

    _this.form.phoneme = null
    _this.entry = _this.$route.params.entry;

    if(_this.entry==undefined || _this.entry==null || _this.entry=='')
    {
      _this.entry = 'sound'
    }

    if(_this.entry == 'sound')
    {
      this.startingText = 'Choose a phoneme/sound below to practise its pronunciation with videos.';
    }
    else if(_this.entry == 'word')
    {
      this.startingText = 'Choose a sound to be included in the words for practise.'
    }

  },
  methods: {
    makeSound(sound)
    {
        let _this = this;
        // alert("Play sound "+sound);
        _this.training_flag = false;
        if(_this.entry == 'sound')
        {
            _this.searchExamplesWithPhoneme(sound);
        }
        else if(_this.entry == 'word')
        {
            _this.autoGenerate(sound);
        }
    },
    autoGenerate(searchKey)
    {
      let _this = this;
      //   this.showExercise = true;
      //   console.debug('this.searchKey = ' + this.searchKey)

      searchKey = _this.phonemeDisplayDictionaryExerciseConfig[searchKey]

      let word_list = find_n_random_words_containing_phonemes(_this.form.n_item,searchKey)
      //   console.debug('drop selection= '+this.value);
      // console.debug(word_list)
      if(word_list.length<1)
      {
        alert('No words were found containing these letters');
        this.autoGenWords = [];
      }
      else
      {
        this.autoGenWords = word_list;
      }


      //   this.words_form.text = this.autoGenWords;
      if(this.autoGenWords.length>0)
      {
        _this.localStoreNewWordsPlan()

        _this.form.pre_loaded_exercises = _this.pre_loaded_exercises

        console.debug('_this.form.pre_loaded_exercises = ' + _this.form.pre_loaded_exercises.length)
        _this.form.changes_confirm = true
        
        if(_this.form.pre_loaded_exercises != undefined && _this.form.pre_loaded_exercises!=null && _this.form.pre_loaded_exercises.length>0)
        {
          _this.start_object.module_name = 'Word List'
          _this.start_object.pre_loaded_exercises = _this.form.pre_loaded_exercises


          _this.start_object.entry = 'ipa'

          _this.$router.push({ name: 'Session',params: {start_object: _this.start_object}})

          return
        }
      }
      
    },
    localStoreNewWordsPlan()
    {
      const _this  = this;
        
      
      let mod_name = ''
      for(let i in _this.autoGenWords)
    {
    
        mod_name = mod_name  + _this.autoGenWords[i] + '/'

    }
        
        // _this.form.n_item = 50;
        
        

        _this.pre_loaded_exercises = []

        // console.debug('form text all= ',_this.form.text[1]);
        
        for(let n=0; n<_this.form.n_item;n++)
        {

          let form_temp = {
            text: mod_name,
            instruct: 'Press Start. Read the following word. Press Next.',
            name:mod_name,
            assist:['spm_live'],
            voicetype: ['spm'],
            speechtype:['intelligibility'],
            module_name: 'Word list',
            curr_exercise_path: 'word_list/',
            assessment_type: 'USER_TRAINING'
          };
        
          // form_temp.text = [];
          let text_temp = _this.autoGenWords[n];

          form_temp.text = text_temp;
          form_temp.name = _this.mod_name+'-'+n;

          _this.pre_loaded_exercises.push(form_temp)    
          
        }

      
    },
    searchExamplesWithPhoneme(phoneme)
    {
        var _this = this

        var phoneme_list = [phoneme] 

        const words_per_phoneme = 0 //all words



        _this.exForm_list = []
        Create_Phoneme_Exercise_List(phoneme_list, words_per_phoneme).then(
            function(exForm_list)
            {
                console.debug('exForm_list = ' + exForm_list.length)
                _this.exForm_list = [...exForm_list]

                _this.basic_module_modal = new Modal(document.getElementById('basic_module_modal'));
                _this.basic_module_modal.show()
            }
        )

    },
    temp(phoneme)
    {



      var _this = this;
      var do_once = true;
      let today = getTodayString()
     
     _this.exForm_list = []

      _this.target_phoneme = phoneme;
      let targetDir = 'Exercises/Articulation/'

      

      _this.phoneme_exercise_list = []

      getData(targetDir , function (err, result) {

          //Inputs new exercise on the current user's database

        if (do_once) {


          do_once = false;

          result.child('Consonants').forEach(function(childSnapshot)
          {

            // console.debug(' phoneme = ' + phoneme)
            // console.debug(' childSnapshot.key = ' + childSnapshot.key.toUpperCase())


            if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
            {


                let exercise_address = targetDir + childSnapshot.key 

                let exForm = childSnapshot.val()

                exForm.instruct = null
                exForm.text = 'Follow the video instruction, practise phoneme ' + _this.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]
                exForm.curr_exercise_path = childSnapshot.key 
                exForm.today = today
                exForm.module_name = 'IPA_'+ childSnapshot.key
                exForm.assessment_type = "USER_TRAINING"

                _this.exForm_list.push(exForm)

                let curr_exercise = {exercise_address: exercise_address, exerciseSnapshot: childSnapshot, type: 'Consonant'}
                
                
                _this.phoneme_exercise_list.push(curr_exercise)

            }
          });

          result.child('Vowels').forEach(function(childSnapshot)
          {

            // console.debug(' phoneme = ' + phoneme)
            // console.debug(' childSnapshot.key = ' + childSnapshot.key.toUpperCase())


            if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
            {


                let exercise_address = targetDir + childSnapshot.key 


                let exForm = childSnapshot.val()

                exForm.instruct = null
                exForm.text = 'Follow the video instruction, practise phoneme ' + _this.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]
                exForm.curr_exercise_path = childSnapshot.key 
                exForm.today = today
                exForm.module_name = 'IPA_'+ childSnapshot.key
                exForm.assessment_type = "USER_TRAINING"

                _this.exForm_list.push(exForm)

                let curr_exercise = {exercise_address: exercise_address, exerciseSnapshot: childSnapshot, type: 'Vowel'}
                
                _this.phoneme_exercise_list.push(curr_exercise)

            }
          });

          result.child('Words').forEach(function(childSnapshot)
          {
            if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
            {
              childSnapshot.forEach(function(exerciseSnapshot)
              {

                let exForm = exerciseSnapshot.val()

                exForm.text = 'Press Start, practise phoneme /' + _this.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()] + '/ in word: ' + exForm.text
                exForm.instruct = null
                exForm.curr_exercise_path = childSnapshot.key + "/" + exerciseSnapshot.key
                exForm.today = today
                exForm.module_name = 'IPA_'+ childSnapshot.key
                exForm.assessment_type = "USER_TRAINING"

                _this.exForm_list.push(exForm)

                let exercise_address = targetDir + childSnapshot.key + "/" + exerciseSnapshot.key

                let curr_exercise = {exercise_address: exercise_address, exerciseSnapshot: exerciseSnapshot, type: 'Word'}
                
                _this.phoneme_exercise_list.push(curr_exercise)

              });
            }
          });

          if(_this.phoneme_exercise_list.length>0)
          {

              _this.exForm = _this.exForm_list[0]

            //  console.debug('_this.exForm ')
              _this.basic_module_on.show()
              _this.current_exercise_index = 0


            // _this.training_flag = true
          }
          else
          {
            alert("No exercises available for phoneme /" + phoneme + "/ yet. The developers will be adding it as soon as possible.")
          }

        }
      });

    },
    close_modal() {
      
      this.exForm_list = []
      this.basic_module_modal.hide()
      //this is for stopping the audio playback
      var player = document.getElementById('movie');
      player.pause();
      /////////////////////
    },
    checkProgress()
    {
        this.$router.push({ name: 'detailedProgress', params: {tableData: this.tableData}});
    },

  },
}
</script>
<style scoped>

</style>