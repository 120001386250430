<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
        <div class="row mt-2">
      <div class="card card-body"  style="border-color: blueviolet;">
        <div class="accordion" id="accordionQuestionnaires">
          <div class="accordion-item mb-3">
            <div class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#questionnaires_collapse" aria-expanded="false" aria-controls="questionnaires_collapse"><p class="text-start" >
                <b>Swal-Qol</b></p></button>
            </div>
            <div class="accordion-collapse collapse" id="questionnaires_collapse" data-bs-parent="#accordionQuestionnaires">

          <div class="row mb-2">

            <div v-for="questionnaire, i of table">
            <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

              <p class="text-start" v-if="'Questionnaire_Name' in table[i]" ><b>Questionnaire {{ i +1}} - {{ table[i]['Questionnaire_Name'] }}</b></p>
              <p class="text-start" v-else ><b>Questionnaire {{ i +1}} </b></p>

              <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

              <div v-for="row, index in table[i]['Data']" v-bind:key="index">
                  
                  <div v-if="row['question_type']=='A'">

                    {{ row['instruct']}}
                    <table class="table" style="table-layout: fixed">
                    <thead>
                        <tr>
                          <th></th>
                          <th v-for="option, index2 in row['radio_options']" v-bind:key="index2">{{ option['label'] }}</th>
                    

                        </tr>
                      </thead>

                  
                    <tbody>
                        <tr v-for="question, index3 in row['qs']" v-bind:key="index3">

                        <td>{{ question }}</td>

                        <th v-for="option, index2 in row['radio_options']" v-bind:key="index2">
                          <h4 v-if="(index2+1)==row['selections'][index3]"> X</h4>
                          </th>



                        </tr>
                      

                      </tbody>
                    </table>
                  </div>
                  <div v-if="row['question_type']=='B'">

                  {{ row['instruct']}}  


                    <h6> {{ row['qs'][Math.max(row['selections'].indexOf(true),0)]['value'] }} - {{ row['qs'][Math.max(row['selections'].indexOf(true),0)]['label'] }}</h6>

                  </div>

                  <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />

                </div>


            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Ddk',
  props: ['table'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





