import {getData} from "../firebase"
import {keyCrawler,child_key_value_Crawler} from '@/utils/crawlers.js'
import constants from '@/utils/constants'

import { roundNumber } from "@/utils/utils"
import {phonemeToWord} from '@/utils/utils'
import { over, round } from "lodash"



export async function generateAssessmentReport(results_data_assessment)
{
  let selected_data_assessment = select_data(results_data_assessment)

  let combined_selected_data = [selected_data_assessment]

  let all_outputs = await extract_results(combined_selected_data)

  return all_outputs
}

export async function generateReport(results_data_training,results_data_assessment,selected_date)
{

  let selected_data_training = select_data_from_date(results_data_training,selected_date)
  let selected_data_assessment = select_data_from_date(results_data_assessment,selected_date)

  let combined_selected_data = [selected_data_training,selected_data_assessment]

  let all_outputs = await extract_results(combined_selected_data)

  all_outputs.overview = {date:selected_date}

  return all_outputs
}

export function getDataAndDates(results_directory)
{

  let output = new Promise(function(resolve)
  {

    getData(results_directory,function (err, results_data) {
      let all_dates = findAllDates(results_data)

      let output = {all_dates:all_dates, data:results_data}

      resolve(output)

    })
  })

  return output

}

export function findAllDates(results_data)
{
  let all_dates = []


  results_data.forEach(function(single_results)
  {
    let date_time = single_results.key

    let split_date = date_time.split(' ')

    let date = split_date[0]

    if(date_time.includes('SLP') || date_time.includes('USER'))
    {

    }
    else if(!(all_dates.includes(date)))
    {
      all_dates.push(date)
    }
  })

  let orderedDates = all_dates.sort(function(a,b){
    return Date.parse(a) > Date.parse(b);
  });

  let orderedDates2 = []

  for(let n=(orderedDates.length-1);n>=0;--n)
  {
      orderedDates2.push(orderedDates[n])
  }

  return orderedDates2

}

export function findLatestExerciseDate(results_data)
{

  let all_dates = []

  results_data.forEach(function(single_results)
  {
    let date_time = single_results.key

    let split_date = date_time.split(' ')

    let date = split_date[0]

    all_dates.push(date)
  })

  let orderedDates = all_dates.sort(function(a,b){
      return Date.parse(a) > Date.parse(b);
  });

  let latest_date = orderedDates[orderedDates.length-1]

  return latest_date
}

export function select_data(results_data)
{

  let selected_data = []

  results_data.forEach(function(single_results)
  {
    let date_time = single_results.key

    let split_date = date_time.split(' ')

    let date = split_date[0]

    selected_data.push(single_results)
  })

  return selected_data
}

export function select_data_from_date(results_data, selected_date)
{

  let selected_data = []

  results_data.forEach(function(single_results)
  {
    let date_time = single_results.key

    let split_date = date_time.split(' ')

    let date = split_date[0]

    if(date==selected_date)
    {
        selected_data.push(single_results)
    }
  })

  return selected_data
}

export async function extract_results(combined_selected_data)
{

  let all_outputs = {}

  let picture_naming  = extract_picture_naming(combined_selected_data)
  all_outputs.picture_naming = picture_naming

  let token_assessment  = extract_token_assessment(combined_selected_data)
  all_outputs.token_assessment = token_assessment

  let ddk_rate  = extract_ddk_rate(combined_selected_data)
  all_outputs.ddk_rate = ddk_rate

  let pitch_variation  = extract_pitch_variation(combined_selected_data)
  all_outputs.pitch_variation = pitch_variation
  
  let phonation  = extract_phonation(combined_selected_data)
  all_outputs.phonation = phonation

  let sentence_intelligibility = extract_sentence_intelligibility(combined_selected_data)
  all_outputs.sentence_intelligibility = sentence_intelligibility


  let intelligibility = extract_intelligibility(combined_selected_data)
  all_outputs.intelligibility = intelligibility

  let word_finding = extract_word_finding(combined_selected_data)
  all_outputs.word_finding = word_finding

  let memory_image = extract_memory_image(combined_selected_data)
  all_outputs.memory_image = memory_image

  
  let written_words_comprehension = extract_written_words_comprehension(combined_selected_data)
  all_outputs.written_words_comprehension = written_words_comprehension


  
  let spoken_words_comprehension = extract_spoken_words_comprehension(combined_selected_data)
  all_outputs.spoken_words_comprehension = spoken_words_comprehension

  let semantic_memory = extract_semantic_memory(combined_selected_data)
  all_outputs.semantic_memory = semantic_memory

  let recognition_memory = extract_recognition_memory(combined_selected_data)
  all_outputs.recognition_memory = recognition_memory

  let questionnaires = await extract_questionnaires(combined_selected_data)
  if (questionnaires.length > 0) {
    all_outputs.questionnaires = questionnaires
  }


  console.debug('swal_qol!')
  let swal_qol = await extract_swal_qol(combined_selected_data)

  console.debug(swal_qol)
  if (swal_qol.length > 0) {
    all_outputs.swal_qol = swal_qol

    console.debug(all_outputs.swal_qol)

  }


  return all_outputs
}

async function extract_questionnaires(combined_selected_data) {

    let questionnaires = []
    let questionnaires_promises = []
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]
        for(let i in selected_data)
        {
            let curr_data = selected_data[i]
            let module_set_data = curr_data.val()

            for (let module_data_key of Object.keys(module_set_data)) {
              let module_data = module_set_data[module_data_key]
              
              if ('exercises' in module_data) {
                for (const [key, value] of Object.entries(module_data['exercises'])) {
                  if ('resultsTablePre' in value && 'Questionnaire' === value['resultsTablePre']['exercise_type']) {
                        let questionnaire_data = value['resultsTablePre']['results']

                        let answers = questionnaire_data['chosen_options']

                        let output = new Promise(function(resolve)
                        {
                            getData(questionnaire_data['questionnaire_address'],function (err, rt) {
                                let rt_question_data = rt.val()
                                
                                let questions = rt_question_data['Ex'].splice(0, answers.length)
                                let questionnaire = {
                                    //z_score: questionnaire_data['z_score'],
                                    // z_score_meaning: questionnaire_data['z_score_meaning'],
                                    answers: answers,
                                    questions: questions
                                }

                                for(let k in questionnaire_data)
                                {
                                    questionnaire[k] = questionnaire_data[k]
                                }
                                resolve(questionnaire)
                            })
                        })

                        questionnaires_promises.push(output)
                    }
                }
              }
            }
        }
    }
    
    let values = await Promise.all(questionnaires_promises)

    for (let value of values) {
      questionnaires.push(value)
    }

    console.log(questionnaires)
    return questionnaires
}


async function extract_swal_qol(combined_selected_data) {

    let questionnaires = []
    console.debug('here 1')
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]
        for(let i in selected_data)
        {
            console.debug('here 2')

            let curr_data = selected_data[i]
            let module_set_data = curr_data.val()

            for (let module_data_key of Object.keys(module_set_data)) {

                console.debug('here 3')

              let module_data = module_set_data[module_data_key]
              
              if ('exercises' in module_data) {
                console.debug('here 4')

                for (const [key, value] of Object.entries(module_data['exercises'])) {
                    console.debug('here 5')

                    if ('resultsTablePre' in value && 'Swal-Qol' === value['resultsTablePre']['exercise_type']) {

                        console.debug('here 6')

                        let output = value['resultsTablePre']['results']

                        questionnaires.push(output)

                    }
                  

                    
                }
              }
            }
        }
    }
    
    console.log(questionnaires)
    return questionnaires
}


function extract_sentence_intelligibility(combined_selected_data)
{

  let sentence_intelligibility_individual_output = []

  let assessment_date = ''

  let word_and_score_list = []

  let total_number_of_spoken_words = 0

  const max_syllable_count = 4

  let score_by_syllable_count = []

  for(let n=0;n<max_syllable_count;++n)
  {
    score_by_syllable_count.push([])
  }

  for(let p in combined_selected_data)
  {
    let selected_data = combined_selected_data[p]
    
    for(let i in selected_data)
    {
      
      let curr_data = selected_data[i]

      let all_sentence_intelligibility_data = []


      keyCrawler(curr_data, 'sentencegibility', all_sentence_intelligibility_data)

      let date = curr_data.key

      let time = date.split(' ')

      assessment_date = time[0]

      if(time.length>1)
      {
        time = time[1]
      }

      let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      let sentence_intelligibility_grading = constants.sentence_intelligibility_grading_strict

      if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
      {
    
        sentence_intelligibility_grading = constants.sentence_intelligibility_grading_encouragement
    
      }
    

      for(let n in all_sentence_intelligibility_data)
      {

        let curr_parent_results = all_sentence_intelligibility_data[n].val()

        let AudioAddress = curr_parent_results['audio_address']
        let loudness = curr_parent_results['loudness']['loudness']
        loudness = roundNumber(loudness, 0)
        let max_loudness = curr_parent_results['loudness']['max_loudness']
        max_loudness = roundNumber(max_loudness, 0)

        let curr_results = curr_parent_results.sentencegibility

        let ground_truth = curr_results['reference_text']

        let accuracy = roundNumber(curr_results['accuracy_score'],0)

        let n_words = curr_results['Words'].length;


        var word_quality = []
        var words = []
        var word_grade = []

        let sentence_intelligibility_info = []

        for(let k=0;k<n_words;++k)
        { 
            
            let error_type = curr_results['Words'][k]['error_type'];

            if(error_type!="Insertion")
            {
                total_number_of_spoken_words = total_number_of_spoken_words + 1
                let curr_word = curr_results['Words'][k]['word'];
                let curr_word_quality = curr_results['Words'][k]['accuracy_score'];
                
                let syllable_count = 1
                score_by_syllable_count[syllable_count-1].push(curr_word_quality)

                let curr_word_grade = 'Very Good'


                for(let m in sentence_intelligibility_grading)
                {
                    if(curr_word_quality>=sentence_intelligibility_grading[m]['min'] && curr_word_quality<sentence_intelligibility_grading[m]['max'])
                    {
                        let curr_word_intelligibility_info = sentence_intelligibility_grading[m]
                        curr_word_grade = sentence_intelligibility_grading['grade']
                        
                        sentence_intelligibility_info.push(curr_word_intelligibility_info)

                        break
            
                    }
                }
          
          

                word_quality.push(curr_word_quality)
                word_grade.push(curr_word_grade)
                words.push(curr_word)

                word_and_score_list.push({'word':curr_word,'word_score':curr_word_quality})

            }

        }


        let result_object = {
            AudioAddress: AudioAddress, 
            time: time, 
            name: ground_truth, 
            loudness: loudness, 
            max_loudness: max_loudness,
            accuracy: accuracy, 
            WordQuality: word_quality, 
            Words: words, 
            WordGrade: word_grade,
            sentence_intelligibility_info: sentence_intelligibility_info
        }

        sentence_intelligibility_individual_output.push(result_object)
          
      }

    }
  }


  function compare_word( a, b ) {
  if ( a.word_score < b.word_score ){
      return -1;
  }
  if ( a.word_score > b.word_score ){
      return 1;
  }
  return 0;
  }

  let average_syllable_count_score = []

  for(let n in score_by_syllable_count)
  {
      let curr_average = 0
      for(let k in score_by_syllable_count[n])
      {
          curr_average+=score_by_syllable_count[n][k]

      }

      if(score_by_syllable_count[n].length>0)
      {
          curr_average = curr_average/score_by_syllable_count[n].length
      }

      average_syllable_count_score.push(roundNumber(curr_average,0))
  }


  word_and_score_list.sort(compare_word)

  let words_2_work_on = null

  for(let i=0;i<Math.min(3,word_and_score_list.length);++i)
  {
      if(word_and_score_list[i].word_score>95)
      {
          break;
      }
      else
      {
          if(words_2_work_on!=null)
          {
              words_2_work_on = words_2_work_on + ', ' + word_and_score_list[i].word

          }
          else{
              words_2_work_on = word_and_score_list[i].word

          }
      }
  }


  let time_overview = getActivityStartEndTime(sentence_intelligibility_individual_output)

  let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end,words_2_work_on:words_2_work_on,total_number_of_spoken_words:total_number_of_spoken_words,average_syllable_count_score:average_syllable_count_score}

  let sentence_intelligibility_output = {overview:overview,individual_outputs:sentence_intelligibility_individual_output}


  return sentence_intelligibility_output
}

export function extract_intelligibility(combined_selected_data)
{

    let intelligibility_individual_output = []

    let assessment_date = ''

    let phoneme_list = []

    let phonemeVowelList = constants.phonemeVowelList

    let phonemeDisplayDictionary = constants.phonemeDisplayDictionary

    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]
            
        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_intelligibility_data = []


            keyCrawler(curr_data,'intelligibility',all_intelligibility_data)

            let date = curr_data.key

            let time = date.split(' ')

            assessment_date = time[0]


            if(time.length>1)
            {
                time = time[1]
            }


            for(let n in all_intelligibility_data)
            {

                let curr_parent_results = all_intelligibility_data[n].val()

                let AudioAddress = curr_parent_results['audio_address']

                let loudness = curr_parent_results['loudness']['loudness']
                loudness = roundNumber(loudness,0)

                let max_loudness = curr_parent_results['loudness']['max_loudness']
                max_loudness = roundNumber(max_loudness,0)
  
                let curr_results = curr_parent_results.intelligibility

                let name = curr_results['reference_text']

                let accuracy = curr_results['accuracy_score']


                let phonemes = curr_results['Phonemes']

                let all_phonemes = []
                var phoneme_quality = []
                var phoneme_grade = []
                var phonemes_display = []
                let phoneme_intelligibility_info = []

                let total_phoneme_quality_score = 0

                let n_phonemes = phonemes.length

                for(let k=0;k<n_phonemes;++k)
                {
                    let curr_phoneme_data = phonemes[k]

                    let curr_phoneme = curr_phoneme_data['Phoneme']

                    curr_phoneme = curr_phoneme.split('"').join('');


                    let curr_phoneme_quality = curr_phoneme_data['PronunciationAssessment']['AccuracyScore']

                    if(phonemeVowelList.includes(curr_phoneme))
                    {
                        var correction_factor = 1
                        if(curr_phoneme_quality<60)
                        {
                          correction_factor = 1.2
                        }
                        else
                        {
                          correction_factor = 1.1
                        }
                        curr_phoneme_quality = Math.min(100,curr_phoneme_quality*correction_factor)
                    }




                    let region = localStorage.getItem('region')


                    if(region==undefined || region==null || region==='UK')
                    {  
                        var curr_phoneme_UK = curr_phoneme

                     //Only for UK - check if the 'r' phoneme should be replaced or deleted, XXX region variable to be created
                      if((k == n_phonemes-1)&& (curr_phoneme_UK == 'r')&& (n_phonemes>1)) 
                      {

                        var second_last_phoneme = phonemes[n_phonemes-2]['Phoneme']
                        second_last_phoneme = second_last_phoneme.split('"').join('');
              
                        if((second_last_phoneme == 'ax') || (second_last_phoneme == 'aa')|| (second_last_phoneme == 'er'))
                        {
                          n_phonemes = n_phonemes - 1
                          break
                        }
                        else
                        {
                          curr_phoneme_UK = 'ax'
                        }
                      }
                    }
      
                    total_phoneme_quality_score = total_phoneme_quality_score + curr_phoneme_quality

                    let intelligibility_grading_selection = localStorage.getItem('intelligibility_grading_selection')

      
                    let intelligibility_grading = constants.intelligibility_grading_strict
              
                    if(intelligibility_grading_selection!=undefined && intelligibility_grading_selection!=null && intelligibility_grading_selection==='encouragement')
                    {
  
                      intelligibility_grading = constants.intelligibility_grading_encouragement
              
                    }
                  
              
                    let intelligibility_info = intelligibility_grading[intelligibility_grading.length-1]
                    let curr_phoneme_grade = 'Very Good'
            
                    for(let m in intelligibility_grading)
                    {
                        if(curr_phoneme_quality>=intelligibility_grading[m]['min'] && curr_phoneme_quality<intelligibility_grading[m]['max'])
                        {
                            intelligibility_info = intelligibility_grading[m]
                            curr_phoneme_grade = intelligibility_info['grade']
                
                            break
                
                        }
                    }
      
      
                    phoneme_quality.push(curr_phoneme_quality)
                    phoneme_grade.push(curr_phoneme_grade)
                    all_phonemes.push(curr_phoneme_data['Phoneme'])
                    phoneme_intelligibility_info.push(intelligibility_info)

                    var phoneme_display = curr_phoneme_UK
                    let IPA_Phoneme_Representation = true
              
              
                    if(curr_phoneme_UK in constants.phonemeDisplayDictionary && IPA_Phoneme_Representation)
                    {
                      phoneme_display = constants.phonemeDisplayDictionary[curr_phoneme_UK]
                    }
                    else
                    {
                      console.debug('phoneme display not found: ' + curr_phoneme_UK)
                    }
                    phonemes_display.push(phoneme_display)
              
                 

                    phoneme_list.push({phoneme:curr_phoneme,phoneme_score:curr_phoneme_quality,phoneme_grade:phoneme_grade})

                }

                accuracy = Math.round(total_phoneme_quality_score/n_phonemes)


                let ground_truth = name
                let [pho_w,pho_word_pos] = phonemeToWord(ground_truth,all_phonemes)

                let pho_word_grade = [];
                let phoneme_word_intelligibility_info = [];

                for(let nn=0;nn<pho_w.length;nn++)
                {
                  pho_word_grade.push(phoneme_grade[pho_word_pos[nn]])
                  phoneme_word_intelligibility_info.push(phoneme_intelligibility_info[pho_word_pos[nn]])

                }    

                let phoWord_display = []

                if(ground_truth!=undefined)
                {
                    phoWord_display = ground_truth.split('')

                    // console.log('phoneme_intelligibility_info = ' + phoneme_intelligibility_info)
                    // console.log('phoneme_word_intelligibility_info = ' + phoneme_word_intelligibility_info)

                    // console.log('here')
                    let result_object = {
                        AudioAddress: AudioAddress, 
                        time: time,
                        loudness: loudness,
                        max_loudness: max_loudness,
                        name: name,
                        accuracy: accuracy,
                        phonemes: phonemes,
                        PhonemeQuality: phoneme_quality, 
                        Phoneme: phonemes, 
                        PhoWordGrade: pho_word_grade, 
                        phoWord_display: phoWord_display,
                        PhonemeGrade: phoneme_grade, 
                        phonemes_display: phonemes_display,
                        phoneme_word_intelligibility_info: phoneme_word_intelligibility_info,
                        phoneme_intelligibility_info: phoneme_intelligibility_info
                    }

                    intelligibility_individual_output.push(result_object)

                }
            }

        }
    }

    let time_overview = getActivityStartEndTime(intelligibility_individual_output)

    function compare_phoneme( a, b ) {
        if ( a.phoneme_score < b.phoneme_score ){
          return -1;
        }
        if ( a.phoneme_score > b.phoneme_score ){
          return 1;
        }
        return 0;
      }


    phoneme_list.sort(compare_phoneme)

    let phonemes_2_work_on = null

    for(let i=0;i<Math.min(3,phoneme_list.length);++i)
    {
        if(phoneme_list[i].phoneme_score>95)
        {
            break;
        }
        else
        {
            if(phonemes_2_work_on!=null)
            {
                phonemes_2_work_on = phonemes_2_work_on + ', ' + phoneme_list[i].phoneme
            }
            else
            {
                phonemes_2_work_on = phoneme_list[i].phoneme

            }
        }
    }



    let overview = {phonemes_2_work_on:phonemes_2_work_on,date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end}

    let intelligibility_output = {overview:overview,individual_outputs:intelligibility_individual_output}


    return intelligibility_output
}


export function extract_phonation(combined_selected_data)
{

    let phonation_individual_output = []

    let assessment_date = ''


    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_phonation = []


            keyCrawler(curr_data,'max_phonation_dictionary',all_phonation)

            let date = curr_data.key

            let time = date.split(' ')

            assessment_date = time[0]


            if(time.length>1)
            {
                time = time[1]
            }


            for(let n in all_phonation)
            {

                let name = all_phonation[n].child('name').val()
                let curr_parent_results = all_phonation[n].val()

                let AudioAddress = curr_parent_results['audio_address']


                let loudness = 0
                let max_loudness = 0
                if('loudness' in curr_parent_results)
                {
                    loudness = curr_parent_results['loudness']['loudness']
                    loudness = roundNumber(loudness,0)

                    if ('max_loudness' in curr_parent_results['loudness']) {
                        max_loudness = roundNumber(curr_parent_results['loudness']['max_loudness'], 0)
                    }

                }


                let max_phonation = curr_parent_results['max_phonation_dictionary'].max_phonation

                max_phonation = roundNumber(max_phonation,1)

                let result_object = {
                    AudioAddress: AudioAddress, 
                    time: time,
                    name: name,
                    max_phonation: max_phonation,
                    loudness: loudness,
                    max_loudness: max_loudness
                }

                phonation_individual_output.push(result_object)

            }

        }
    }

    let time_overview = getActivityStartEndTime(phonation_individual_output)



    let total_exercises = 0
    let average_duration = 0
    let lowest_duration = 100
    let highest_duration = 0

    let average_loudness = 0 
    let average_max_loudness = 0 

    for(let k in phonation_individual_output)
    {
        let max_phonation = phonation_individual_output[k].max_phonation

        average_duration = average_duration+max_phonation
        total_exercises  = total_exercises+1

        highest_duration = Math.max(highest_duration,max_phonation)
        lowest_duration = Math.min(lowest_duration,max_phonation)

        average_loudness = average_loudness + phonation_individual_output[k].loudness
        average_max_loudness = average_max_loudness + phonation_individual_output[k].max_loudness

    }

    if(total_exercises>0)
    {
        average_duration = average_duration/total_exercises
        average_loudness = average_loudness/total_exercises
        average_max_loudness = average_max_loudness/total_exercises
    }

    

    average_duration = roundNumber(average_duration,0)
    average_loudness = roundNumber(average_loudness,0)
    average_max_loudness = roundNumber(average_max_loudness,0)


    let overview = {
        date: assessment_date,
        time_start: time_overview.time_start,
        time_end: time_overview.time_end,
        average_duration: average_duration,
        highest_duration: highest_duration,
        lowest_duration: lowest_duration,
        total_exercises: total_exercises,
        average_loudness: average_loudness,
        average_max_loudness: average_max_loudness
    }

    let phonation_output = {overview:overview,individual_outputs:phonation_individual_output}

    console.debug('phonation_individual_output.length = ' + phonation_individual_output.length)
    return phonation_output
}


export function extract_pitch_variation(combined_selected_data)
{

    console.debug('extract_pitch_variation')

    let pitch_variation_individual_output = []

    let assessment_date = ''


    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]


            let all_pitch_variation = []



            keyCrawler(curr_data,'resultsTablePre',all_pitch_variation)
            console.debug('all_pitch_variation = ' + all_pitch_variation)



            for(let n in all_pitch_variation)
            {

                let curr_pitch_variation_candidate = all_pitch_variation[n].val()

                let AudioAddress = curr_pitch_variation_candidate['audio_address']

                if('exercise_type' in curr_pitch_variation_candidate['resultsTablePre'] && 'Pitch Range' === curr_pitch_variation_candidate['resultsTablePre']['exercise_type'])
                {

                    
                    let curr_results = curr_pitch_variation_candidate['resultsTablePre']


                    let name = curr_pitch_variation_candidate['name']

                        
                    let date = curr_data.key

                    let time = date.split(' ')

                    assessment_date = time[0]


                    if(time.length>1)
                    {
                        time = time[1]
                    }

                    if('results' in curr_results)
                    {


                            
                        let results = curr_results.results
                        console.debug('results = ' + results)


                        let freq_range = results.freq_range
                        let max_freq = results.max_freq
                        let min_freq = results.min_freq
                        let mean_freq = results.mean_freq
                        let loudness = results.loudness
                        let max_loudness = results.max_loudness

                        freq_range = roundNumber(freq_range,0)
                        max_freq = roundNumber(max_freq,0)
                        min_freq = roundNumber(min_freq,0)
                        mean_freq = roundNumber(mean_freq,0)
                        loudness = roundNumber(loudness,0)
                        max_loudness = roundNumber(max_loudness, 0)


                        let result_object = {
                            AudioAddress: AudioAddress, 
                            time: time,
                            name: name,
                            loudness: loudness,
                            max_loudness: max_loudness, 
                            freq_range: freq_range,
                            max_freq: max_freq,
                            min_freq: min_freq,
                            mean_freq: mean_freq}

                        pitch_variation_individual_output.push(result_object)

                    }
                }
                
            }

        }
    }

    let time_overview = getActivityStartEndTime(pitch_variation_individual_output)


    let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end}

    let pitch_variation_output = {overview:overview,individual_outputs:pitch_variation_individual_output}


    console.debug('pitch_variation_individual_output.length = ' + pitch_variation_individual_output.length)
    return pitch_variation_output
}


export function extract_ddk_rate(combined_selected_data)
{

    let ddk_individual_output = []

    let assessment_date = ''


    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_ddk_rate = []


            keyCrawler(curr_data,'ddk_rate_dictionary',all_ddk_rate)

            let date = curr_data.key

            let time = date.split(' ')

            assessment_date = time[0]


            if(time.length>1)
            {
                time = time[1]
            }


            for(let n in all_ddk_rate)
            {


                let curr_parent_results = all_ddk_rate[n].val()
                let AudioAddress = curr_parent_results['audio_address']

                let loudness = curr_parent_results['loudness']['loudness']
                loudness = roundNumber(loudness,0)

                let max_loudness = 0
                if ('max_loudness' in curr_parent_results['loudness']) {
                    let max_loudness = curr_parent_results['loudness']['max_loudness']
                    max_loudness = roundNumber(max_loudness,0)
                }


                let name = curr_parent_results.name
                curr_parent_results = curr_parent_results.ddk_rate_dictionary

                let syllable_counter = curr_parent_results.syllable_counter
                let test_syllable_count = curr_parent_results.test_syllable_count
                let test_duration = curr_parent_results.test_duration

                let syllable_rate = syllable_counter/test_duration
                let number_repetitions = syllable_counter/test_syllable_count

                syllable_rate = roundNumber(syllable_rate,0)
                number_repetitions = roundNumber(number_repetitions,0)

                let result_object = {
                    AudioAddress: AudioAddress, 
                    time: time,
                    name: name,
                    loudness: loudness,
                    max_loudness: max_loudness,
                    syllable_rate: syllable_rate,
                    number_repetitions: number_repetitions,
                    test_duration: test_duration
                }

                ddk_individual_output.push(result_object)

            }

        }
    }

    let time_overview = getActivityStartEndTime(ddk_individual_output)


    let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end}

    let ddk_rate_output = {overview:overview,individual_outputs:ddk_individual_output}


    console.debug('ddk_individual_output.length = ' + ddk_individual_output.length)
    return ddk_rate_output
}

export function extract_picture_naming(combined_selected_data)
{

    let picture_individual_output = []
    let assessment_date = ''

    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_picture_naming = []


            child_key_value_Crawler(curr_data, 'exercise', 'Word Naming',all_picture_naming)

            let date = curr_data.key

            console.debug('all_picture_naming.length = ' + all_picture_naming.length)
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }

            for(let n in all_picture_naming)
            {

                let curr_parent_results = all_picture_naming[n].val()

                let AudioAddress = curr_parent_results['audio_address']

                let all_results = curr_parent_results.results.all_results

                let correct = all_results.Correct
                let total = all_results.Total
                let incorrect = all_results.Incorrect

                let show_options = false
                let sound_hints = false
                let show_answer = false

                if('Show_Options' in all_results)
                {
                    show_options = all_results.Show_Options
                }

                if('Show_Answer' in all_results)
                {
                    show_answer = all_results.Show_Answer
                }

                
                if('Sound_Hints' in all_results)
                {
                    sound_hints = all_results.Sound_Hints
                }

                let result_object = {AudioAddress:AudioAddress, difficulty:curr_parent_results.difficulty,category:curr_parent_results.category,word_type:curr_parent_results.word_type,word:curr_parent_results.word,correct:correct,total:total,incorrect:incorrect,sound_hints:sound_hints,show_answer:show_answer,show_options:show_options,time:time}

                picture_individual_output.push(result_object)


    
            }


        }
    }

    let time_overview = getActivityStartEndTime(picture_individual_output)

    let correct_overview = computeAverageCorrect(picture_individual_output)

    let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end,correct:correct_overview.correct,incorrect:correct_overview.incorrect,total:correct_overview.total}

    let picture_naming_output = {overview:overview,individual_outputs:picture_individual_output}



    return picture_naming_output
}


export function extract_written_words_comprehension(combined_selected_data)
{


    let written_words_comprehension_individual_output = []
    let assessment_date = ''


    let all_average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Self Correction':0,'Delay':0,'Phonological Distractor':0,'Target':0,'Semantic Distractor':0, 'Unrelated Distractor':0,'Total Selection':0}

    let all_phonological_distractors_errors = {'3df':0,'2df':0,'1df':0,'I':0,'F':0,'total':0}



    
    for(let p in combined_selected_data)
    {


        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {


            let curr_data = selected_data[i]

            let all_data = []

            child_key_value_Crawler(curr_data,'exercise','written_words_comprehension',all_data)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }

            for(let n in all_data)
            {

                let written_words_comprehension_results = []
                keyCrawler(all_data[n],'results',written_words_comprehension_results)


                for(let k in written_words_comprehension_results)
                {


                    let curr_results = written_words_comprehension_results[k].val()

                    let curr_written_words_comprehension_result = {}

                    if('results' in curr_results && 'written_words_comprehension' in curr_results.results && 'results' in curr_results.results.written_words_comprehension)
                    {

                        let assessment_end = curr_results.results.written_words_comprehension.assessment_end
                        let temp = assessment_end.split(' ')
                        if(temp.length>1)
                        {
                            assessment_end = temp[1]
                        }

                        let assessment_start = curr_results.results.written_words_comprehension.assessment_start
                        temp = assessment_start.split(' ')
                        if(temp.length>1)
                        {
                            assessment_start = temp[1]
                        }

                        curr_results = curr_results.results.written_words_comprehension.results

                        let average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Self Correction':0,'Delay':0,'Phonological Distractor':0,'Target':0,'Semantic Distractor':0, 'Unrelated Distractor':0,'Total Selection':0}

                        let phonological_distractors_errors = {'3df':0,'2df':0,'1df':0,'I':0,'F':0,'total':0}



                        for(let m in curr_results)
                        {
                            curr_written_words_comprehension_result[m] = curr_results[m]

                            let curr_score = curr_results[m]['score']
                            if(curr_score=='2')
                            {
                                average_results['Score'] = average_results['Score'] + 2
                                average_results['Max Score'] = average_results['Max Score'] + 2


                            }
                            if(curr_score=='0')
                            {
                                average_results['Score'] = average_results['Score'] + 0
                                average_results['Max Score'] = average_results['Max Score'] + 2


                            }
                            if(curr_score=='1-D')
                            {
                                average_results['Score'] = average_results['Score'] + 1
                                average_results['Max Score'] = average_results['Max Score'] + 2
                                average_results['Delay'] = average_results['Delay'] + 1


                            }
                            if(curr_score=='1-Sc')
                            {
                                average_results['Score'] = average_results['Score'] + 1
                                average_results['Max Score'] = average_results['Max Score'] + 2
                                average_results['Self Correction'] = average_results['Self Correction'] + 1

                            }
              

                            
                            if('delay' in curr_results[m])
                            {
                                average_results['Mean_Delay'] = average_results['Mean_Delay']+ curr_results[m]['delay']
                            }

                            if(curr_results[m]['selection']!=undefined && curr_results[m]['selection']!=null)
                            {
                                let selection = curr_results[m]['selection']

                                function titleCase(str) {
                                    var splitStr = str.toLowerCase().split(' ');
                                    for (var i = 0; i < splitStr.length; i++) {
                                        // You do not need to check if i is larger than splitStr length, as your for does that for you
                                        // Assign it back to the array
                                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                                    }
                                    // Directly return the joined string
                                    return splitStr.join(' '); 
                                 }

                                 selection = titleCase(selection)


                                 if(selection in average_results)
                                 {
                                    average_results[selection] = average_results[selection]+1
                                    average_results['Total Selection'] = average_results['Total Selection'] + 1 
                                 }


                                 if(selection === 'Phonological Distractor')
                                 {

                                    let phonological_distractor_data = curr_results[m]['phonological distractor']

                                    phonological_distractors_errors['total'] = phonological_distractors_errors['total']+1


                                    phonological_distractors_errors[phonological_distractor_data['position']] = phonological_distractors_errors[phonological_distractor_data['position']] + 1

                                    if(phonological_distractor_data['n_features_different']==1)
                                    {
                                        phonological_distractors_errors['1df'] = phonological_distractors_errors['1df']+1
                                    }
                                    if(phonological_distractor_data['n_features_different']==2)
                                    {
                                        phonological_distractors_errors['2df'] = phonological_distractors_errors['2df']+1
                                    }
                                    if(phonological_distractor_data['n_features_different']==3)
                                    {
                                        phonological_distractors_errors['3df'] = phonological_distractors_errors['3df']+1
                                    }
                                

                                 }    
                            }
                        }

                        average_results['Mean_Delay'] = average_results['Mean_Delay']/Math.max(curr_results.length,1)

                        for(let k in all_phonological_distractors_errors)
                        {
                            all_phonological_distractors_errors[k] = all_phonological_distractors_errors[k] + phonological_distractors_errors[k]
                        }

                        for(let k in all_average_results)
                        {
                            all_average_results[k] = all_average_results[k] + average_results[k]
                        }

                        average_results['phonological_distractors_errors'] = phonological_distractors_errors
                    
                        written_words_comprehension_individual_output.push({results:curr_written_words_comprehension_result,assessment_end:assessment_end,assessment_start:assessment_start,average_results:average_results})
    
                        

                    }


                }
    
            }


        }
    }

    // let time_overview = getActivityStartEndTime(written_words_comprehension_individual_output)

    // let correct_overview = computeAverageCorrect(written_words_comprehension_individual_output)

    // let total_exercises = written_words_comprehension_individual_output.length

    // if(total_exercises>0)
    // {
    //     total_listen_count = total_listen_count/total_exercises
    //     total_listen_count = roundNumber(total_listen_count,1)

    // }


    // let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end,correct:correct_overview.correct,incorrect:correct_overview.incorrect,total:correct_overview.total,average_listen_count:total_listen_count,ex_multi_listen:exercises_with_multiple_listen}


    all_average_results['phonological_distractors_errors'] = all_phonological_distractors_errors






    let overview_text = extractOverviewTextWrittenSpokenComprehension(all_average_results)



    let overview = {date:assessment_date,average_results:all_average_results,overview_text:overview_text}

    let written_words_comprehension_output = {overview:overview,individual_outputs:written_words_comprehension_individual_output}



    return written_words_comprehension_output
}

export function extract_spoken_words_comprehension(combined_selected_data)
{


    let spoken_words_comprehension_individual_output = []
    let assessment_date = ''


    let all_average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Repetition of Stimulus':0, 'Self Correction':0,'Delay':0,'Phonological Distractor':0,'Target':0,'Semantic Distractor':0, 'Unrelated Distractor':0,'Total Selection':0}

    let all_phonological_distractors_errors = {'3df':0,'2df':0,'1df':0,'I':0,'F':0,'total':0}



    
    for(let p in combined_selected_data)
    {


        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {


            let curr_data = selected_data[i]

            let all_data = []

            child_key_value_Crawler(curr_data,'exercise','spoken_words_comprehension',all_data)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }



            for(let n in all_data)
            {

                let spoken_words_comprehension_results = []
                keyCrawler(all_data[n],'results',spoken_words_comprehension_results)


                for(let k in spoken_words_comprehension_results)
                {


                    let curr_results = spoken_words_comprehension_results[k].val()

                    let curr_spoken_words_comprehension_result = {}

                    if('results' in curr_results && 'spoken_words_comprehension' in curr_results.results && 'results' in curr_results.results.spoken_words_comprehension)
                    {

                        let assessment_end = curr_results.results.spoken_words_comprehension.assessment_end
                        let temp = assessment_end.split(' ')
                        if(temp.length>1)
                        {
                            assessment_end = temp[1]
                        }

                        let assessment_start = curr_results.results.spoken_words_comprehension.assessment_start
                        temp = assessment_start.split(' ')
                        if(temp.length>1)
                        {
                            assessment_start = temp[1]
                        }


                        curr_results = curr_results.results.spoken_words_comprehension.results

                        let average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Repetition of Stimulus':0, 'Self Correction':0,'Delay':0,'Phonological Distractor':0,'Target':0,'Semantic Distractor':0, 'Unrelated Distractor':0,'Total Selection':0}

                        let phonological_distractors_errors = {'3df':0,'2df':0,'1df':0,'I':0,'F':0,'total':0}
                    
                    

                        for(let m in curr_results)
                        {
                            curr_spoken_words_comprehension_result[m] = curr_results[m]

                            let curr_score = curr_results[m]['score']
                            if(curr_score=='2')
                            {
                                average_results['Score'] = average_results['Score'] + 2
                                average_results['Max Score'] = average_results['Max Score'] + 2


                            }
                            if(curr_score=='0')
                            {
                                average_results['Score'] = average_results['Score'] + 0
                                average_results['Max Score'] = average_results['Max Score'] + 2


                            }
                            if(curr_score=='1-D')
                            {
                                average_results['Score'] = average_results['Score'] + 1
                                average_results['Max Score'] = average_results['Max Score'] + 2
                                average_results['Delay'] = average_results['Delay'] + 1


                            }
                            if(curr_score=='1-Sc')
                            {
                                average_results['Score'] = average_results['Score'] + 1
                                average_results['Max Score'] = average_results['Max Score'] + 2
                                average_results['Self Correction'] = average_results['Self Correction'] + 1

                            }
                            if(curr_score=='1-R')
                            {
                                average_results['Score'] = average_results['Score'] + 1
                                average_results['Max Score'] = average_results['Max Score'] + 2
                                average_results['Repetition of Stimulus'] = average_results['Repetition of Stimulus'] + 1

                            }

                            if('delay' in curr_results[m])
                            {
                                average_results['Mean_Delay'] = average_results['Mean_Delay'] + curr_results[m]['delay']
                            }

                            if(curr_results[m]['selection']!=undefined && curr_results[m]['selection']!=null)
                            {
                                let selection = curr_results[m]['selection']

                                function titleCase(str) {
                                    var splitStr = str.toLowerCase().split(' ');
                                    for (var i = 0; i < splitStr.length; i++) {
                                        // You do not need to check if i is larger than splitStr length, as your for does that for you
                                        // Assign it back to the array
                                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                                    }
                                    // Directly return the joined string
                                    return splitStr.join(' '); 
                                 }

                                 selection = titleCase(selection)


                                 if(selection in average_results)
                                 {
                                    average_results[selection] = average_results[selection]+1
                                    average_results['Total Selection'] = average_results['Total Selection'] + 1 
                                 }

                                 if(selection === 'Phonological Distractor')
                                 {

                                    let phonological_distractor_data = curr_results[m]['phonological distractor']

                                    phonological_distractors_errors['total'] = phonological_distractors_errors['total']+1


                                    phonological_distractors_errors[phonological_distractor_data['position']] = phonological_distractors_errors[phonological_distractor_data['position']] + 1

                                    if(phonological_distractor_data['n_features_different']==1)
                                    {
                                        phonological_distractors_errors['1df'] = phonological_distractors_errors['1df']+1
                                    }
                                    if(phonological_distractor_data['n_features_different']==2)
                                    {
                                        phonological_distractors_errors['2df'] = phonological_distractors_errors['2df']+1
                                    }
                                    if(phonological_distractor_data['n_features_different']==3)
                                    {
                                        phonological_distractors_errors['3df'] = phonological_distractors_errors['3df']+1
                                    }
                                

                                 }    
                            }
                        }

                        average_results['Mean_Delay'] = average_results['Mean_Delay']/Math.max(curr_results.length,1)


                        for(let k in all_average_results)
                        {
                            all_average_results[k] = all_average_results[k] + average_results[k]
                        }

                        
                        for(let k in all_phonological_distractors_errors)
                        {
                            all_phonological_distractors_errors[k] = all_phonological_distractors_errors[k] + phonological_distractors_errors[k]
                        }

                        average_results['phonological_distractors_errors'] = phonological_distractors_errors
                    
                        spoken_words_comprehension_individual_output.push({results:curr_spoken_words_comprehension_result,assessment_start:assessment_start,assessment_end:assessment_end,average_results:average_results})

                    }


                }
    
            }


        }
    }

    all_average_results['phonological_distractors_errors'] = all_phonological_distractors_errors


    let overview_text = extractOverviewTextWrittenSpokenComprehension(all_average_results)

    let overview = {date:assessment_date,average_results:all_average_results,overview_text:overview_text}


    let spoken_words_comprehension_output = {overview:overview,individual_outputs:spoken_words_comprehension_individual_output}



    return spoken_words_comprehension_output
}

function extractOverviewTextWrittenSpokenComprehension(all_average_results)
{
    let overview_text = 'The client scored ' + all_average_results['Score'] + ' out of ' + all_average_results['Max Score'] + '.'
    
    overview_text = overview_text + '\n They got ' + all_average_results['Target'] + ' word(s) correctly'
    
    if('Delay' in all_average_results && all_average_results['Delay']>0)
    {

        overview_text = overview_text + ', ' + all_average_results['Delay'] + ' of those word(s) with delay > 5s'
        
    } 
    if('Self Correction' in all_average_results && all_average_results['Self Correction']>0)
    {

        overview_text = overview_text + ', ' + all_average_results['Self Correction'] + ' of those word(s) with self correction'
        
    } 

    if('Repetition of Stimulus' in all_average_results && all_average_results['Repetition of Stimulus']>0)
    {

        overview_text = overview_text + ', ' + all_average_results['Repetition of Stimulus'] + ' of those word(s) with repetition of stimulus'
        
    } 

    overview_text = overview_text + '.'
    
    
        
    
    
    let average_error_by_type = []

    average_error_by_type.push({type:'Phonological Distractor','errors':all_average_results['Phonological Distractor']})
    average_error_by_type.push({type:'Semantic Distractor','errors':all_average_results['Semantic Distractor']})
    average_error_by_type.push({type:'Unrelated Distractor','errors':all_average_results['Unrelated Distractor']})

    function compare( a, b ) {
        if ( a.errors < b.errors ){
            return 1;
        }
        if ( a.errors >= b.errors ){
            return -1;
        }
    }


    average_error_by_type = average_error_by_type.sort(compare)

    for(let k in average_error_by_type)
    {



        if(k==0 && average_error_by_type[k]['errors']>0)
        {


            overview_text = overview_text + '\n - The majority of errors were with ' + average_error_by_type[k]['type'] + 's. Where they had ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Total Selection'] + ' errors.'

        }

        if(k==1 && average_error_by_type[k]['errors']>0)
        {
            overview_text = overview_text + '\n - They also had ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Total Selection'] + ' errors with ' + average_error_by_type[k]['type'] + 's.'
        }

        if(k==2 && average_error_by_type[k]['errors']>0)
        {
            overview_text = overview_text + '\n - And ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Total Selection'] + ' errors with ' + average_error_by_type[k]['type'] + 's.'
        }


        if(average_error_by_type[k]=='Phonological Distractor')
        {
            // need to add this in the future
        }

       
        if(average_error_by_type[k]['errors'] == 0)
        {
            overview_text = overview_text + '\n - They made no ' + average_error_by_type[k]['type'] + ' errors.'
        }

    }

    return overview_text
}


function extractOverviewTextSemanticMemory(all_average_results)
{
    let overview_text = 'The client correctly identified ' + all_average_results['Score'] + '  images out of ' + all_average_results['Max Score'] + '.'

    
    
    let average_error_by_type = []

    average_error_by_type.push({type:'Close Semantic Distractor','errors':all_average_results['Close Semantic Distractor']})
    average_error_by_type.push({type:'Distant Semantic Distractor','errors':all_average_results['Distant Semantic Distractor']})
    average_error_by_type.push({type:'Unrelated Distractor','errors':all_average_results['Unrelated Distractor']})

    function compare( a, b ) {
        if ( a.errors < b.errors ){
            return 1;
        }
        if ( a.errors >= b.errors ){
            return -1;
        }
    }


    average_error_by_type = average_error_by_type.sort(compare)

    for(let k in average_error_by_type)
    {

        if(k==0 && average_error_by_type[k]['errors']>0)
        {


            overview_text = overview_text + '\n - The majority of errors were with ' + average_error_by_type[k]['type'] + 's. Where they had ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Max Score'] + ' errors.'

        }

        if(k==1 && average_error_by_type[k]['errors']>0)
        {
            overview_text = overview_text + '\n - They also had ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Max Score'] + ' errors with ' + average_error_by_type[k]['type'] + 's.'
        }

        if(k==2 && average_error_by_type[k]['errors']>0)
        {
            overview_text = overview_text + '\n - And ' + average_error_by_type[k]['errors'] + ' / ' + all_average_results['Max Score'] + ' errors with ' + average_error_by_type[k]['type'] + 's.'
        }

       
        if(average_error_by_type[k]['errors'] == 0)
        {
            overview_text = overview_text + '\n - They made no ' + average_error_by_type[k]['type'] + ' errors.'
        }

    }

    return overview_text
}


export function extract_semantic_memory(combined_selected_data)
{


    let semantic_memory_individual_output = []
    let assessment_date = ''


    let all_average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Close Semantic Distractor':0,'Target':0,'Distant Semantic Distractor':0, 'Unrelated Distractor':0}


    
    for(let p in combined_selected_data)
    {


        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {


            let curr_data = selected_data[i]

            let all_data = []

            child_key_value_Crawler(curr_data,'exercise','semantic_memory',all_data)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }



            for(let n in all_data)
            {

                let semantic_memory_results = []
                keyCrawler(all_data[n],'results',semantic_memory_results)


                for(let k in semantic_memory_results)
                {



                    let curr_results = semantic_memory_results[k].val()

                    let curr_semantic_memory_result = {}

                    if('results' in curr_results && 'semantic_memory' in curr_results.results && 'results' in curr_results.results.semantic_memory)
                    {

                        
                        
                        let assessment_end = curr_results.results.semantic_memory.assessment_end
                        let temp = assessment_end.split(' ')
                        if(temp.length>1)
                        {
                            assessment_end = temp[1]
                        }

                        let assessment_start = curr_results.results.semantic_memory.assessment_start
                        temp = assessment_start.split(' ')
                        if(temp.length>1)
                        {
                            assessment_start = temp[1]
                        }

                        curr_results = curr_results.results.semantic_memory.results

                        let average_results = {'Mean_Delay':0,'Score':0,'Max Score':0,'Close Semantic Distractor':0,'Target':0,'Distant Semantic Distractor':0, 'Unrelated Distractor':0}

                    


                        for(let m in curr_results)
                        {
                            curr_semantic_memory_result[m] = curr_results[m]

                                      
                            if('delay' in curr_results[m])
                            {
                                average_results['Mean_Delay'] = average_results['Mean_Delay']+ curr_results[m]['delay']
                            }

                            if(curr_results[m]['selection']!=undefined && curr_results[m]['selection']!=null)
                            {
                                let selection = curr_results[m]['selection']

                                function titleCase(str) {
                                    var splitStr = str.toLowerCase().split(' ');
                                    for (var i = 0; i < splitStr.length; i++) {
                                        // You do not need to check if i is larger than splitStr length, as your for does that for you
                                        // Assign it back to the array
                                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                                    }
                                    // Directly return the joined string
                                    return splitStr.join(' '); 
                                }

                                selection = titleCase(selection)

                                console.debug(selection)


                                if(selection in average_results)
                                {


                                    average_results[selection] = average_results[selection]+1
                                    average_results['Max Score'] = average_results['Max Score'] + 1 

                                    if(selection == 'Target')
                                    {
                                        average_results['Score'] = average_results['Score'] + 1 
                                    }
                                }



                                
                            }
                        }


                        average_results['Mean_Delay'] = average_results['Mean_Delay']/Math.max(curr_results.length,1)

                        for(let k in all_average_results)
                        {
                            all_average_results[k] = all_average_results[k] + average_results[k]
                        }

                        
            
                        semantic_memory_individual_output.push({results:curr_semantic_memory_result,assessment_end:assessment_end,assessment_start:assessment_start,average_results:average_results})

                    }


                }
    
            }


        }
    }

    let overview_text = extractOverviewTextSemanticMemory(all_average_results)

    let overview = {date:assessment_date,average_results:all_average_results,overview_text:overview_text}


    let semantic_memory_output = {overview:overview,individual_outputs:semantic_memory_individual_output}



    return semantic_memory_output
}

export function extract_recognition_memory(combined_selected_data)
{


    let recognition_memory_individual_output = []
    let assessment_date = ''


    let all_average_results = {'Mean_Delay':0,'Score':0,'Max Score':0}


    
    for(let p in combined_selected_data)
    {


        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {


            let curr_data = selected_data[i]

            let all_data = []

            child_key_value_Crawler(curr_data,'exercise','recognition_memory',all_data)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }



            for(let n in all_data)
            {

                let recognition_memory_results = []
                keyCrawler(all_data[n],'results',recognition_memory_results)


                for(let k in recognition_memory_results)
                {



                    let curr_results = recognition_memory_results[k].val()

                    let curr_recognition_memory_result = {}

                    if('results' in curr_results && 'recognition_memory' in curr_results.results && 'results' in curr_results.results.recognition_memory)
                    {

                        let assessment_end = curr_results.results.recognition_memory.assessment_end
                        let temp = assessment_end.split(' ')
                        if(temp.length>1)
                        {
                            assessment_end = temp[1]
                        }

                        let assessment_start = curr_results.results.recognition_memory.assessment_start
                        temp = assessment_start.split(' ')
                        if(temp.length>1)
                        {
                            assessment_start = temp[1]
                        }

                        curr_results = curr_results.results.recognition_memory.results

                        let average_results = {'Mean_Delay':0,'Score':0,'Max Score':0}

                    


                        for(let m in curr_results)
                        {
                            curr_recognition_memory_result[m] = curr_results[m]

                                  
                            if('delay' in curr_results[m])
                            {
                                average_results['Mean_Delay'] = average_results['Mean_Delay']+ curr_results[m]['delay']
                            }


                            if(curr_results[m]['correct'])
                            {
                                average_results['Score'] = average_results['Score']+1 
                            }

                            
                            average_results['Max Score'] = average_results['Max Score']+1 

                           
                        }

                        average_results['Mean_Delay'] = average_results['Mean_Delay']/Math.max(curr_results.length,1)

                        for(let k in all_average_results)
                        {
                            all_average_results[k] = all_average_results[k] + average_results[k]
                        }

                        
            
                        recognition_memory_individual_output.push({results:curr_recognition_memory_result,assessment_start:assessment_start,assessment_end:assessment_end,average_results:average_results})

                    }


                }
    
            }


        }
    }


    let overview_text = 'The client recognised ' + all_average_results['Score'] + ' images out of ' + all_average_results['Max Score']

    let overview = {date:assessment_date,average_results:all_average_results,overview_text:overview_text}


    let recognition_memory_output = {overview:overview,individual_outputs:recognition_memory_individual_output}



    return recognition_memory_output
}

export function extract_memory_image(combined_selected_data)
{

    let memory_image_individual_output = []
    let assessment_date = ''

    let total_listen_count = 0

    let exercises_with_multiple_listen = 0

    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_memory_image = []

            child_key_value_Crawler(curr_data,'exercise','Memory Image',all_memory_image)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }

            for(let n in all_memory_image)
            {

                let memory_image_results = []
                keyCrawler(all_memory_image[n],'all_results',memory_image_results)

                for(let k in memory_image_results)
                {
                    let curr_results = memory_image_results[k].val()

                    let all_results = curr_results.all_results

                    let correct = all_results.Correct
                    let total = all_results.Total
                    let incorrect = all_results.Incorrect

                    let listen_count = 0

                    if('ListenCount' in all_results)
                    {
                        listen_count = all_results.ListenCount

                    }

                    if(listen_count>1)
                    {
                        exercises_with_multiple_listen = exercises_with_multiple_listen+1
                    }


                    total_listen_count = total_listen_count + listen_count
                    let result_object = {correct:correct,total:total,incorrect:incorrect,time:time,listen_count:listen_count}

                    memory_image_individual_output.push(result_object)


                }
    
            }


        }
    }

    let time_overview = getActivityStartEndTime(memory_image_individual_output)

    let correct_overview = computeAverageCorrect(memory_image_individual_output)

    let total_exercises = memory_image_individual_output.length

    if(total_exercises>0)
    {
        total_listen_count = total_listen_count/total_exercises
        total_listen_count = roundNumber(total_listen_count,1)

    }


    let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end,correct:correct_overview.correct,incorrect:correct_overview.incorrect,total:correct_overview.total,average_listen_count:total_listen_count,ex_multi_listen:exercises_with_multiple_listen}

    let memory_image_output = {overview:overview,individual_outputs:memory_image_individual_output}



    return memory_image_output
}

export function extract_word_finding(combined_selected_data)
{

    let word_finding_individual_outputs = []
    let assessment_date = ''

    console.debug('word finding')

    let average_words = 0
    let average_hints = 0

    
    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_word_finding = []

            child_key_value_Crawler(curr_data, 'exercise','Word Finding',all_word_finding)

            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            console.debug('all_word_finding.length = ' + all_word_finding.length)

            if(time.length>1)
            {
                time = time[1]
            }

            for(let n in all_word_finding)
            {


                let word_finding_results = []



                keyCrawler(all_word_finding[n],'all_results',word_finding_results)

                for(let k in word_finding_results)
                {
                    let curr_results = word_finding_results[k].val()

                    

                    let all_results = curr_results.all_results

                    let Number_Words = all_results.Number_Words
                    let Number_Hints = all_results.Number_Hints


                    average_words = average_words + Number_Words
                    average_hints = average_hints + Number_Hints

                    
                    let Topic = all_results.Topic

                    let result_object = {time:time,Number_Words:Number_Words,Number_Hints:Number_Hints,Topic:Topic}

                    word_finding_individual_outputs.push(result_object)


                }
    
            }


        }
    }

    let time_overview = getActivityStartEndTime(word_finding_individual_outputs)

    let total_exercises = word_finding_individual_outputs.length

    console.debug('total_exercises = ' + total_exercises)

    if(total_exercises>0)
    {
        console.debug('average_words = ' + average_words)

        average_hints = average_hints/total_exercises
        average_words = average_words/total_exercises

        console.debug('average_words = ' + average_words)
        average_hints = roundNumber(average_hints,1)
        average_words = roundNumber(average_words,1)
        console.debug('average_words = ' + average_words)

    }



    let overview = {average_hints:average_hints,average_words:average_words,total_exercises:total_exercises,date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end}

    let word_finding_output = {overview:overview,individual_outputs:word_finding_individual_outputs}



    return word_finding_output
}

export function extract_token_assessment(combined_selected_data)
{

    let token_assessment_individual_outputs = []
    let assessment_date = ''

    let total_listen_count = 0


    let exercises_with_multiple_listen = 0

    let total_task_attributes_for_results = {color:{},shape:{},size:{},multi:{}}

    for(let n in total_task_attributes_for_results)
    {
        total_task_attributes_for_results[n] = {total:0,error:0,correct:0,percentage:100}
    }



    for(let p in combined_selected_data)
    {
        let selected_data = combined_selected_data[p]

        for(let i in selected_data)
        {
            let curr_data = selected_data[i]

            let all_token_assessment = []

            child_key_value_Crawler(curr_data,'exercise','Token Assessment',all_token_assessment)


            let date = curr_data.key
            
            let time = date.split(' ')

            assessment_date = time[0]

            if(time.length>1)
            {
                time = time[1]
            }



            for(let n in all_token_assessment)
            {

                let token_assessment_results = []
                keyCrawler(all_token_assessment[n],'all_results',token_assessment_results)

                for(let k in token_assessment_results)
                {
                    let curr_results = token_assessment_results[k].val()

                    let all_results = curr_results.all_results

                    let correct = all_results.Correct
                    let total = all_results.Total
                    let incorrect = all_results.Incorrect

                    let listen_count = all_results.ListenCount

                    console.debug('listen_count = ' + listen_count)

                    total_listen_count = total_listen_count + listen_count

                    if('task_attributes_for_results' in all_results)
                    {
                        let task_attributes_for_results = all_results.task_attributes_for_results
                        for(let m in task_attributes_for_results)
                        {
                            if(task_attributes_for_results[m]['contains'])
                            {
                                total_task_attributes_for_results[m]['total'] = total_task_attributes_for_results[m]['total']+1

                                if(task_attributes_for_results[m]['error'])
                                {
                                    total_task_attributes_for_results[m]['error'] = total_task_attributes_for_results[m]['error']+1

                                }
                            }
                        }

                    }

                    if(listen_count>1)
                    {
                        exercises_with_multiple_listen = exercises_with_multiple_listen+1
                    }

                    let result_object = {correct:correct,total:total,incorrect:incorrect,listen_count:listen_count,time:time}

                    token_assessment_individual_outputs.push(result_object)


                }
    
            }


        }
    }

    let time_overview = getActivityStartEndTime(token_assessment_individual_outputs)
    let correct_overview = computeAverageCorrect(token_assessment_individual_outputs)

    let total_exercises = token_assessment_individual_outputs.length

    if(total_exercises>0)
    {
        total_listen_count = total_listen_count/total_exercises
        total_listen_count = roundNumber(total_listen_count,1)

    }

    for(let k in total_task_attributes_for_results)
    {
        total_task_attributes_for_results[k]['correct'] = total_task_attributes_for_results[k]['total']-total_task_attributes_for_results[k]['error']

        if(total_task_attributes_for_results[k]['total']>0)
        {
            total_task_attributes_for_results[k]['percentage'] = roundNumber(total_task_attributes_for_results[k]['correct']/total_task_attributes_for_results[k]['total']*100,0)
        
        }


    }

    let overview = {date:assessment_date,time_start:time_overview.time_start,time_end:time_overview.time_end,correct:correct_overview.correct,incorrect:correct_overview.incorrect,total:correct_overview.total,average_listen_count:total_listen_count,ex_multi_listen:exercises_with_multiple_listen,overview_difficulties:total_task_attributes_for_results}


    let token_test_output = {overview:overview,individual_outputs:token_assessment_individual_outputs}



    return token_test_output
}

export function computeAverageCorrect(activity_output_data)
{
    let correct = 0
    let incorrect = 0
    let total = 0

    for(let n in activity_output_data)
    {
        let curr_total = activity_output_data[n].total
        let curr_correct = activity_output_data[n].correct

        let curr_incorrect = curr_total - curr_correct

        total = total+curr_total
        correct = correct+curr_correct
        incorrect = incorrect+curr_incorrect

    }

    let correct_overview = {total:total,correct:correct,incorrect:incorrect}

    return correct_overview
}

export function getActivityStartEndTime(activity_output_data)
{
    let all_times = []

    let time_start = ''
    let time_end = ''

    for(let n in activity_output_data)
    {
        all_times.push(activity_output_data[n].time)

    }

    let orderedTimes = all_times.sort(function(a,b){
        return Date.parse(a) > Date.parse(b);
    });

    if(orderedTimes.length>0)
    {
        time_start = orderedTimes[0]
        time_end = orderedTimes[orderedTimes.length-1]

        // for(let n in activity_output_data)
        // {
        //     activity_output_data[n].time_start = time_start
        //     activity_output_data[n].time_end = time_end
    
        // }

    }

    let time_overview = {time_start:time_start,time_end:time_end}

    return time_overview
}

