<template>
  <div class="row mt-2">
    <div class="card card-body" >
      <div>
        <p class="h4 text-start">Interactive Session Questionnaire</p>
        <p v-if="session_slt_id">Session SLT ID: {{ session_slt_id }}</p> 
      </div>
      <table class="table mt-4">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Question</th>
            <th scope="col">Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in results" v-bind:key="index">
            <td>{{ index }}</td>
            <td class="text-start">{{ row['question'] }}</td>
            <td>{{ row['options'][row['answer']]}}</td>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import {getData} from '@/firebase/index'
import firebase from 'firebase/app'

  export default {
    name: 'isession_questionnaire',
    components: {
    },
    props: {
      results: {
        type: Object,
        default: null
      },
    },
    emits: ['is_isession'],
    setup(props, {emit}) {

      onMounted( () => {

      });

      const isession_questionnaire = ref([])
      const session_slt_id = ref(null)
      
      const audio_player = ref(null)

      const playAudio = (audio_file_location) => {

        let storage = firebase.storage()
        storage.ref(audio_file_location).getDownloadURL().then((url) => {
          audio_player.value = new Audio(url);
          audio_player.value.play();

          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });

        }).catch((error) => {

        })
      }

      const playRecording = (address) => {
        playAudio(address)
      }

      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      const downloadRecording = (address) => {
        let storage = firebase.storage();

        storage.ref(address).getDownloadURL().then((url) => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {

        });
      }

      const route = useRoute()
      let root_path = JSON.parse(route.query.root_path) //list of exercises paths that have audio address
      let module_name = route.query.module_name

    
      
      //these are for interactive session
      getData(root_path, function (err, snapshot) {
        let module_object = snapshot.val()
        if ('session_slt_id' in module_object[module_name]) {
          session_slt_id.value = module_object[module_name]['session_slt_id']
        }
        if (module_object[module_name].hasOwnProperty('exercises') && typeof module_object[module_name]['exercises'][Symbol.iterator] === 'function') {

          for (let exercise_data of module_object[module_name]['exercises']) {
            if (exercise_data) {
          
              if('session_questionnaire' in exercise_data) {

                let question = exercise_data['session_questionnaire']['question']
                let answer = exercise_data['session_questionnaire']['answer']
                let options = exercise_data['session_questionnaire']['options']

                let data = {question: question, answer: answer, options: options}

                isession_questionnaire.value.push(data)
              }
            }
          }
        } 
        if (isession_questionnaire.value.length > 0) {
          emit('is_isession', true)
        }
      })

    
      return {
        userCategory,
        playRecording,
        downloadRecording,
        session_slt_id,
        isession_questionnaire,
        
      };

    }
  }
</script>