<template>
  <div>

    <Layout>
      <template v-slot:content>
        <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
        <div class="row">
          <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="card card-body">
              <div class="row">
                <p class="text-30">
                  Press Start. Chat with the {{ bot_topic }} <br/>
                  Once you are done, press Next.
                </p>
              </div>
              <div class="row">
                <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card card-body h-100">
              <p style="color:red; white-space: pre-wrap">{{ last_message }}</p>
              <p style="color:blue; white-space: pre-wrap">{{ last_reply }}</p>
              <!-- <input v-model="curr_message"  style="font-size: x-large; width:100%"/> -->  
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="!first_exercise"
          @show_results="goToResults()"   
          >
          <template v-slot:top>
            <hr/>
            <!-- <p class="lead" font-size="medium">Task {{curr_task+1}} of {{n_tasks}}</p> -->
            <div v-if="recognition_setting==0">
              <button v-if="!started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()"><strong>{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></strong></button>
              <button v-if="started" class="btn btn-primary btn-lg w-100 mt-2" disabled>
                <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
              </button>
              <!-- <button v-if="started && congatulationsOnce" disabled class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button> -->
            </div>
            <!-- next was here -->
          </template>
        </Sidebar>
      </template>
    </Layout>

  </div>
</template>

<script>

import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {  get_phonetic_transcription,
          LevenshteinDistanceNoLower, 
          LevenshteinDistance, 
          LevenshteinDistanceWord_Phonemes } from '@/utils/text_distance_metrics'
import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import SpeechRec from '@/media_input/SpeechRecognition'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import { save_pre_computed_results } from '@/utils/results/data_save_utils'
import { sendMessage } from '@/utils/chatbot'

import words from '@/media_input/words'
import constants from '@/utils/constants'


import { logActivity, removePunctuationFromString } from '@/media_input/utils'
import { startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'

import {BV_Points} from '@/utils/bv_points'


export default {
    name: 'chat_scenario',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: { Layout, Sidebar },
    data() {
      return {
        curr_message:'',
        last_message:'',
        stopped:false,
        last_reply:'',
        chat_history:'',
        conversationHistory:[],
        total_exercises:1,
        recognition_setting: 0,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        diffMarks:{
          0:{
            style:{color: '#000000'},
            label:'Auto'
          },
          1:{
            style:{color: '#000000'},
            label:'Manual'
          },
        },
        answer_text: '',
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        bot_topic:'Barista',
        bot_topic_options:{
          'Postman':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Postman/postman_1.png"
            ],
            "initial_message": "Hi! I'm Chris, your postman. How can I help you?",
            "role": "a Postman, named chris"
          },
          'Barista':
          {
            "img_names": [
                "/BV/images/Chat_scenario/Cafe/cafe_1.png"
              ],
              "initial_message": "Welcome to our coffee shop. What would you like to have?",
            "role": "a barista in the UK, named chris"
          },
          'Waiter':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Restaurant/restaurant_1.png",
              "/BV/images/Chat_scenario/Restaurant/restaurant_2.png"
            ],
            "initial_message": "Good evening, I'm Chris, your waiter for today. How may I help you?",
            "role": "a restaurant waiter, named chris"
          },
          'Hairdresser':
          {
            "img_names": [
              "/BV/images/Chat_scenario/Hairdresser/hairdresser_1.png"
            ],
            "initial_message": "Welcome, how would you like to have your haircut",
            "role": "a hairdresser, named chris"

          }
        },
        initial_message:'',
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        first_exercise:true,
        target_word: '',
        single_target_word: '',
        audio_player: null,
        instructions_msg: '',
        crossImg: crossImg,
        doneIcon: doneIcon,
        play_pause_text: 'Start',
        current_exercise_index: 0,
        total_exercises:1,
        speech_rec: null,
        started: false,
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        backImg:null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this

      logActivity( 'accessed', 'chat_scenario')

      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))

      this.setup();

    },
    mounted()
    {
      const _this = this

      console.debug('mounted')


    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()
      this.speech_rec.stop()

      clearTimeout(window.countDownTimer)
      clearTimeout(window.countDownTimer2)
    },
    computed: {

    currentSentence_found: async function () {
      

      console.debug('this.currentSentence')
      console.debug(this.currentSentence)

      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
      {



        let curr_message = (' ' + this.currentSentence).slice(1);

        this.sendMessage(curr_message)

        this.currentSentence = ''

        // this.stop_recording()
        // this.start_recording()


      }



    },
    },
    
    methods: 
    {
      saveResults()
      {
        const _this = this



        let results = {'conversationHistory':this.conversationHistory}
        var save_data = {resultsTablePre: {exercise_type: 'Chat Scenario', exercise: 'Chat Scenario', results: results, audio_address:''}}


        save_pre_computed_results(_this.exForm, _this.$firebase, save_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', save_data);
        
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      async initialMessage(initial_message)
      {
        let reply  = initial_message

        if(initial_message=='')
        {
          let curr_message = 'Shortly introduce yourself like a human would'
          this.conversationHistory.push({ role: 'user', content: curr_message }); // Add user input to conversation history

          reply = await sendMessage(this.conversationHistory,this.bot_topic_options[this.bot_topic]['role'])

          
        }


      
        this.conversationHistory.push({ role: 'assistant', content: reply }); // Add assistant's reply to history

        this.bv_tts.say(reply) 

        this.chat_history = this.chat_history + '\n' + reply

        this.last_reply = reply


      },
      async sendMessage(curr_message)
      {
        
        const _this = this

        this.bv_tts.cancel()

        this.conversationHistory.push({ role: 'user', content: curr_message }); // Add user input to conversation history
        let reply = await sendMessage(this.conversationHistory,this.bot_topic_options[this.bot_topic]['role'])

        this.conversationHistory.push({ role: 'assistant', content: reply }); // Add assistant's reply to history

        // console.debug('reply = ' + reply)

        console.debug(curr_message)
        this.bv_tts.say(reply) 

        this.last_message = curr_message

        this.last_reply = reply


        this.chat_history = this.chat_history + '\n' + curr_message

        this.chat_history = this.chat_history + '\n' + reply

        // this.curr_message = ''

      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      setup()
      {
        console.debug('setup')
        let _this = this;

        _this.answer_text = '';

        _this.first_exercise = !_this.exForm.has_results

        let img_array = []

        console.debug('this.exForm')

        console.debug(this.exForm)

        if('topic' in this.exForm)
        {

          for(let k in this.exForm.topic)
          {

            this.bot_topic = k
            this.bot_topic_options = this.exForm.topic

            img_array = this.bot_topic_options[k]['img_names']
            this.initial_message = this.bot_topic_options[k]['initial_message']

            
          }
          
        }
        else
        {
          this.bot_topic = localStorage.getItem('chatbot_topic')

          img_array = this.bot_topic_options[this.bot_topic]['img_names']

          this.initial_message = this.bot_topic_options[this.bot_topic]['initial_message']

        }

        
        console.debug('client_demo= '+_this.client_demo);

      

        let img_address = img_array[[Math.floor(img_array.length * Math.random())]]

        console.debug('img_address = ' + img_address)
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
          console.debug('url = ' )
          console.debug(url)
            _this.imgSrc = url;
        }).catch(function(error) {

        });




        
        _this.initialMessage(this.initial_message)

        console.debug('_this.first_exercise = ' + _this.first_exercise)

    
       
        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.current_exercise_index = _this.exForm.current_exercise_index + 1


        console.log('_this.exForm.current_exercise_index = ' + _this.exForm.current_exercise_index)
        _this.total_exercises = _this.exForm.total_all_type_exercises


    
      },
      playPause()
      {
        let _this = this;

        _this.play_status = true;
        _this.resumeRecording()
        _this.results['total'] = 1
      },
      resumeRecording()
      {
        let _this = this;
        // recordAudioMedia()
        _this.is_recording = true;
        _this.congatulationsOnce = false;
        // _this.runTimer()
        _this.start_recording()
      },
      start_recording() {
        const _this = this
        this.bv_tts.cancel()

        this.speech_rec.start(undefined,true,this.region)
        this.started = true
        // this.play_pause_text = 'Recording'
        console.debug('started')

        clearTimeout(window.countDownTimer)

        clearTimeout(window.countDownTimer2)
        
        // window.countDownTimer2 = setTimeout(() => {
        //   _this.answer_text = 'It is recording now, name the picture out loud'
        //   _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        // }, 500)
          
        window.countDownTimer = setTimeout(() => {
          if(_this.play_status)
          {
            _this.started = false
            _this.play_pause_text = 'Start'

            _this.answer_text = "Oops we missed what you said! Press Start and try again"
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }
          _this.stop_recording()
        }, 1000000)
      },
      stop_recording() {
        const _this = this
        this.stopped = true
        _this.play_status = false;
        this.started = false
        _this.currentSentence = ''
        _this.speech_rec.stop()
      },
      pause()
      {
        let _this = this;
        _this.play_status = false;
        _this.play_pause_text = 'Start'
      },
    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>
