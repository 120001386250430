<template>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #0D302A; position:relative; bottom:0px;">
        <div class="container-fluid">
          <span class="navbar-brand text-light" style="font-size: 13px;">© {{ new Date().getFullYear() }} BeautifulVoice. All rights reserved.</span>

            <div class="d-flex flex-row"  style="font-size: 13px;">
              <div class="border-end pe-2 me-2">
                <router-link :to="{ name: 'company'}" target="_blank">
                  <span class="text-light align-middle"><u>Company Information</u></span>
                </router-link>
              </div>
              <div class="border-end pe-2 me-2">
                <a href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FBV_TERMS.pdf?alt=media&token=602cef8d-fb54-4dc7-bd16-9ba44215689c" target="_blank"><span class="text-light align-middle"><u>Terms of Use</u></span></a>
              </div>
              <div class="border-end pe-2 me-2">
                <a href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FPrivacy%20Policy.pdf?alt=media&token=a8132518-3a58-405d-8f6b-f0616af2ebff" target="_blank"><span class="text-light align-middle"><u>Privacy Policy</u></span></a>
              </div>
              <div class="">
                <router-link :to="{ name: 'about'}" target="_blank">
                  <span class="text-light align-middle"><u>Platform Info & Manual</u></span>
                </router-link>

              </div>
            </div>
        </div>
      </nav>
      
    </div>
</template>

<script>

export default {
  props: {

  },
  name: "Footer-bar",
  data() {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
 
}
</script>

<style scoped>

#footer {
  position: fixed;
  height: 50px;
  background-color: red;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

</style>