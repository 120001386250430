<template>
  <div class="d-flex flex-column min-vh-100">
  
    <Navbar :enableBack=true></Navbar>

    <div class="container">
      <h1 class="display-5">Home Assignments</h1>

      <div v-if="assessment_modules.length>0" class="card card-body">
        <div>
          <h4 v-if="assessment_modules.length==1">You have {{assessment_modules.length}} assessment task from your speech and language therapist!</h4>
          <h4 v-else>You have {{assessment_modules.length}} assessment tasks from your speech and language therapist!</h4>

          <p class="lead">These modules are required to be completed for your speech and language assessment.</p>
        </div>

        <div class="card card-body mb-2 shadow-sm text-white" v-for="module, index in assessment_modules" :key="index" style="background-color: rebeccapurple; ">
          <div>
            <h2>{{module.mod_name}}</h2>
            <div>
              <p style="font-size:16px" v-if="module.category">This is a {{module.category.toLowerCase()}} module.</p>
            </div>
            <div>
              <button v-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index == module.total_all_type_exercises" style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-secondary" disabled><i class="bi bi-clipboard-check"></i> Assessed</button>
              <button v-else-if="module.current_exercise_index >= 1 && module.current_exercise_index < module.total_all_type_exercises" style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-primary" @click="startModule(module.index, true, module.isPackage, module.package_uid, module.current_exercise_index)"><i class="bi bi-chevron-right"></i> Continue Assessment</button>
              <button v-else style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-success" @click="startModule(module.index, true, module.isPackage, module.package_uid, module.current_exercise_index)"><i class="bi bi-pencil-square"></i> Start Assessment</button>
            </div>
          </div>
          <hr style="color:rgba(0,0,0, 0.3)"/>
          <div>
            <p style="font-size:14px">You have completed <i>{{module.current_exercise_index}} out of {{module.total_all_type_exercises}} exercises. </i></p>
            <p v-if="module.completedOn" style="font-size:14px">This assessment module has been completed.</p>
            
            <div class="progress" style="height: 30px;">
              <div v-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index == module.total_all_type_exercises || module.completedOn" class="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: (module.current_exercise_index / module.total_all_type_exercises * 100 + '%')}" >{{Math.floor(module.current_exercise_index / module.total_all_type_exercises * 100)}} % completed </div>
              <div v-else-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index < module.total_all_type_exercises" class="progress-bar bg-success" role="progressbar" :style="{width: (module.current_exercise_index / module.total_all_type_exercises * 100 + '%')}" >{{Math.floor(module.current_exercise_index / module.total_all_type_exercises * 100)}} % completed</div>
              <div v-else class="progress-bar bg-success" role="progressbar" style="width: 0%" ></div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div v-if="homework_modules.length>0" class="card card-body">
        <div>
          <h4 v-if="homework_modules.length==1">You have {{homework_modules.length}} homework task from your speech and language therapist!</h4>
          <h4 v-else>You have {{homework_modules.length}} homework tasks from your speech and language therapist!</h4>

          <p v-if="homework_modules.length>1" class="lead">You can choose which module you would like to practise first.</p>
        </div>
        <div class="card card-body mb-2 shadow-sm" v-for="module, index in homework_modules" :key="index">
          <div>
            <h2>{{module.mod_name}}</h2>
            <div>
              <p style="font-size:16px" v-if="module.category">This is a {{module.category.toLowerCase()}} module.</p>
              <!--<p style="font-size:16px">Some description</p> -->
            </div>
            <div>
              <button v-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index == module.total_all_type_exercises" style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-success" @click="startModule(index, false, module.isPackage, 'general', module.current_exercise_index)"><i class="bi bi-repeat"></i> Practise Again</button>
              <button v-else-if="module.current_exercise_index >= 1 && module.current_exercise_index < module.total_all_type_exercises" style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-primary" @click="startModule(index, false, module.isPackage, 'general', module.current_exercise_index)"><i class="bi bi-chevron-right"></i> Continue Exercise</button>
              <button v-else style="float:right;padding:30px;margin:10px; width:250px" class="btn btn-success" @click="startModule(index, false, module.isPackage, 'general', module.current_exercise_index)"><i class="bi bi-pencil-square"></i> Start Exercise</button>
            </div>
          </div>
          <hr style="color:rgba(0,0,0, 0.3)"/>
          <div>
            <p style="font-size:14px">You have completed <i>{{module.current_exercise_index}} out of {{module.total_all_type_exercises}} exercises. </i></p>
            <p v-if="module.completedOn" style="font-size:14px">This module has been completed in total {{module.completedOn.length}} times <font-awesome-icon icon="fa-solid fa-thumbs-up" size="1x" color="green" />, practise again to improve your skills.</p>
            
            <div class="progress" style="height: 30px;">
              <div v-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index == module.total_all_type_exercises" class="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: (module.current_exercise_index / module.total_all_type_exercises * 100 + '%')}" >{{Math.floor(module.current_exercise_index / module.total_all_type_exercises * 100)}} % completed </div>
              <div v-else-if="module.current_exercise_index && module.total_all_type_exercises && module.current_exercise_index < module.total_all_type_exercises" class="progress-bar bg-success" role="progressbar" :style="{width: (module.current_exercise_index / module.total_all_type_exercises * 100 + '%')}" >{{Math.floor(module.current_exercise_index / module.total_all_type_exercises * 100)}} % completed</div>
              <div v-else class="progress-bar bg-success" role="progressbar" style="width: 0%" ></div>
            </div>
            
          </div>
        </div>
      </div>
      
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>


import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'
import {updateData, getUserDataDir} from '@/firebase/index'
import {count_db_requested_exercises, count_db_total_all_type_exercises} from '@/utils/session/functions/exercise_count'


export default {
  name: 'Homework_dashboard',
  props: {
    moduleRequested: String
  },
  components: {Footer, Navbar},
  data() {
    return { 
      homework_modules: [],
      assessment_modules: [],
      userCategory: localStorage.getItem("userCategory"),
      uid: localStorage.getItem('uid')
    }
  },
  created() {

  },
  mounted() {    
    this.getAssignments()
  },
  methods: {
    getAssignments() {
      this.getHomeworkModules()
      this.getAssessmentModules()
    },
    getHomeworkModules() {
      let _this = this
      var do_once = true;

      let homeworkModulesPath = 'Assignments/homework/general/'
      getUserDataDir(homeworkModulesPath,function (err, handle) {
        if (do_once) {

          do_once = false;
          
          if(handle.hasChildren()) //should always be true
          {
            handle.forEach(function (childSnapshot) {
              
              let total_all_type_exercises = count_db_total_all_type_exercises(childSnapshot.val())
              //alert(total_all_type_exercises)
              let homework_module = childSnapshot.val()
              let last_accessed = childSnapshot.val()["last_accessed"]

              if (last_accessed) {
                //check if accessed before 00:00
                if (new Date(last_accessed) < new Date().date) {
                  
                  //reset x out y exercises completed after 00:00               
                  let current_exercise_index = childSnapshot.val()["current_exercise_index"]
                  if (!current_exercise_index || current_exercise_index > 0) {
                    updateData(childSnapshot.ref, {"current_exercise_index": 0});      
                    homework_module["current_exercise_index"] = 0
                  }
                }
              } else {
                updateData(childSnapshot.ref, {"current_exercise_index": 0});
                homework_module["current_exercise_index"] = 0
              }

              homework_module["total_all_type_exercises"] = total_all_type_exercises
              homework_module["isPackage"] = false


              _this.homework_modules.push(homework_module)
            })
          }
        }
      });
    },
    getAssessmentModules() {

      let _this = this;

      let do_once = true
      //General Modules
      let assessmentModulesPath = 'Assignments/assessment/general/'
      getUserDataDir(assessmentModulesPath, function (err, handle) {
        if (do_once) {

            do_once = false;
            if(handle.hasChildren()) //should always be true
            {
              let counter = 0

              handle.forEach(function (module) {
                
                let total_all_type_exercises = count_db_total_all_type_exercises(module.val())
                let p_module = module.val()
                p_module['package_uid'] = 'general'
                p_module['index'] = counter
                p_module['total_all_type_exercises'] = total_all_type_exercises
                p_module["isPackage"] = false

                let last_accessed = module.val()["last_accessed"]
                if (last_accessed) {

                } else {
                  updateData(module.ref, {"current_exercise_index": 0});
                  p_module["current_exercise_index"] = 0
                }
                
                _this.assessment_modules.push(p_module)
                counter = counter + 1
              })
            }
        }
      });


      let assessmentPackagesPath = 'Assignments/assessment/packages/'
      getUserDataDir(assessmentPackagesPath, function (err, packages) {

        packages.forEach((package_handle) => {

          let assessment_package = package_handle.val()
          let assesssment_package_uid = package_handle.key
          
          let counter = 0
          package_handle.child('modules').forEach(function (module) {

            let total_all_type_exercises = count_db_total_all_type_exercises(module.val())

            let p_module = module.val()
            p_module['package_uid'] = assesssment_package_uid
            p_module['index'] = counter
            p_module['total_all_type_exercises'] = total_all_type_exercises
            p_module["isPackage"] = true


            let last_accessed = module.val()["last_accessed"]
            if (last_accessed) {
            } else {
              updateData(module.ref, {"current_exercise_index": 0})
              p_module["current_exercise_index"] = 0
            }

            _this.assessment_modules.push(p_module)
            counter = counter + 1
          })
        })
      });

    },    
    startModule(index, isAssessment, isPackage, package_uid, current_exercise_index) {
      this.$router.push({ name: 'assignment', query: {module_index: index, isAssessment: isAssessment, isPackage: isPackage, package_uid: package_uid, current_exercise_index: current_exercise_index}});
    },
    startAllHomeworkInSequence() {
      //go to HCP homework plan
      _this.start_object.module_path = _this.home_plan_address
      _this.start_object.module_name = _this.homePlanName
      _this.start_object.assessment_type = "ASSIGNMENT_TRAINING"
      
      _this.$router.push({ name: 'Session', query: {start_object:_this.start_object}});
    }
      
  },
}
</script>
<style scoped>



</style>