import {updateData, getData, getUserDataDir, getDataDirectory, getAuthToken} from '@/firebase/index'
import firebase from '@firebase/app';
import axios from 'axios'

import {getPackageResultPath} from '@/utils/results/common.js'

import {Module} from '@/utils/session/module.js'
import {logActivity} from '@/media_input/utils'

export class Package {
  constructor(meta) {

    this.meta = meta
    this.modules = [] //fill this

    this.more_exercises_available = false
    this.name = ''
    this.current_module_idx = 0
    this.current_module_name = ''

    this.curr_package_uid = ''
    this.curr_package_meta = {}

    this.curr_ex_package_idx = 0

    this.isAssignment = false

    this.all_module_names = []
    this.completed_module_names = []

    this.results_table = {back: [], pre: []}
    this.showResults = false
  }

  async initialise(requestType, pmeta) {
    if (requestType === "assignment") {
      this.title = "Assignment from therapist"
      
      this.isAssignment = true

      let index = pmeta.index
      let isAssessment = pmeta.isAssessment
      let package_uid = pmeta.package_uid

      await this.loadAssignmentPackageModule(index, isAssessment, package_uid)
    } else {
      
      this.isAssignment = false
      
      let package_dir = this.meta.package_dir
      let package_name = this.meta.package_name
      this.name = package_name
      
      let package_data = (await getDataDirectory(package_dir)).val()

      this.curr_package_uid = package_data['package_uid']
      
      await this.loadPackagedAssessmentModules(package_dir)
    }

    if(this.title!=undefined && this.title!=null) {
      logActivity( 'accessed', this.title)
    } else {
      logActivity( 'accessed', 'no title')
    }

  }

  async loadPackagedAssessmentModules(package_dir) { //this should also load training packages
    const _this = this
    
    let package_path_ref = firebase.database().ref(package_dir + '/modules/')
    
    await new Promise((resolve) => {

      getData(package_path_ref, async function (err, handle) {
      
        if(handle.hasChildren()) //should always be true
        {
          await _this.loadMultiModulesFolder(handle)
        }
        resolve()
      })
    
    })
  }

  async loadAssignmentPackageModule(index, isAssessment, package_uid)
  {
    
    const _this = this
    
    _this.isAssessment = true

    let assignment_type = 'homework'

    if (isAssessment) {
      assignment_type = 'assessment'
    }

    _this.curr_package_uid = package_uid
    
    let package_assignment_address = '/Assignments/' + assignment_type + '/packages/' + package_uid

    await new Promise((resolve) => {

      getUserDataDir(package_assignment_address, function(err, handle) {
        let package_handle = handle.val()
        
        let package_name = package_handle['name']

        _this.name = package_name

        _this.all_module_names = []
        for (const [key, module] of Object.entries(package_handle['modules'])) {
          _this.all_module_names.push(module['mod_name'])
        }
        
        _this.curr_package_meta = {name: package_name, all_module_names: _this.all_module_names}

        resolve()
      });

    })

    let assignments_address = '/Assignments/' + assignment_type + '/packages/' + package_uid + '/modules/'

    console.debug('package_address = ' + assignments_address)

    let do_once = true

    let foundSnapshot = false
    
    await new Promise((resolve) => {

      getUserDataDir(assignments_address, async function (err, handle) {
        if (do_once) {
          do_once = false
          
          if(handle.hasChildren()) //should always be true
          {
            let counter = 0        
            handle.forEach(function (childSnapshot) {
                            
              if (counter == index) { //get requested module
                
                _this.moduleHandle = childSnapshot

                foundSnapshot = true

                resolve()
              }
              counter++;
            })
          }
          
        }
      });
    })


    if (foundSnapshot) {

      let module_ = new Module(_this.meta)

      let timestamp = new Date().getTime()
      module_.curr_package_uid = _this.name + "_" + timestamp

      _this.assessment_type = "ASSIGNMENT_TRAINING"
  
      if (isAssessment) {
        _this.assessment_type = "ASSIGNMENT_ASSESSMENT"
      }
      
      module_.title = _this.name + " Assessment"
      module_.assessment_type = _this.assessment_type
      module_.isAssessment = true
      module_.isAssignment = _this.isAssignment
      module_.moduleHandle = _this.moduleHandle
      module_.current_module_name = _this.moduleHandle.val()['mod_name']

      await module_.loadSingleModuleFolder(_this.moduleHandle)

      this.modules.push({ 
        module: module_,
        name: _this.moduleHandle.val()['mod_name'],
        completed: false
      })
    
  
      updateData(_this.moduleHandle.ref, {"last_accessed": new Date()});
  
      _this.title = _this.moduleHandle.val()['mod_name']
      _this.current_module_name = _this.moduleHandle.val()['mod_name']

  
      _this.curr_ex_package_idx = 0

      _this.current_module_idx = 0
          
      _this.total_exercises = module_.getTotalExercisesNumber()

    }

  }

  async getFirebaseHandleByChildKey(handle, target_key, dest_array)
  { 
    const _this = this;
    await new Promise( (resolve) => {
      handle.forEach(function (childSnapshot) {

        let c_snapshot = childSnapshot;
  
        if(childSnapshot.hasChildren()) {
          _this.getFirebaseHandleByChildKey(c_snapshot, target_key, dest_array)
        }
        else if(childSnapshot.key == target_key) {
          dest_array.push(handle)
        }
      })
      resolve()
    })
  }

  async loadMultiModulesFolder(root_handle) //sets exercise_address_list and total_exercises
  {
    const _this = this

    let total_modules = [] //list of handles pointing to each module root directory
    await _this.getFirebaseHandleByChildKey(root_handle, 'mod_name', total_modules) //get all modules

    _this.assessment_type = "SLT_ASSESSMENT"
    
    _this.current_module_idx = 0

    if(total_modules.length>0) { //loads one or more modules
      
      _this.total_exercises = 0
      for (let module of total_modules) {
        let module_ = new Module(_this.meta)

        module_.curr_package_uid = _this.curr_package_uid
        
        module_.title = _this.name + " Assessment"
        module_.assessment_type = _this.assessment_type
        module_.isAssessment = true
        module_.isAssignment = false
        module_.moduleHandle = module
        module_.current_module_name = module.val()['mod_name']
        await module_.loadSingleModuleFolder(module)

        _this.modules.push({ 
          module: module_,
          name: module.val()['mod_name'],
          completed: false
        })

        //////////////////////set total exercise number
        let n_exercises = module_.getTotalExercisesNumber()
        
        _this.total_exercises = _this.total_exercises + n_exercises

        if ('completedOn' in module.val()) {
          _this.current_module_idx ++
          _this.curr_ex_package_idx = _this.curr_ex_package_idx + n_exercises
        } else {
          if ('current_exercise_index' in module.val()) {
            _this.curr_ex_package_idx = _this.curr_ex_package_idx + module.val()['current_exercise_index']
          }
        }

      }
    }


    _this.current_module_name = _this.modules[_this.current_module_idx]['name']
  }

  async next() {
    let _this = this

    //if (_this.isAssignment) { 
      //completed module
      console.log(_this.modules[_this.current_module_idx].module.moduleHandle)
      updateData(_this.modules[_this.current_module_idx].module.moduleHandle.ref, 
        {"current_exercise_index": _this.modules[_this.current_module_idx].module.current_exercise_index});
    //}

    if (_this.curr_ex_package_idx == 0) { //first exercise finished
      let package_result_path = getPackageResultPath(_this.isAssessment, _this.assessment_type, _this.curr_package_uid)
      
      let package_result_path_ref = firebase.database().ref(package_result_path)

      if (_this.all_module_names.length == 0) {
        for (let i=0; i<_this.modules.length; i++) {
          _this.all_module_names.push(_this.modules[i]['name'])
        }
      }
      
      updateData(package_result_path_ref, {
        name: _this.name, 
        all_module_names: _this.all_module_names,
      })
    }

    ////////////////////////////////////////////////////////////////////////
    let hasFinished = await this.modules[this.current_module_idx]['module'].next()
    ////////////////////////////////////////////////////////////////////////
    if (hasFinished) {
      //if (_this.isAssignment) { 
        //completed module
        let completedOnList = _this.modules[_this.current_module_idx].module.moduleHandle.val()["completedOn"]

        if (completedOnList == undefined) {
          completedOnList = []
        }

        completedOnList.push(new Date())
        updateData(_this.modules[_this.current_module_idx].module.moduleHandle.ref, {"completedOn": completedOnList});

        _this.checkIsPackageCompleted()
      //}

      //////////////MARK end of module in results of package

      let package_result_path = getPackageResultPath(_this.isAssessment, _this.assessment_type, _this.curr_package_uid)
      let package_result_path_ref = firebase.database().ref(package_result_path)
        

      if  (!_this.completed_module_names.includes(_this.current_module_name)) {
        _this.completed_module_names.push(_this.current_module_name)
      }

      getData(package_result_path, function (err, result) {
        let value = result.val()
        if (value.hasOwnProperty('completed_module_names')) {
          _this.completed_module_names = [...new Set([..._this.completed_module_names ,...value['completed_module_names']])] 
        }

        updateData(package_result_path_ref, {
          completed_module_names: _this.completed_module_names
        })
      })
      
      if (this.current_module_idx < _this.modules.length-1) {
        this.current_module_idx++ 
      } else {
        //package finished
        this.showResults = true
        return
      }
    }
    


    _this.current_module_name = _this.modules[_this.current_module_idx]['name']    
    _this.curr_ex_package_idx = _this.getCurrExNumber()
  }

  getCurrExNumber() {
    let n = 0

    for (let i = 0; i<this.current_module_idx; i++) {
      let x = this.modules[i]['module'].total_available_all_type_exercises
      n = n + x
    }

    n = n + this.modules[this.current_module_idx]['module'].current_exercise_index-1
    return n
  }

  isFinished() {
    let count = 0
    for (let i = 0; i<this.modules.length; i++) {
      if (this.modules[i]['completed']) {
        count++
      }
    }
    if (count == this.modules.length) {
      return true
    }
    return false
  }

  checkIsPackageCompleted() {
    
    let assessmentPackagesPath = '/Users/' + localStorage.getItem('uid') + '/Assignments/assessment/packages/'

    if (!this.isAssignment) {
      assessmentPackagesPath = '/Users/' + localStorage.getItem('client_uid') + '/Active_Assessment/packages/'
    }

    getDataDirectory(assessmentPackagesPath).then((packages) => {

      packages.forEach((package_handle) => {

        let assessment_package = package_handle.val()
        let total_modules = Object.entries(assessment_package['modules']).length

        let modules_completed = 0;

        package_handle.child('modules').forEach(function (module) {

          let p_module = module.val()

          if (p_module['completedOn']) {
            modules_completed = modules_completed + 1
          }
        })

        if (modules_completed === total_modules) {
          //alert("Package completed")
          if (package_handle['finished']) {
            //already finished so do nothing
          } else {
            if (this.isAssignment) {

              //just finished so notify SLT
              let slt_uid = null
              if (package_handle['slt']) {
                slt_uid = package_handle['slt']
              }

              let notification_api = import.meta.env.VITE_FAST_API + "/finished_assessment_package"
              let data = {
                patient_uid: localStorage.getItem("uid"),
                slt_uid: slt_uid,
                package_key: package_handle.key
              }
              
              getAuthToken().then((idToken) => {
                axios.post(notification_api, data, {
                  headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                  }
                }).then((response) => {
                  if (response.status === 200) {
                    updateData(package_handle.ref, {'finished':  Date.now()})
                  }
                })
              })

            } else {
              updateData(package_handle.ref, {'finished':  Date.now()})
            }

          }

        }
      })
    });

  }

  getExForm() {
    let exForm = this.modules[this.current_module_idx]['module'].exForm

    exForm['total_all_type_exercises'] = this.total_exercises

    exForm['current_exercise_index'] = this.curr_ex_package_idx
    
    exForm['title'] = this.package_name
    exForm['package_uid'] = this.curr_package_uid

    return exForm
  }

  getShowResults() {
    return this.showResults
  }

  getexercise_on() {
    return this.modules[this.current_module_idx]['module'].exercise_on
  }

  showResultPage() {
  }

  logExerciseResults(data) {
    this.modules[this.current_module_idx]['module'].logExerciseResults(data)

    let resultsTableBack = data['resultsTableBack']

    if(resultsTableBack != null) {
      let back = this.modules[this.current_module_idx]['module'].results_table['back']
//      console.error(back)
      if (back.length > 0) {
        //this.results_table['back'] = this.results_table['back'].concat(back)
        
        this.results_table['back'] = [...new Set([...this.results_table['back'] ,...back])]
      }
    }


    let resultsTablePre = data['resultsTablePre']

    if(resultsTablePre != null) {
      let pre = this.modules[this.current_module_idx]['module'].results_table['pre']
//      console.error(pre)
      if (pre.length > 0) {
        //this.results_table['pre'] = this.results_table['pre'].concat(pre)
        this.results_table['pre'] = [...new Set([...this.results_table['pre'] ,...pre])]
      }
    }
  }

}