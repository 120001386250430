<template>
    <div  class="row mt-2">
      <div class="card card-body">
        <div slot="header" class="clearfix">
          <span style="float:left">Maximum phonation analysis results</span>
        </div>
        <table class="table mt-4">
          <thead>
            <tr>
              <th scope="col">Exercise Name</th>
              <th scope="col">Loudness (dB)</th>
              <th scope="col">Sound sustaining time (s)</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row, index in q_data" v-bind:key="index">
              <td>{{ row.Name }}</td>
              <td>{{ row.loudness<50?'<50':row.loudness }}</td>
              <td>{{ row.max_phonation }}</td>
              <td>
                <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
              </td>
            </tr>
            <tr class="table-group-divider" v-if="q_data && q_data.length > 1">
              <th scope="col">Average value</th>
              <th scope="col">{{ (q_data.reduce((sum, value) => sum + value.loudness, 0) / q_data.length).toFixed(0) }}</th>
              <th scope="col">{{ (q_data.reduce((sum, value) => sum + value.max_phonation, 0) / q_data.length).toFixed(0) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>
  
<script>

import firebase from 'firebase/app'
import { add } from 'lodash';
import {ref, onUpdated, onMounted} from 'vue'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'phonation',
    components: {
      Audio_Playback
    },
    props: {
      q_data: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      onMounted( () => {

      });
      const audio_player = ref(null)

      const playAudio = (audio_file_location) => {

        let storage = firebase.storage()
        storage.ref(audio_file_location).getDownloadURL().then((url) => {
          audio_player.value = new Audio(url);
          audio_player.value.play();
          
          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });

        }).catch((error) => {

        })
      }

      const playRecording = (address) => {
        playAudio(address)
      }

      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      const downloadRecording = (address) => {
        let storage = firebase.storage();

        storage.ref(address).getDownloadURL().then((url) => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {

        });
      }
    
      return {
        playRecording,
        downloadRecording

      };

    }
  }
</script>