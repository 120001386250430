<template>
  <div>
    
    <div class="row d-flex mb-3" style="min-height: 100vh;">
      <div class="col-xl-10 pe-0 d-flex flex-column">
        <div class="card card-body">

          <slot name="content">
          
          </slot>
        </div>
      </div>
      <div class="col-xl-2" style="padding-left: 0px">

        <slot name="sidebar">
        
        </slot>

      </div>
    </div>

  </div>
</template>
  
<script>
  import {onMounted } from 'vue';

  export default {
    props: {
      
    },
    setup(props, {emit}) {
    
      onMounted(() => {
        
      });
      
      return {

      };
    }
  };
</script>
  
<style scoped>

</style>
  